import { Box, Typography } from '@mui/material'
import { CreateEventPreviewProps } from './CreateEventPreviewView'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import {
    iso2date,
    iso2day,
    iso2dayRange,
    iso2time,
} from '../../../utils/DateStringConverter'

const CreateEventPreviewDateView = ({ tempEvent }: CreateEventPreviewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                DATE AND TIME
            </Typography>
            {tempEvent.event_time && tempEvent.end_time ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '8px',
                    }}
                >
                    <CalendarMonthRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: 'text.tertiary',
                            background: (theme) =>
                                theme.palette.background.tertiary,
                            padding: '8px',
                            borderRadius: '10px',
                        }}
                    />
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 14,
                                marginLeft: '8px',
                                color: 'text.primary',
                                fontWeight: 'bold',
                            }}
                        >
                            {iso2dayRange(
                                tempEvent.event_time,
                                tempEvent.end_time
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                marginLeft: '8px',
                                color: 'text.primary',
                            }}
                        >
                            {`${iso2time(tempEvent.event_time)} - ${iso2time(tempEvent.end_time)}`}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Typography
                    sx={{ fontSize: 14, color: 'text.disabled', mt: '8px' }}
                >
                    Select a date and time
                </Typography>
            )}
        </Box>
    )
}

export default CreateEventPreviewDateView
