import { Box, BoxProps, styled, Typography } from '@mui/material'
import CreateTopBarMobileView from './CreateTopBarMobileView'
import { LoadingBox } from '../Components/StyledComponents'
import { set } from 'date-fns'
import { navigateToCreatePost } from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import CreateBottomBarMobileView from './CreateBottomBarMobileView'

interface showCreateMobileViewProps {
    showCreateMobileView: boolean
    setShowCreateMobileView: React.Dispatch<React.SetStateAction<boolean>>
}

interface RootContainerProps extends BoxProps {
    showCreateMobileView: boolean
}
const RootContainer = styled((props: RootContainerProps) => <Box {...props} />)(
    ({ theme, showCreateMobileView }) => ({
        width: '100%',
        display: showCreateMobileView ? 'inline' : 'none',
        height: '100%',
        position: 'fixed',
        top: 0,
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.primary,
    })
)

const CreateContentBox = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.border.primary,
    borderRadius: 10,
    backgroundColor: theme.palette.background.secondary,
    width: '100%',
}))
const CreateMobileView = ({
    showCreateMobileView,
    setShowCreateMobileView,
}: showCreateMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const handleCreateEvent = () => {
        setShowCreateMobileView(false)
    }
    const handleCreatePost = () => {
        navigateToCreatePost(null, null, null, navigate, state, setState)
        setShowCreateMobileView(false)
    }
    return (
        <RootContainer showCreateMobileView={showCreateMobileView}>
            <Box
                sx={{
                    position: 'relative',
                    height: '100%',
                    p: '16px',
                    pt: '55px',
                }}
            >
                {/* <CreateBottomBarMobileView /> */}
                <CreateContentBox
                    sx={{
                        mt: '16px',
                    }}
                    onClick={handleCreateEvent}
                >
                    <LoadingBox
                        sx={{
                            height: '120px',
                            borderRadius: '10px 10px 0px 0px',
                        }}
                    />
                    <Box
                        sx={{
                            p: '8px',
                            pl: '16px',
                            pr: '16px',
                            pb: '24px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Create Event
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: 'text.secondary',
                                mt: '0px',
                            }}
                        >
                            Click here to create a new event that others can
                            join and attend. Share the details, and invite
                            people to participate in your event!
                        </Typography>
                    </Box>
                </CreateContentBox>

                <CreateContentBox
                    sx={{
                        mt: '16px',
                    }}
                    onClick={handleCreatePost}
                >
                    <LoadingBox
                        sx={{
                            height: '120px',
                            borderRadius: '10px 10px 0px 0px',
                        }}
                    />
                    <Box
                        sx={{
                            p: '8px',
                            pl: '16px',
                            pr: '16px',
                            pb: '24px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Create Post
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: 'text.secondary',
                                mt: '0px',
                            }}
                        >
                            Click here to create a post where you can share
                            anything about the event—discuss plans, talk about
                            event details, or connect with others attending.
                        </Typography>
                    </Box>
                </CreateContentBox>
                <CreateTopBarMobileView
                    showCreateMobileView={showCreateMobileView}
                    setShowCreateMobileView={setShowCreateMobileView}
                />
            </Box>
        </RootContainer>
    )
}

export default CreateMobileView
