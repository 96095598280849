import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useState } from 'react'
import { Box, InputBase, Typography } from '@mui/material'
import { styled } from '@mui/system'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { getEventsText } from '../../../api/RestEvent'
import { DataStatus } from '../../../models/Dataloading'
import { Event } from '../../../models/Event'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import EventSubMobileView from '../../EventView/EventMobileView/EventSubMobileView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
    },
}))

const SearchContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: theme.palette.background.tertiary,
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
    alignItems: 'center',
    display: 'flex',
    minHeight: 35,
    marginRight: 16,
}))

interface DiscoverSearchMobileViewProps {
    showSearch: boolean
    setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const DiscoverSearchMobileView = ({
    showSearch,
    setShowSearch,
}: DiscoverSearchMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [searchInput, setSearchInput] = useState('')
    const [eventState, setEventState] = useState<Event[]>([])
    const [eventStateStaus, setEventStateStatus] = useState<DataStatus>(
        DataStatus.none
    )
    useEffect(() => {
        if (searchInput.length > 0) {
            setEventStateStatus(DataStatus.loading)
            getEventsText(searchInput, 5, 0, state.usertoken)
                .then((response) => {
                    setEventState(response.events)
                    setEventStateStatus(DataStatus.success)
                    console.log(response.events)
                })
                .catch((error) => {
                    console.log(error)
                    setEventStateStatus(DataStatus.failure)
                })
        } else {
            setEventStateStatus(DataStatus.none)
            setEventState([])
        }
    }, [searchInput])
    return (
        <Box
            sx={{
                pb: `${navMobileBarHeight}px`,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '0px 16px',
                    height: '55px',
                }}
            >
                <SearchContainer>
                    <SearchRoundedIcon
                        sx={{
                            color: 'text.disabled',
                            pl: '4px',
                            fontSize: '20px',
                        }}
                    />
                    <StyledInputBase
                        placeholder="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                    {searchInput.length > 0 && (
                        <CancelRoundedIcon
                            sx={{
                                color: 'text.disabled',
                                position: 'absolute',
                                right: '8px',
                                cursor: 'pointer',
                                fontSize: '20px',
                            }}
                            onClick={() => setSearchInput('')}
                        />
                    )}
                </SearchContainer>
                <Typography
                    sx={{
                        fontSize: '14px',
                        color: 'text.primary',
                        fontWeight: 'bold',
                    }}
                    onClick={() => setShowSearch(false)}
                >
                    Cancel
                </Typography>
            </Box>
            <PrimaryDivider />
            <Box>
                {eventStateStaus === DataStatus.loading ? (
                    <Box
                        sx={{
                            marginTop: '24px',
                            justifyContent: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <StyledCircularProgress />
                    </Box>
                ) : eventStateStaus === DataStatus.failure ? (
                    <ErrorTabView />
                ) : (
                    eventStateStaus === DataStatus.success && (
                        <>
                            {eventState.length === 0 ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '20px 20px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                        }}
                                    >
                                        No events found
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            mt: '8px',
                                            color: 'text.secondary',
                                        }}
                                    >
                                        No events are found given the search
                                        criteria
                                    </Typography>
                                </Box>
                            ) : (
                                eventState.map((event) => (
                                    <Box>
                                        <EventSubMobileView event={event} />
                                        <PrimaryDivider />
                                    </Box>
                                ))
                            )}
                        </>
                    )
                )}
            </Box>
        </Box>
    )
}

export default DiscoverSearchMobileView
