import { useEffect, useState } from 'react'
import {
    Box,
    Drawer,
    DrawerProps,
    IconButton,
    Grid,
    Collapse,
    Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAppState } from '../../AppStateProvider'
import {
    PrimaryTypography,
    SecondaryTypography,
    PrimaryDivider,
    EmptyImageBox,
    StyledTextField,
    SecondaryDivider,
} from '../Components/StyledComponents'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import CreateEventTextDetails from './CreateEventEditView/CreateEventTextDetails'
import { CreateEvent } from '../../models/CreateEvent'
import CreateEventPhotoView from './CreateEventEditView/CreateEventPhoto'
import { FilterCategory } from '../../models/Filter'
import { BorderLeft } from '@mui/icons-material'
import { NavigateCreateEventState } from '../../models/NavigateState'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateEventEditLocationView from './CreateEventEditView/CreateEventEditLocaitonView'
import { set } from 'date-fns'
import CreateEventEditTagsView from './CreateEventEditView/CreateEventEditTagsView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../Components/StyledComponents/StyledTabView'
import CreateEventStepper from './CreateEventStepper'
import CreateEventSettingsView from './CreateEventEditView/CreateEventSettingsView'
import CreateEventPreviewView from './CreateEventPreviewView/CreateEventPreviewView'
import CreateEventHostsView from './CreateEventEditView/CreateEventHostsView'
import CreateEventInviteView from './CreateEventEditView/CreateEventInviteView'
import zIndex from '@mui/material/styles/zIndex'
import { use } from 'marked'
import CreateEventMobileView from '../CreateView/CreateEventMobileView.tsx'
import { getUser } from '../../api/RestUser'

const TopBar = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.header,
}))
interface PreviewIconButtonProps
    extends React.ComponentProps<typeof IconButton> {
    drawerOpen: boolean
}
const DrawerIconButton = styled((props: PreviewIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, drawerOpen }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,

    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    transform: drawerOpen ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const DrawerPreviewIconButton = styled((props: PreviewIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, drawerOpen }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,

    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    transform: drawerOpen ? 'rotate(0deg)' : 'rotate(-180deg)',
}))

const DrawerEditIconButton = styled((props: PreviewIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, drawerOpen }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,

    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    transform: drawerOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
}))

interface EventEditorDiv extends React.HTMLAttributes<HTMLDivElement> {
    drawerOpen: boolean
    divideropen: boolean
    drawerStepOpen: boolean
}

const EventEditorDiv = styled((props: EventEditorDiv) => <div {...props} />)(
    ({ theme, drawerOpen, divideropen, drawerStepOpen }) => ({
        width: '100%',
        height: '100%',
        float: 'left',
        zIndex: 2,
        padding: '8px 16px',
        paddingBottom: '16px',
        position: 'relative',
        justifyContent: 'center',

        ...(drawerStepOpen
            ? {
                  marginLeft: 250,
                  transition: theme.transitions.create('margin', {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                  }),
              }
            : {
                  marginLeft: 0,
                  transition: theme.transitions.create('margin', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                  }),
              }),
        ...(drawerOpen
            ? {
                  marginRight: divideropen
                      ? 'calc(40% + 88px)'
                      : 'calc(40% + 24px)',
                  transition: theme.transitions.create('margin', {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                  }),
              }
            : {
                  marginRight: 0,
                  transition: theme.transitions.create('margin', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                  }),
              }),
    })
)

const PreviewDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: '40%',
        boxSizing: 'border-box',
        background: theme.palette.background.primary,
        marginTop: 56,
        borderLeft: '1px solid ' + theme.palette.border.primary,
    },
}))

interface StepsDrawerProps extends DrawerProps {
    drawerOpen: boolean
}

const StepsDrawer = styled((props: StepsDrawerProps) => <Drawer {...props} />)(
    ({ theme, drawerOpen }) => ({
        '& .MuiDrawer-paper': {
            width: '250px',
            boxSizing: 'border-box',
            background: theme.palette.background.primary,
            marginTop: 55,
            zIndex: 3,
            ...(drawerOpen
                ? {
                      marginLeft: 220,
                      transition: theme.transitions.create('margin', {
                          easing: theme.transitions.easing.easeOut,
                          duration: theme.transitions.duration.enteringScreen,
                      }),
                  }
                : {
                      marginLeft: 60,
                      transition: theme.transitions.create('margin', {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.leavingScreen,
                      }),
                  }),
            borderRight: '1px solid ' + theme.palette.border.primary,
        },
    })
)

const EventEditorContainer = styled('div')(({ theme }) => ({
    borderRadius: 10,
    backgroundColor: theme.palette.background.secondary,
    border: '1px solid ' + theme.palette.border.primary,
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
    maxWidth: 1000,
    width: '100%',
    maxHeight: 'calc(100% - 20px)',
    overflow: 'hidden',
}))

export const activeStepEventDetailsIsComplete = (
    tempEventData: CreateEvent
) => {
    return (
        tempEventData.title !== '' &&
        tempEventData.image !== '' &&
        tempEventData.description !== '' &&
        tempEventData.tag_list.length > 0 &&
        tempEventData.category_list.length > 0 &&
        tempEventData.location !== null &&
        tempEventData.event_time !== '' &&
        tempEventData.end_time !== ''
    )
}

const activeStepEventSettingsIsComplete = (tempEventData: CreateEvent) => {
    return tempEventData.attendee_limit !== 0
}

const CreateEventView = () => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <CreateEventMobileView />
            ) : (
                <CreateEventDesktopView />
            )}
        </>
    )
}
const CreateEventDesktopView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [drawerOpen, setDrawerOpen] = useState(true)
    const [drawerStepOpen, setDrawerStepOpen] = useState(true)
    const location = useLocation()
    const initialTempEventData = location.state as NavigateCreateEventState
    const [tempEventData, setTempEventData] = useState<CreateEvent>(
        initialTempEventData.tempEventData
            ? initialTempEventData.tempEventData
            : {
                  title: '',
                  image: '',
                  description: '',
                  tag_list: [],
                  category_list: [],
                  private: false,
                  primary_host: null,
                  host: [],
                  location: null,
                  locationsearch: null,
                  address: null,
                  event_time: '',
                  end_time: '',
                  attendee_limit: 0,
              }
    )
    const [value, setValue] = useState('event-details')

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    useEffect(() => {
        if (initialTempEventData.tempEventData) {
            setTempEventData(initialTempEventData.tempEventData)
        }
    }, [initialTempEventData])
    const [activeStep, setActiveStep] = useState(0)
    const [activeStepIsComplete, setActiveStepIsComplete] = useState([
        false,
        false,
        false,
        false,
    ])
    useEffect(() => {
        getUser(state.name, state.usertoken)
            .then((response) => {
                setTempEventData((prev) => {
                    return {
                        ...prev,
                        primary_host: response.profile,
                    }
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])
    useEffect(() => {
        setActiveStepIsComplete([
            activeStepEventDetailsIsComplete(tempEventData),
            activeStepEventSettingsIsComplete(tempEventData),
            true,
            true,
        ])
    }, [tempEventData])
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                marginTop: '56px',
            }}
        >
            {window.location.pathname.includes('location') && (
                <CreateEventEditLocationView />
            )}
            {window.location.pathname.includes('tags') && (
                <CreateEventEditTagsView />
            )}
            <StepsDrawer
                variant="persistent"
                anchor="left"
                open={drawerStepOpen}
                drawerOpen={state.open}
            >
                <CreateEventStepper
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    activeStepIsComplete={activeStepIsComplete}
                />
            </StepsDrawer>
            <EventEditorDiv
                drawerOpen={drawerOpen}
                divideropen={state.open}
                drawerStepOpen={drawerStepOpen}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContext: 'space-between',
                            alignItems: 'center',
                            mb: '4px',
                        }}
                    >
                        <DrawerEditIconButton
                            sx={{
                                width: 35,
                                height: 35,
                                mr: '8px',
                                color: 'text.tertiary',
                            }}
                            drawerOpen={drawerStepOpen}
                            onClick={() => setDrawerStepOpen(!drawerStepOpen)}
                        >
                            <ChevronRightRoundedIcon />
                        </DrawerEditIconButton>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: 'text.primary',
                                ml: '0px',
                                mt: '0px',
                            }}
                        >
                            Edit Event
                        </Typography>
                        <DrawerPreviewIconButton
                            sx={{
                                width: 35,
                                height: 35,
                                mr: '0px',
                                ml: 'auto',
                                color: 'text.tertiary',
                            }}
                            drawerOpen={drawerOpen}
                            onClick={() => setDrawerOpen(!drawerOpen)}
                        >
                            <ChevronRightRoundedIcon />
                        </DrawerPreviewIconButton>
                    </Box>
                    <SecondaryDivider
                        sx={{
                            mt: '0px',
                            mb: '16px',
                            ml: '0px',
                            mr: '0px',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        height: '100%',
                    }}
                >
                    <Box>
                        <EventEditorContainer sx={{ mt: '0px' }}>
                            <TopBar
                                style={{
                                    height: 35,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        marginLeft: '16px',
                                        fontWeight: 'bold',
                                        color: 'text.tertiary',
                                    }}
                                >
                                    EVENT DETAILS
                                </Typography>

                                <DrawerIconButton
                                    sx={{
                                        float: 'right',
                                        width: 30,
                                        height: 30,
                                        marginRight: '10px',
                                    }}
                                    drawerOpen={activeStep === 0}
                                    onClick={() => setActiveStep(0)}
                                >
                                    <ChevronRightRoundedIcon
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 20,
                                        }}
                                    />
                                </DrawerIconButton>
                            </TopBar>
                            <Collapse in={activeStep === 0}>
                                {/* <SecondaryDivider
                                    sx={{
                                        mt: '0px',
                                        mb: '4px',
                                        ml: '16px',
                                        mr: '16px',
                                    }}
                                /> */}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 'calc(100% - 50px)',
                                        display: 'flex',
                                        borderRadius: 2,
                                        justifyContent: 'center',
                                        // WebkitOverflowScrolling: 'touch',
                                        // scrollBehavior: 'smooth',
                                    }}
                                >
                                    <div>
                                        <Box
                                            sx={{
                                                pb: '16px',
                                                pl: '16px',
                                                pr: '16px',

                                                alignContent: 'top',
                                            }}
                                        >
                                            <CreateEventPhotoView
                                                tempEventData={tempEventData}
                                                setTempEventData={
                                                    setTempEventData
                                                }
                                            />
                                            <CreateEventTextDetails
                                                tempEventData={tempEventData}
                                                setTempEventData={
                                                    setTempEventData
                                                }
                                            />
                                        </Box>
                                    </div>
                                </Box>
                            </Collapse>
                        </EventEditorContainer>
                        <EventEditorContainer sx={{ mt: '8px' }}>
                            <TopBar
                                style={{
                                    height: 35,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'text.tertiary',
                                        ml: '16px',
                                        mt: '0px',
                                    }}
                                >
                                    EVENT SETTINGS
                                </Typography>

                                <DrawerIconButton
                                    sx={{
                                        float: 'right',
                                        width: 30,
                                        height: 30,
                                        marginRight: '10px',
                                    }}
                                    drawerOpen={activeStep === 1}
                                    onClick={() => setActiveStep(1)}
                                >
                                    <ChevronRightRoundedIcon
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 20,
                                        }}
                                    />
                                </DrawerIconButton>
                            </TopBar>
                            <Collapse in={activeStep === 1}>
                                {/* <SecondaryDivider
                                    sx={{
                                        mt: '0px',
                                        mb: '4px',
                                        ml: '16px',
                                        mr: '16px',
                                    }}
                                /> */}
                                <Box
                                    sx={{
                                        pb: '16px',
                                        pl: '16px',
                                        pr: '16px',

                                        alignContent: 'top',
                                    }}
                                >
                                    <CreateEventSettingsView
                                        tempEvent={tempEventData}
                                        setTempEvent={setTempEventData}
                                    />
                                </Box>
                            </Collapse>
                        </EventEditorContainer>
                        <EventEditorContainer sx={{ mt: '8px' }}>
                            <TopBar
                                style={{
                                    height: 35,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'text.tertiary',
                                        ml: '16px',
                                        mt: '0px',
                                    }}
                                >
                                    ADD HOSTS
                                </Typography>

                                <DrawerIconButton
                                    sx={{
                                        float: 'right',
                                        width: 30,
                                        height: 30,
                                        marginRight: '10px',
                                    }}
                                    drawerOpen={activeStep === 2}
                                    onClick={() => setActiveStep(2)}
                                >
                                    <ChevronRightRoundedIcon
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 20,
                                        }}
                                    />
                                </DrawerIconButton>
                            </TopBar>
                            <Collapse in={activeStep === 2}>
                                {/* <SecondaryDivider
                                    sx={{
                                        mt: '0px',
                                        mb: '4px',
                                        ml: '16px',
                                        mr: '16px',
                                    }}
                                /> */}
                                <Box
                                    sx={{
                                        pb: '16px',
                                        pl: '16px',
                                        pr: '16px',

                                        alignContent: 'top',
                                    }}
                                >
                                    <CreateEventHostsView
                                        tempEventData={tempEventData}
                                        setTempEventData={setTempEventData}
                                    />
                                </Box>
                            </Collapse>
                        </EventEditorContainer>

                        <EventEditorContainer sx={{ mt: '8px' }}>
                            <TopBar
                                style={{
                                    height: 35,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'text.tertiary',
                                        ml: '16px',
                                        mt: '0px',
                                    }}
                                >
                                    INVITE GUESTS
                                </Typography>

                                <DrawerIconButton
                                    sx={{
                                        float: 'right',
                                        width: 30,
                                        height: 30,
                                        marginRight: '10px',
                                    }}
                                    drawerOpen={activeStep === 3}
                                    onClick={() => setActiveStep(3)}
                                >
                                    <ChevronRightRoundedIcon
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 20,
                                        }}
                                    />
                                </DrawerIconButton>
                            </TopBar>
                            <Collapse in={activeStep === 3}>
                                {/* <SecondaryDivider
                                    sx={{
                                        mt: '0px',
                                        mb: '4px',
                                        ml: '16px',
                                        mr: '16px',
                                    }}
                                /> */}
                                <Box
                                    sx={{
                                        pb: '16px',
                                        pl: '16px',
                                        pr: '16px',

                                        alignContent: 'top',
                                    }}
                                >
                                    <CreateEventInviteView
                                        tempEventData={tempEventData}
                                        setTempEventData={setTempEventData}
                                    />
                                </Box>
                            </Collapse>
                        </EventEditorContainer>
                    </Box>
                </Box>
            </EventEditorDiv>
            <PreviewDrawer
                variant="persistent"
                anchor="right"
                open={drawerOpen}
            >
                <CreateEventPreviewView tempEvent={tempEventData} />
            </PreviewDrawer>
        </div>
    )
}

export default CreateEventView
