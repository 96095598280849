import axios from 'axios'
import { mainUrl } from '../constants'
import { Post } from '../models/Post'
import { ErrorCode } from '../models/ErrorCode'
import {
    convertBase64toBlob,
    convertBlobToBase64,
} from '../utils/Base64ImageEncoding'
export interface GetPostReply {
    post: Post
}

export interface UpdatePostRequest {
    update_data: {
        caption: string
    }
}

export interface GetPostsReply {
    posts: Post[]
    post_count: number
}

export interface CreatePostRequest {
    caption: string
    event_slug: string
    tags: string[]
}

export const getPosts = async (
    author: string | null,
    favorited: string | null,
    event_slug: string | null,
    limit: number,
    offset: number,
    userToken: string
): Promise<GetPostsReply> => {
    try {
        const params: Record<string, any> = {
            limit,
            offset,
        }
        if (author) {
            params.author = author
        }
        if (favorited) {
            params.favorited = favorited
        }
        if (event_slug) {
            params.event_slug = event_slug
        }

        const response = await axios.get<GetPostsReply>(`${mainUrl}posts`, {
            params: params,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const getPostbyId = async (
    id: string,
    userToken: string
): Promise<GetPostReply | ErrorCode> => {
    try {
        const response = await axios.get<GetPostReply>(
            `${mainUrl}posts/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { errorCode: error.response?.status || 500 }
        } else {
            return { errorCode: 500 }
        }
    }
}

export const grabPostPhoto = async (
    imagelink: string,
    usertoken: string
): Promise<string> => {
    const url = `${mainUrl}post/image?image=${imagelink}`

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${usertoken}`,
        },
    }

    try {
        const response = await fetch(url, requestOptions)
        if (!response.ok) {
            throw new Error('Failed to fetch data')
        }
        const blob = await response.blob()
        const image = await convertBlobToBase64(blob)

        return image
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const favoritePost = async (
    postID: string,
    userToken: string
): Promise<GetPostReply> => {
    try {
        const response = await axios.post<GetPostReply>(
            `${mainUrl}posts/${postID}/favorite`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const unfavoritePost = async (
    postID: string,
    userToken: string
): Promise<GetPostReply> => {
    try {
        const response = await axios.delete<GetPostReply>(
            `${mainUrl}posts/${postID}/favorite`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const createPost = async (
    caption: string,
    event_slug: string,
    tags: string[],
    postImage: string | null,
    userToken: string
): Promise<GetPostReply> => {
    try {
        const formData = new FormData()
        const params = new URLSearchParams()
        if (postImage) {
            const blob = convertBase64toBlob(postImage)
            formData.append('image', blob, 'image.jpeg')
        }
        params.append('caption', caption)
        params.append('event_slug', event_slug)
        params.append('tags', JSON.stringify(tags))
        console.log(params)
        const response = await axios.post<GetPostReply>(
            `${mainUrl}posts`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'multipart/form-data',
                },
                params: params,
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(
                error.response?.data || 'An unexpected error occurred'
            )
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const deletePost = async (
    postID: string,
    userToken: string
): Promise<void> => {
    try {
        await axios.delete(`${mainUrl}posts/${postID}`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        })
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const updatePost = async (
    postID: string,
    caption: string,
    userToken: string
): Promise<GetPostReply> => {
    try {
        const params: UpdatePostRequest = {
            update_data: {
                caption,
            },
        }
        const response = await axios.put<GetPostReply>(
            `${mainUrl}posts/${postID}`,
            params,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
