import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Typography,
    styled,
} from '@mui/material'
import { CreateEvent } from '../../../../models/CreateEvent'
import {
    OutlineContainer,
    OutlineInputBase,
    StyledLinearProgress,
} from '../../../Components/StyledComponents'
import { useAppState } from '../../../../AppStateProvider'
import { useState } from 'react'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
}))

interface CreateEventEditTitleMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    setView: React.Dispatch<React.SetStateAction<string>>
}

interface CreateEventEditTitleTopBarMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    title: string
    setView: React.Dispatch<React.SetStateAction<string>>
}

const CreateEventEditTitleTopBarMobileView = ({
    tempEventData,
    setTempEventData,
    title,
    setView,
}: CreateEventEditTitleTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const handleConfirm = () => {
        if (
            title.length > 0 &&
            title.length <= 40 &&
            tempEventData.title !== title
        ) {
            setTempEventData({ ...tempEventData, title: title })
            setView('edit')
        }
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'relative',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => setView('edit')}
                >
                    Back
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            title.length > 0 &&
                            title.length <= 40 &&
                            tempEventData.title !== title
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleConfirm}
                >
                    Confirm
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        height: 55,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Title
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Toolbar>
        </TopBar>
    )
}

const CreateEventEditTitleMobileView = ({
    tempEventData,
    setTempEventData,
    setView,
}: CreateEventEditTitleMobileViewProps) => {
    const [title, setTitle] = useState(tempEventData.title)
    const [focusState, setFocusState] = useState(false)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }
    const handleFocus = () => {
        setFocusState(true)
    }

    const handleBlur = () => {
        setFocusState(false)
    }
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                p: '0px 16px',
            }}
        >
            <CreateEventEditTitleTopBarMobileView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
                title={title}
                setView={setView}
            />
            <Box
                sx={{
                    pt: '8px',
                    display: 'flex',
                    pr: '24px',
                }}
            >
                <OutlineContainer
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    focused={focusState}
                >
                    <OutlineInputBase
                        placeholder="Title"
                        value={title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </OutlineContainer>
                <IconButton>
                    <ClearRoundedIcon
                        sx={{
                            color: 'text.primary',
                            fontSize: 20,
                        }}
                        onClick={() => setTitle('')}
                    />
                </IconButton>
            </Box>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                    mt: '8px',
                }}
            >
                {title.length}/40
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                    mt: '4px',
                }}
            >
                Enhance your event's visability and appeal by adding a
                captivating title that reflects its essence and engages
                potnential attendees.
            </Typography>
        </Box>
    )
}

export default CreateEventEditTitleMobileView
