import { Box, Typography, styled } from '@mui/material'
import { CreateEvent } from '../../../models/CreateEvent'
import { LoadingBox } from '../../Components/StyledComponents'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { useState } from 'react'
import CreateEventPreviewDetailsView from './CreateEventPreviewDetailsView'
import { hexToRGBA } from '../../../utils/haxToRGBA'

const PicturesBox = styled(Box)(({ theme }) => ({
    width: '100%',
    borderRadius: 50,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignText: 'center',
    display: 'flex',
    padding: '5px 10px',
    cursor: 'pointer',
}))

const PreviewBox = styled(Box)(({ theme }) => ({
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    borderRadius: 10,
    justifyContent: 'center',
}))
export interface CreateEventPreviewProps {
    tempEvent: CreateEvent
}

const CreateEventPreviewView = ({ tempEvent }: CreateEventPreviewProps) => {
    const [value, setValue] = useState('details')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    return (
        <Box
            sx={{
                width: '100%',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: 'relative',
                overflow: 'auto',
                height: 'calc(100% - 56px)',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    style={{
                        overflow: 'hidden',
                        position: 'relative',
                        width: '100%',
                        height: 250,
                    }}
                >
                    {tempEvent.image ? (
                        <img
                            src={tempEvent.image}
                            style={{
                                width: '100%',
                                height: '100%',
                                opacity: 1,
                                zIndex: 1,
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <LoadingBox
                            sx={{
                                width: '100%',
                                height: '100%',
                                opacity: 1,
                                zIndex: 1,
                                objectFit: 'cover',
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 'calc(100% - 48px)',
                            bottom: 0,
                            padding: '8px 16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 30,
                                fontWeight: 800,
                                textAlign: 'left',
                                color: 'textPrimary',
                                textShadow: '0px 0px 5px rgba(0, 0, 0, 0.9)',
                            }}
                        >
                            {tempEvent.title !== ''
                                ? tempEvent.title
                                : 'Event Title'}
                        </Typography>
                        <Box
                            sx={{
                                alighItems: 'left',
                                display: 'flex',
                                alignItems: 'center',
                                textShadow: '0px 0px 5px rgba(0, 0, 0, 0.9)',
                            }}
                        >
                            <FavoriteBorderRoundedIcon
                                sx={{
                                    marginRight: '4px',
                                    fontSize: 20,
                                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                }}
                            />
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                fontSize={16}
                                sx={{
                                    textShadow:
                                        '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                }}
                            >
                                0 likes
                            </Typography>
                            <Box width={20} />
                            <RepeatRoundedIcon
                                sx={{
                                    marginRight: '4px',
                                    fontSize: 20,
                                    boxShadow:
                                        '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                }}
                            />
                            <Typography
                                color="textPrimary"
                                fontSize={16}
                                sx={{
                                    textShadow:
                                        '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                }}
                            >
                                0 posts
                            </Typography>
                            <Box width={20} />
                            <PersonOutlineRoundedIcon
                                sx={{
                                    marginRight: '4px',
                                    fontSize: 20,
                                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                }}
                            />

                            <Typography
                                variant="body1"
                                color="textPrimary"
                                fontSize={16}
                                sx={{
                                    textShadow:
                                        '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                }}
                            >
                                0 attendees
                            </Typography>
                        </Box>
                        <Box>
                            <PicturesBox
                                sx={{ marginTop: '8px' }}
                                // onClick={() =>
                                //     navigateToEventPhotos(
                                //         event,
                                //         eventImage,
                                //         navigate,
                                //         state,
                                //         setState,
                                //         tabIndex!
                                //     )
                                // }
                            >
                                <Typography
                                    color="textPrimary"
                                    sx={{ fontSize: 14 }}
                                >
                                    See all 0 photos
                                </Typography>
                            </PicturesBox>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        padding: '0px 16px',
                        pt: '8px',
                        pb: '16px',
                        borderRadius: '0px 0px 10px 10px',
                    }}
                >
                    <TabBox>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <StyledTab label="Details" value="details" />
                            <StyledTab label="Posts" value="posts" />
                            <StyledTab label="Attendees" value="attendees" />
                        </StyledTabs>
                    </TabBox>
                    {value === 'details' ? (
                        <CreateEventPreviewDetailsView tempEvent={tempEvent} />
                    ) : value === 'posts' ? (
                        <></>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    left: 12,
                    top: 8,
                }}
            >
                <PreviewBox
                    sx={{
                        padding: '4px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                    >
                        Preview
                    </Typography>
                </PreviewBox>
            </Box>
        </Box>
    )
}

export default CreateEventPreviewView
