import {
    AppBar,
    Box,
    InputBase,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import { useAppState } from '../../../AppStateProvider'
import { DataStatus } from '../../../models/Dataloading'
import { Post } from '../../../models/Post'
import {
    LoadingBox,
    PrimaryDivider,
    StyledLinearProgress,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { timeDifferenceFromCurrentTime } from '../../../utils/TimeDifference'
import { createComment } from '../../../api/RestComment'
import { Comment } from '../../../models/Comment'
import HighlightTypography from '../../Components/HighlightTypography'
import EventSubPostView from '../../EventView/EventSubPostView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { useEffect, useState } from 'react'
import { grabUserPhoto } from '../../../api/RestUser'
const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: 'none',
    height: 55,
}))

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    width: '100%',
    paddingTop: `55px`,
    zIndex: theme.zIndex.drawer + 5,
    position: 'relative',
    paddingBottom: '16px',
}))
const ContainedEventBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    padding: '8px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
}))
interface CreateCommentExpandMobileViewProps {
    post: Post
    postImage: string | null
    setPostImage: React.Dispatch<React.SetStateAction<string | null>>
    postImageStatus: DataStatus
    setPostImageStatus: React.Dispatch<React.SetStateAction<DataStatus>>
    postAuthorImage: string | null
    setPostAuthorImage: React.Dispatch<React.SetStateAction<string | null>>
    postAuthorImageStatus: DataStatus
    setPostAuthorImageStatus: React.Dispatch<React.SetStateAction<DataStatus>>
    selectedComment: Comment | null
    setSelectedComment: React.Dispatch<React.SetStateAction<Comment | null>>
    comments: Comment[] | null
    setComments: React.Dispatch<React.SetStateAction<Comment[] | null>>
    showExpandComment: boolean
    setShowExpandComment: React.Dispatch<React.SetStateAction<boolean>>
    inputComment: string
    setInputComment: React.Dispatch<React.SetStateAction<string>>
    setFocused: React.Dispatch<React.SetStateAction<boolean>>
    setCommenting: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateCommentExpandMobileView = ({
    post,
    postImage,
    setPostImage,
    postImageStatus,
    setPostImageStatus,
    postAuthorImage,
    setPostAuthorImage,
    postAuthorImageStatus,
    selectedComment,
    setSelectedComment,
    comments,
    setComments,
    showExpandComment,
    setShowExpandComment,
    inputComment,
    setInputComment,
    setFocused,
    setCommenting,
}: CreateCommentExpandMobileViewProps) => {
    const { state, setState } = useAppState()
    const [selectedCommentImage, setSelectedCommentImage] = useState<
        string | null
    >(null)
    useEffect(() => {
        if (selectedComment) {
            grabUserPhoto(selectedComment.author.image, state.usertoken).then(
                (response) => {
                    setSelectedCommentImage(response)
                }
            )
        }
    })
    return (
        <Container>
            <CreateCommentExpandTopBarMobileView
                post={post}
                inputComment={inputComment}
                setInputComment={setInputComment}
                setShowExpandComment={setShowExpandComment}
                comments={comments}
                setSelectedComment={setSelectedComment}
                setComments={setComments}
                setFocused={setFocused}
                setCommenting={setCommenting}
            />
            <Box
                sx={{
                    p: '16px 16px',
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                    }}
                >
                    <Box>
                        {postAuthorImage ? (
                            <UserPhotoCircle
                                sx={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                }}
                                includeBorder={false}
                            >
                                <img
                                    src={postAuthorImage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </UserPhotoCircle>
                        ) : (
                            <ErrorAccountAvatarView
                                size={35}
                                includeBorder={false}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <PrimaryDivider
                            orientation="vertical"
                            flexItem
                            sx={{
                                borderRightWidth: '2px',
                                height: '100%',
                                mr: `${34 / 2}px`,
                                mt: '8px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginLeft: '12px',
                        height: '100%',
                        width: '100%',
                        float: 'left',
                        alignItems: 'top',
                        flex: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            {post.author.name}
                        </Typography>
                        {post.is_host ? (
                            <SecurityRoundedIcon
                                sx={{
                                    color: 'text.host',
                                    fontSize: 18,
                                    ml: '4px',
                                }}
                            />
                        ) : post.attending ? (
                            <PersonRoundedIcon
                                sx={{
                                    color: 'text.attending',
                                    fontSize: 18,
                                    ml: '4px',
                                }}
                            />
                        ) : null}
                        <Typography
                            sx={{
                                fontSize: 14,
                                ml: '4px',
                                color: 'text.secondary',
                            }}
                        >
                            @{post.author.username}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                ml: '4px',
                                color: 'text.secondary',
                            }}
                        >
                            {'\u{2022} ' +
                                timeDifferenceFromCurrentTime(post.created_at)}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.primary',
                        }}
                    >
                        {post.event.title}
                    </Typography>
                    <Box
                        sx={{
                            fontSize: 14,
                            paddingBottom: '8px',
                        }}
                    >
                        <HighlightTypography
                            text={post.caption}
                            fontSize={14}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            aspectRatio: '20/9',
                            position: 'relative',
                            mb: '8px',
                        }}
                    >
                        {postImageStatus === DataStatus.success && postImage ? (
                            <img
                                src={postImage}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                }}
                            />
                        ) : postImageStatus === DataStatus.loading ? (
                            <LoadingBox />
                        ) : (
                            <LoadingBox />
                        )}
                    </Box>
                </Box>
            </Box>
            {selectedComment && (
                <Box
                    sx={{
                        p: '16px 16px',
                        pt: '0px',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            justifyContent: 'center',
                        }}
                    >
                        <Box>
                            {postAuthorImage ? (
                                <UserPhotoCircle
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        borderRadius: '50%',
                                        overflow: 'hidden',
                                    }}
                                    includeBorder={false}
                                >
                                    <img
                                        src={postAuthorImage}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </UserPhotoCircle>
                            ) : (
                                <ErrorAccountAvatarView
                                    size={35}
                                    includeBorder={false}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <PrimaryDivider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    borderRightWidth: '2px',
                                    height: '100%',
                                    mr: `${34 / 2}px`,
                                    mt: '8px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: '12px',
                            height: '100%',
                            width: '100%',
                            float: 'left',
                            alignItems: 'top',
                            flex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'text.primary',
                                }}
                            >
                                {selectedComment.author.name}
                            </Typography>
                            {selectedComment.is_host ? (
                                <SecurityRoundedIcon
                                    sx={{
                                        color: 'text.host',
                                        fontSize: 18,
                                        ml: '4px',
                                    }}
                                />
                            ) : selectedComment.attending ? (
                                <PersonRoundedIcon
                                    sx={{
                                        color: 'text.attending',
                                        fontSize: 18,
                                        ml: '4px',
                                    }}
                                />
                            ) : null}
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    ml: '4px',
                                    color: 'text.secondary',
                                }}
                            >
                                @{selectedComment.author.username}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    ml: '4px',
                                    color: 'text.secondary',
                                }}
                            >
                                {'\u{2022} ' +
                                    timeDifferenceFromCurrentTime(
                                        post.created_at
                                    )}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                fontSize: 14,
                                paddingBottom: '8px',
                            }}
                        >
                            <HighlightTypography
                                text={selectedComment.body}
                                fontSize={14}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    p: '16px 16px',
                    pt: '0px',
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                    }}
                >
                    <Box>
                        {state.userimage ? (
                            <UserPhotoCircle
                                sx={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                }}
                                includeBorder={false}
                            >
                                <img
                                    src={state.userimage}
                                    alt="User"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </UserPhotoCircle>
                        ) : (
                            <ErrorAccountAvatarView
                                size={35}
                                includeBorder={false}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <PrimaryDivider
                            orientation="vertical"
                            flexItem
                            sx={{
                                borderRightWidth: '2px',
                                height: '100%',
                                mr: `${34 / 2}px`,
                                mt: '8px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginLeft: '12px',
                        height: '100%',
                        width: '100%',
                        float: 'left',
                        alignItems: 'top',
                        flex: 1,
                    }}
                >
                    <Box display="flex" alignItems={'center'}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            {state.name}
                        </Typography>
                        {post.event.is_host ? (
                            <SecurityRoundedIcon
                                sx={{
                                    color: 'text.host',
                                    fontSize: 18,
                                    ml: '4px',
                                }}
                            />
                        ) : post.event.attending ? (
                            <PersonRoundedIcon
                                sx={{
                                    color: 'text.attending',
                                    fontSize: 18,
                                    ml: '4px',
                                }}
                            />
                        ) : null}
                        <Typography
                            sx={{
                                fontSize: 14,
                                marginLeft: '4px',
                                color: 'text.secondary',
                            }}
                        >
                            @{state.username}
                        </Typography>
                    </Box>
                    <StyledInputBase
                        placeholder={`Reply to ${post.author.name}`}
                        inputProps={{ 'aria-label': 'search' }}
                        multiline
                        fullWidth
                        value={inputComment}
                        onChange={(e) => setInputComment(e.target.value)}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    p: '0px 16px',
                    pb: '60px',
                }}
            >
                <ContainedEventBox
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            pointerEvents: 'none',
                        }}
                    >
                        <EventSubPostView event={post.event} />
                    </Box>
                </ContainedEventBox>
            </Box>
        </Container>
    )
}

interface CreateCommentExpandTopBarMobileViewProps {
    post: Post
    inputComment: string
    setInputComment: React.Dispatch<React.SetStateAction<string>>
    setShowExpandComment: React.Dispatch<React.SetStateAction<boolean>>
    comments: Comment[] | null
    setSelectedComment: React.Dispatch<React.SetStateAction<Comment | null>>
    setComments: React.Dispatch<React.SetStateAction<Comment[] | null>>
    setFocused: React.Dispatch<React.SetStateAction<boolean>>
    setCommenting: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateCommentExpandTopBarMobileView = ({
    post,
    inputComment,
    setInputComment,
    setShowExpandComment,
    comments,
    setSelectedComment,
    setComments,
    setFocused,
    setCommenting,
}: CreateCommentExpandTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const handleCreateCommment = () => {
        createComment(inputComment, post.id, state.usertoken)
            .then((response) => {
                if (comments === null) {
                    setComments([response.comment])
                    setInputComment('')
                    setFocused(false)
                    setCommenting(false)
                } else {
                    setComments([...comments, response.comment])
                    setInputComment('')
                    setFocused(false)
                    setCommenting(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        setShowExpandComment(false)
    }
    const handleCanncelComment = () => {
        setInputComment('')
        setShowExpandComment(false)
        setFocused(false)
        setCommenting(false)
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={handleCanncelComment}
                >
                    Cancel
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            inputComment !== ''
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleCreateCommment}
                >
                    Comment
                </Typography>
            </Toolbar>
        </TopBar>
    )
}
export default CreateCommentExpandMobileView
