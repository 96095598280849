import { useEffect, useState } from 'react'
import { getTickets, getTicketsReply } from '../../api/RestTicket'
import { Ticket } from '../../models/Ticket'
import { DataStatus } from '../../models/Dataloading'
import { useAppState } from '../../AppStateProvider'
import exp from 'constants'
import { Box, List, Typography } from '@mui/material'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import ErrorTabView from '../Components/StaticPages/ErrorView/ErrorTabView'
import EmptyTicketsView, {
    EmptyTicketsViewType,
} from '../Components/StaticPages/EmptyView/EmptyTicketsView'
import TicketItemView from './TicketItemView'

interface ActiveExpiredTicketViewProps {
    tabIndex: string
}

const ActiveExpiredTicketView = ({
    tabIndex,
}: ActiveExpiredTicketViewProps) => {
    const [ticketState, setTicketState] = useState<Ticket[]>([])
    const { state } = useAppState()
    const [ticketLoaded, setTicketLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        try {
            getTickets(tabIndex, null, 20, 0, state.usertoken)
                .then((response) => {
                    console.log(response)
                    setTicketState(response.tickets)
                    setTicketLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setTicketLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setTicketLoaded(DataStatus.failure)
        }
    }, [tabIndex])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {ticketLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : ticketLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : ticketLoaded === DataStatus.success &&
              ticketState.length === 0 ? (
                <EmptyTicketsView
                    type={
                        tabIndex === 'active'
                            ? EmptyTicketsViewType.active
                            : EmptyTicketsViewType.expired
                    }
                />
            ) : (
                <Box
                    sx={{
                        marginTop: '4px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',

                            color: 'text.tertiary',
                            mt: '8px',
                        }}
                    >
                        {tabIndex === 'active'
                            ? `${ticketState.length} Active Tickets`
                            : `${ticketState.length} Expired Tickets`}
                    </Typography>
                    <List sx={{ padding: 0 }}>
                        {ticketState.map((ticket, index) => (
                            <Box>
                                <Box
                                    key={ticket.id}
                                    sx={{ padding: '8px 0px' }}
                                >
                                    <TicketItemView ticket={ticket} />
                                </Box>

                                {/* <PrimaryDivider
                            sx={{
                                marginTop: '4px',
                                marginBottom: '4px',
                            }}
                            /> */}
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    )
}

export default ActiveExpiredTicketView
