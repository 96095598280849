import { Box, styled } from '@mui/material'
import { LocationSearch } from '../../../models/Location'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'

const Search = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    paddingRight: '8px',
    paddingLeft: '8px',
    alignItems: 'center',
    display: 'flex',
    height: 35,
    fontFamily: 'Roboto',
}))

const SearchItem = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.quaternary,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 10px',
    fontSize: 14,
    height: 10,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
}))

interface LocationSearchDiscoverBoxMobileViewProps {
    locationSearchState: LocationSearch | null
    setLocationSearchState: React.Dispatch<
        React.SetStateAction<LocationSearch | null>
    >
    setShowSearchLocation: React.Dispatch<React.SetStateAction<boolean>>
}

const LocationSearchDiscoverBoxMobileView = ({
    locationSearchState,
    setLocationSearchState,
    setShowSearchLocation,
}: LocationSearchDiscoverBoxMobileViewProps) => {
    return (
        <Search
            sx={{
                minWidth: '200px',
            }}
            onClick={() => setShowSearchLocation(true)}
        >
            <PlaceRoundedIcon
                sx={{
                    fontSize: 20,
                    paddingRight: '0px',
                    color: 'text.tertiary',
                }}
            />
            {locationSearchState && (
                <SearchItem
                    sx={{
                        ml: '4px',
                    }}
                >
                    {locationSearchState.maintext}
                </SearchItem>
            )}
        </Search>
    )
}
export default LocationSearchDiscoverBoxMobileView
