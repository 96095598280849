import React from 'react'
import { User } from '../models/User'
import { AppState } from '../models/AppState'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getFollowers, getUser, grabUserPhoto } from '../api/RestUser'
import { getEvents, grabEventPhoto } from '../api/RestEvent'
import { Post } from '../models/Post'
import { getPosts, grabPostPhoto } from '../api/RestPost'
import { getComments } from '../api/RestComment'
import { tab } from '@testing-library/user-event/dist/tab'
import { Event } from '../models/Event'
import { set } from 'date-fns'
import { CreateEvent } from '../models/CreateEvent'
import { Ticket } from '../models/Ticket'
import ApiManger from '../api/ApiManager'

export const navigateToDiscover = async (
    apiManger: ApiManger,
    navigator: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    apiManger.cancelAllRequests()
    // Clear any existing progress intervals
    if (state.progressintervalids.length > 0) {
        state.progressintervalids.forEach((timerid) => {
            clearInterval(timerid)
            setState((prevState) => {
                return {
                    ...prevState,
                    progressintervalids: prevState.progressintervalids.filter(
                        (value) => value !== timerid
                    ),
                }
            })
        })
    }
    // Initializing progress bar and starting the loading process
    setState((prevState) => {
        return {
            ...prevState,
            showprogress: true,
            progress: 0,
            progresstransition: true,
        }
    })
    const timer = setInterval(() => {
        setState((prevState) => {
            return {
                ...prevState,
                progress: prevState.progress < 80 ? prevState.progress + 5 : 80,
                progresstransition: true,
            }
        })
    }, 200)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: [...prevState.progressintervalids, timer],
        }
    })
    // Fetching the data based on the tab
    if (tab === '/app/discover/featured') {
        apiManger
            .getEvents(null, null, null, 5, 5, 0)
            .then((response) => {
                clearInterval(timer)
                setTimeout(() => {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            showprogress: false,
                            progress: 0,
                        }
                    })
                    navigator('/app/discover/search', {
                        state: { eventstate: response.events, code: 200 },
                    })
                }, 10)
            })
            .catch((error) => {
                console.log(error)
                navigator('/app/discover/search', {
                    state: { eventstate: [], code: 500 },
                })
                clearInterval(timer)
            })
    } else if (tab === '/app/discover/search') {
        apiManger
            .getEvents(null, null, null, 5, 5, 0)
            .then((response) => {
                clearInterval(timer)
                setState((prevState) => {
                    return {
                        ...prevState,
                        progressintervalids:
                            prevState.progressintervalids.filter(
                                (value) => value !== timer
                            ),
                    }
                })
                setState((prevState) => {
                    return {
                        ...prevState,
                        progress: 100,
                        progresstransition: false,
                    }
                })
                navigator('/app/discover/search', {
                    state: { eventstate: response.events, code: 200 },
                })
                setTimeout(() => {
                    setState((prevState) => {
                        return { ...prevState, showprogress: false }
                    })
                }, 400)
            })
            .catch((error) => {
                console.log(error)
                clearInterval(timer)
                setState((prevState) => {
                    return {
                        ...prevState,
                        progress: 0,
                        progresstransition: false,
                    }
                })
                navigator('/app/discover/search', {
                    state: { eventstate: [], code: 500 },
                })
            })
    } else if (tab === '/app/discover/favorited') {
        apiManger
            .getEvents(null, null, null, 5, 5, 0)
            .then((response) => {
                navigator('/app/discover/search', {
                    state: { eventstate: response.events, code: 200 },
                })
            })
            .catch((error) => {
                console.log(error)
                navigator('/app/discover/search', {
                    state: { eventstate: [], code: 500 },
                })
            })
    }
}

export const navigateToFeed = async (
    apiManager: ApiManger,
    navigator: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    apiManager.cancelAllRequests()
    if (state.progressintervalids.length > 0) {
        state.progressintervalids.forEach((timerid) => {
            clearInterval(timerid)
            setState((prevState) => {
                return {
                    ...prevState,
                    progressintervalids: prevState.progressintervalids.filter(
                        (value) => value !== timerid
                    ),
                }
            })
        })
    }
    setState((prevState) => {
        return {
            ...prevState,
            showprogress: true,
            progress: 0,
            progresstransition: true,
        }
    })
    const timer = setInterval(() => {
        setState((prevState) => {
            return {
                ...prevState,
                progress: prevState.progress < 80 ? prevState.progress + 5 : 80,
                progresstransition: true,
            }
        })
    }, 200)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: [...prevState.progressintervalids, timer],
        }
    })
    if (tab === '/app/feed/fyp') {
        apiManager
            .getPosts('', '', '', 15, 0)
            .then((response) => {
                clearInterval(timer)
                setState((prevState) => {
                    return {
                        ...prevState,
                        progressintervalids:
                            prevState.progressintervalids.filter(
                                (value) => value !== timer
                            ),
                    }
                })
                setState((prevState) => {
                    return {
                        ...prevState,
                        progress: 100,
                        progresstransition: false,
                    }
                })
                navigator('/app/feed/fyp', {
                    state: { poststate: response.posts, code: 200 },
                })
                setTimeout(() => {
                    setState((prevState) => {
                        return { ...prevState, showprogress: false }
                    })
                }, 400)
            })
            .catch((error) => {
                clearInterval(timer)
                setState((prevState) => {
                    return {
                        ...prevState,
                        progress: 0,
                        progresstransition: false,
                    }
                })
                console.log(error)
                navigator('/app/feed/fyp', {
                    state: { poststate: null, code: 500 },
                })
            })
    }
}

export const navigateToCreatePost = async (
    event: Event | null,
    eventImage: string | null,
    eventHostImage: string | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>
) => {
    const eventNav = event
    var eventImageNav = eventImage
    if (eventNav) {
        try {
            if (!eventImageNav) {
                const response = await grabEventPhoto(
                    event.image,
                    state.usertoken
                )
                eventImageNav = response
            }
        } catch (error) {
            console.log(error)
        }
    }
    navigate(`/app/createpost/`, {
        state: {
            event: event,
            eventImage: eventImageNav,
            eventHostImage: eventHostImage,
            caption: '',
        },
    })
}

export const navigateToEvent = async (
    apiManager: ApiManger,
    event: Event,
    eventImage: string | null | undefined,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    apiManager.cancelAllRequests()
    if (state.progressintervalids.length > 0) {
        state.progressintervalids.forEach((timerid) => {
            clearInterval(timerid)
            setState((prevState) => {
                return {
                    ...prevState,
                    progressintervalids: prevState.progressintervalids.filter(
                        (value) => value !== timerid
                    ),
                }
            })
        })
    }
    setState((prevState) => {
        return {
            ...prevState,
            showprogress: true,
            progress: 0,
            progresstransition: true,
        }
    })
    const timer = setInterval(() => {
        setState((prevState) => {
            return {
                ...prevState,
                progress: prevState.progress < 80 ? prevState.progress + 5 : 80,
                progresstransition: true,
            }
        })
    }, 200)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: [...prevState.progressintervalids, timer],
        }
    })
    var eventImageNav = eventImage
    try {
        if (!eventImageNav) {
            const response = await grabEventPhoto(event.image, state.usertoken)
            eventImageNav = response
        }
    } catch (error) {
        console.log(error)
    }
    clearInterval(timer)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: prevState.progressintervalids.filter(
                (value) => value !== timer
            ),
        }
    })
    setState((prevState) => {
        return { ...prevState, progress: 100, progresstransition: false }
    })
    navigate(`/app/event/${event.slug}/${tab}`, {
        state: { event: event, eventImage: eventImageNav },
    })
    setTimeout(() => {
        setState((prevState) => {
            return { ...prevState, showprogress: false }
        })
    }, 400)
}

export const navigateToAccount = async (
    apiManager: ApiManger,
    username: string,
    user: User | null,
    userImage: string | null | undefined,
    eventImage: string | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    console.log('Navigating to account')
    apiManager.cancelAllRequests()
    if (state.progressintervalids.length > 0) {
        state.progressintervalids.forEach((timerid) => {
            clearInterval(timerid)
            setState((prevState) => {
                return {
                    ...prevState,
                    progressintervalids: prevState.progressintervalids.filter(
                        (value) => value !== timerid
                    ),
                }
            })
        })
    }
    setState((prevState) => {
        return {
            ...prevState,
            showprogress: true,
            progress: 0,
            progresstransition: true,
        }
    })
    const timer = setInterval(() => {
        setState((prevState) => {
            return {
                ...prevState,
                progress: prevState.progress < 80 ? prevState.progress + 5 : 80,
                progresstransition: true,
            }
        })
    }, 200)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: [...prevState.progressintervalids, timer],
        }
    })
    var userImageNav = userImage
    var eventImageNav = eventImage
    var userNav = user

    if (!userNav) {
        try {
            const response = await apiManager.getUser(username)
            console.log(response)
            userNav = response.profile
            console.log(userNav)
        } catch (error) {
            clearInterval(timer)
            setState((prevState) => {
                return {
                    ...prevState,
                    progress: 0,
                    progresstransition: false,
                }
            })
            return
        }
    }

    try {
        if (!userImageNav) {
            const response = await apiManager.grabUserPhoto(userNav.image)
            userImageNav = response
        }
    } catch (error) {
        console.log(error)
    }
    try {
        if (!eventImageNav) {
            const eventsResponse = await apiManager.getEvents(
                null,
                userNav.username,
                null,
                0,
                1,
                0
            )
            const eventPhotoResponse = await apiManager.grabEventPhoto(
                eventsResponse.events[0].image
            )
            eventImageNav = eventPhotoResponse
        }
    } catch (error) {
        console.log(error, 'Error')
    }
    clearInterval(timer)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: prevState.progressintervalids.filter(
                (value) => value !== timer
            ),
        }
    })
    setState((prevState) => {
        return { ...prevState, progress: 100, progresstransition: false }
    })

    navigate(`/app/account/${userNav.username}/${tab}`, {
        state: {
            user: userNav,
            userImage: userImageNav,
            eventImage: eventImageNav,
        },
    })
    setTimeout(() => {
        setState((prevState) => {
            return { ...prevState, showprogress: false }
        })
    }, 400)
}

export const navigateToPost = async (
    apiManger: ApiManger,
    post: Post,
    postImage: string | null | undefined,
    postAuthorImage: string | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>
) => {
    apiManger.cancelAllRequests()
    if (state.progressintervalids.length > 0) {
        state.progressintervalids.forEach((timerid) => {
            clearInterval(timerid)
            setState((prevState) => {
                return {
                    ...prevState,
                    progressintervalids: prevState.progressintervalids.filter(
                        (value) => value !== timerid
                    ),
                }
            })
        })
    }
    setState((prevState) => {
        return {
            ...prevState,
            showprogress: true,
            progress: 0,
            progresstransition: true,
        }
    })
    const timer = setInterval(() => {
        setState((prevState) => {
            return {
                ...prevState,
                progress: prevState.progress < 80 ? prevState.progress + 5 : 80,
                progresstransition: true,
            }
        })
    }, 200)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: [...prevState.progressintervalids, timer],
        }
    })
    var postImageNav = postImage
    var postAuthorImageNav = postAuthorImage
    var comments = null
    try {
        if (!postImageNav) {
            const response = await grabPostPhoto(post.image, state.usertoken)
            postImageNav = response
        }
    } catch (error) {
        console.log(error)
    }
    try {
        if (!postAuthorImageNav) {
            const response = await apiManger.grabUserPhoto(
                post.author.image,
            )
            console.log(response)
            postAuthorImageNav = response
        }
    } catch (error) {
        console.log(error)
    }
    try {
        const response = await apiManger.getComments(post.id)
        console.log(response)
        comments = response.comments
    } catch (error) {
        console.log(error)
    }
    clearInterval(timer)
    setState((prevState) => {
        return {
            ...prevState,
            progressintervalids: prevState.progressintervalids.filter(
                (value) => value !== timer
            ),
        }
    })
    setState((prevState) => {
        return { ...prevState, progress: 100, progresstransition: false }
    })
    navigate(`/app/post/${post.id}`, {
        state: {
            post: post,
            postImage: postImageNav,
            postAuthorImage: postAuthorImageNav,
            comments: comments,
        },
    })
    setTimeout(() => {
        setState((prevState) => {
            return { ...prevState, showprogress: false }
        })
    }, 400)
}

export const navigateToFollowers = async (
    user: User,
    userImage: string | null,
    eventImage: string | null,
    followers: User[] | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tabIndex: string
) => {
    var followersNav = followers
    if (!followersNav) {
        try {
            const response = await getFollowers(user.username, state.usertoken)
            followersNav = response
        } catch (error) {
            console.log(error)
        }
    }
    navigate(`/app/account/${user.username}/${tabIndex}/ff/followers`, {
        state: {
            user: user,
            userImage: userImage,
            eventImage: eventImage,
            followers: followers,
            following: null,
        },
    })
}

export const navigateToFollowing = async (
    user: User,
    userImage: string | null,
    eventImage: string | null,
    following: User[] | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tabIndex: string
) => {
    var followingNav = following
    if (!followingNav) {
        try {
            const response = await getFollowers(user.username, state.usertoken)
            followingNav = response
        } catch (error) {
            console.log(error)
        }
    }
    navigate(`/app/account/${user.username}/${tabIndex}/ff/following`, {
        state: {
            user: user,
            userImage: userImage,
            eventImage: eventImage,
            followers: null,
            following: following,
        },
    })
}

export const navigateToEventPhotos = async (
    event: Event,
    eventImage: string | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    navigate(`/app/event/${event.slug}/${tab}/images`, {
        state: { event: event, eventImage: eventImage },
    })
}

export const navigateToAccountEdit = async (
    user: User,
    userImage: string | null,
    eventImage: string | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    navigate(`/app/account/${user.username}/${tab}/edit`, {
        state: { user: user, userImage: userImage, eventImage: eventImage },
    })
}

export const navigateToCreatePostSelectEvent = async (
    event: Event | null,
    eventImage: string | null,
    eventHostImage: string | null,
    navigate: NavigateFunction,
    tab: string
) => {
    navigate(`/app/createpost/selectevent/${tab}`, {
        state: {
            event: event,
            eventImage: eventImage,
            eventHostImage: eventHostImage,
            caption: '',
        },
    })
}

export const navigateTickets = async (
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>,
    tab: string
) => {
    navigate(`/app/tickets/${tab}`)
}

export const navigateToCreateEvent = async (
    tempEventData: CreateEvent | null,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>
) => {
    navigate(`/app/createevent`, {
        state: { tempEventData: tempEventData },
    })
}

export const navigatetoEditEvent = async (
    tempEventData: CreateEvent,
    eventSlug: string,
    navigate: NavigateFunction,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>
) => {
    navigate(`/app/${eventSlug}/edit`, {
        state: { tempEventData: tempEventData },
    })
}

export const navigateToGiftTickets = async (
    navigate: NavigateFunction,
    ticket: Ticket,
    eventImage: string | null,
    eventHostImage: string | null,
    state: AppState,
    setState: React.Dispatch<React.SetStateAction<AppState>>
) => {
    navigate('/app/gifttickets/', {
        state: {
            ticket: ticket,
            eventImage: eventImage,
            eventHostImage: eventHostImage,
        },
    })
}
