import {
    NavigateFunction,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useState } from 'react'
import { NavigateCreatePostSelectEventState } from '../../../models/NavigateState'
import CreatePostSelectEventTopBar from './CreatePostSelectEventTopBar'
import { Box } from '@mui/material'
import { Event } from '../../../models/Event'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { DataStatus } from '../../../models/Dataloading'
import { getEvents } from '../../../api/RestEvent'
import { StyledCircularProgress } from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import EventSubMobileView from '../../EventView/EventMobileView/EventSubMobileView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'

const CreatePostSelectEventMobileView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const [pageLoaded, setPageLoaded] = useState(false)
    const initialCreatePostEventSelectorState =
        location.state as NavigateCreatePostSelectEventState
    const [selectedEvent, setSelectedEvent] = useState(
        initialCreatePostEventSelectorState.event
    )
    const [eventImage, setEventImage] = useState(
        initialCreatePostEventSelectorState.eventImage
    )
    const [eventHostImage, setEventHostImage] = useState(
        initialCreatePostEventSelectorState.eventHostImage
    )
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const [value, setValue] = useState(tabIndex)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/createpost/selectevent/${tabIndex}`, {
            state: {
                event: selectedEvent,
                eventImage: eventImage,
                eventHostImage: eventHostImage,
            },
        })
    }
    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        }
    }, [])

    return (
        <Box
            sx={{
                height: '100%',
                position: 'relative',
            }}
        >
            <CreatePostSelectEventTopBar
                initialEvent={initialCreatePostEventSelectorState.event}
                selectedEvent={selectedEvent}
                eventImage={eventImage}
                initialEventImage={
                    initialCreatePostEventSelectorState.eventImage
                }
                eventHostImage={eventHostImage}
                initialEventHostImage={
                    initialCreatePostEventSelectorState.eventHostImage
                }
            />
            <TabBox
                sx={{
                    position: 'fixed',
                    width: '100%',
                    top: 55,
                    zIndex: 2,
                    backgroundColor: 'background.primary',
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                >
                    <StyledTab label="Recent" value="recent" />
                    <StyledTab label="Attending" value="attending" />
                    <StyledTab label="Hosting" value="hosting" />
                    <StyledTab label="Favorited" value="favorited" />
                </StyledTabs>
            </TabBox>
            {value === 'recent' ? (
                <CreatePostEventSelectorRecentsMobileView
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    navigate={navigate}
                />
            ) : value === 'attending' ? (
                <CreatePostEventSelectorAttendingMobileView
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    navigate={navigate}
                />
            ) : value === 'hosting' ? (
                <CreatePostEventSelectorHostingMobileView
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    navigate={navigate}
                />
            ) : (
                value === 'favorited' && (
                    <CreatePostEventSelectorRecentsMobileView
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        navigate={navigate}
                    />
                )
            )}
        </Box>
    )
}

export default CreatePostSelectEventMobileView

interface CreatePostEventSelectorRecentsViewProps {
    selectedEvent: Event | null
    setSelectedEvent: React.Dispatch<React.SetStateAction<Event>>
    navigate: NavigateFunction
}
const CreatePostEventSelectorRecentsMobileView = ({
    selectedEvent,
    setSelectedEvent,
    navigate,
}: CreatePostEventSelectorRecentsViewProps) => {
    const { state, setState } = useAppState()
    const [eventState, setEventState] = useState<Event[] | null>(null)
    const [eventStateLoaded, setEventStateLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        if (eventStateLoaded === DataStatus.loading) {
            getEvents(null, null, null, 5, 5, 0, state.usertoken)
                .then((response) => {
                    setEventState(response.events)
                    setEventStateLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventStateLoaded(DataStatus.failure)
                })
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '45px',
                pb: `${navMobileBarHeight}px`,
            }}
        >
            {eventStateLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventStateLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                eventStateLoaded === DataStatus.success && (
                    <Box
                        sx={{
                            marginTop: '8px',
                            width: '100%',
                            display: 'grid',
                        }}
                    >
                        {eventState?.map((event) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate('/app/createpost', {
                                        state: {
                                            event: event,
                                            eventImage: null,
                                            eventHostImage: null,
                                            caption: '',
                                        },
                                    })
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 2,
                                        pointerEvents: 'none',
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                        zIndex: 1,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <EventSubMobileView event={event} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )
            )}
        </Box>
    )
}

const CreatePostEventSelectorAttendingMobileView = ({
    selectedEvent,
    setSelectedEvent,
    navigate,
}: CreatePostEventSelectorRecentsViewProps) => {
    const { state, setState } = useAppState()
    const [eventState, setEventState] = useState<Event[] | null>(null)
    const [eventStateLoaded, setEventStateLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        if (eventStateLoaded === DataStatus.loading) {
            getEvents(null, state.username, null, 5, 5, 0, state.usertoken)
                .then((response) => {
                    setEventState(response.events)
                    setEventStateLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventStateLoaded(DataStatus.failure)
                })
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '45px',
                pb: `${navMobileBarHeight}px`,
            }}
        >
            {eventStateLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventStateLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                eventStateLoaded === DataStatus.success && (
                    <Box
                        sx={{
                            marginTop: '8px',
                            width: '100%',
                            display: 'grid',
                        }}
                    >
                        {eventState?.map((event) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate('/app/createpost', {
                                        state: {
                                            event: event,
                                            eventImage: null,
                                            eventHostImage: null,
                                            caption: '',
                                        },
                                    })
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 2,
                                        pointerEvents: 'none',
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                        zIndex: 1,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <EventSubMobileView event={event} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )
            )}
        </Box>
    )
}

const CreatePostEventSelectorHostingMobileView = ({
    selectedEvent,
    setSelectedEvent,
    navigate,
}: CreatePostEventSelectorRecentsViewProps) => {
    const { state, setState } = useAppState()
    const [eventState, setEventState] = useState<Event[] | null>(null)
    const [eventStateLoaded, setEventStateLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        if (eventStateLoaded === DataStatus.loading) {
            getEvents(state.username, null, null, 5, 5, 0, state.usertoken)
                .then((response) => {
                    setEventState(response.events)
                    setEventStateLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventStateLoaded(DataStatus.failure)
                })
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '45px',
                pb: `${navMobileBarHeight}px`,
            }}
        >
            {eventStateLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventStateLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                eventStateLoaded === DataStatus.success && (
                    <Box
                        sx={{
                            marginTop: '8px',
                            width: '100%',
                            display: 'grid',
                        }}
                    >
                        {eventState?.map((event) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate('/app/createpost', {
                                        state: {
                                            event: event,
                                            eventImage: null,
                                            eventHostImage: null,
                                            caption: '',
                                        },
                                    })
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 2,
                                        pointerEvents: 'none',
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                        zIndex: 1,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <EventSubMobileView event={event} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )
            )}
        </Box>
    )
}
