import { Box, Button, styled, Typography } from '@mui/material'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import {
    SelectButton,
    StyledCircularProgress,
    StyledIconButton,
} from '../../../Components/StyledComponents'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded'
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import { CreateEvent } from '../../../../models/CreateEvent'
import { useNavigate } from 'react-router-dom'

const BottomBar = styled(Box)(({ theme }) => ({
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `100%`,
    zIndex: theme.zIndex.drawer + 1,
    // borderTop: '1px solid ' + theme.palette.border.secondary,
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.8)',
}))

const PostConfigButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '10px',
    padding: '4px 16px',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.9)',
}))

interface CreateEventPreviewBottomBarMobileViewProps {
    tempEvent: CreateEvent
}

const CreateEventPreviewBottomBarMobileView = ({
    tempEvent,
}: CreateEventPreviewBottomBarMobileViewProps) => {
    const navigate = useNavigate()
    const triggerFileSelect = () => {
        document.getElementById('fileInput')?.click()
    }
    return (
        <BottomBar
            sx={{
                overflow: 'hidden',
                bottom: 0,
                p: '0px',
            }}
        >
            <Box
                sx={{
                    p: '8px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    mb: `${navMobileBarHeight - 10}px`,
                    height: `40px`,
                }}
            >
                <SelectButton
                    fullWidth
                    sx={{
                        fontSize: 16,
                    }}
                >
                    Create Event
                </SelectButton>
            </Box>
        </BottomBar>
    )
}

export default CreateEventPreviewBottomBarMobileView
