import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { useAppState } from '../../../AppStateProvider'
import { useCallback, useEffect, useState } from 'react'
import { NavigateAccountEditState } from '../../../models/NavigateState'
import { User } from '../../../models/User'
import { useDropzone } from 'react-dropzone'
import {
    AppBar,
    Avatar,
    Box,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { styled } from '@mui/system'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
    PrimaryDivider,
    StyledLinearProgress,
} from '../../Components/StyledComponents'

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.quaternary,
    '&:hover': {
        backgroundColor: theme.palette.background.quaternary,
    },
}))

const ImageBox = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.tertiary,
    cursor: 'pointer',
}))

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    boxShadow: 'none',
    height: 55,
}))

const EditAccountMobileView = () => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const [pageLoaded, setPageLoaded] = useState(false)
    const { username } = useParams<{ username: string }>()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const initialAccountEditState = location.state as NavigateAccountEditState
    const parentElement = document.getElementById('root')
    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        }
    }, [])
    const [tempUserState, setTempUserState] = useState<User>(
        initialAccountEditState.user
    )
    const [image, setImage] = useState<string | null>(
        initialAccountEditState.userImage
    )
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setImage(reader.result as string)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const removeImage = () => {
        setImage(null)
    }
    const handleEdit = () => {
        if (image) {
            apiManager
                .uploadPhoto(image)
                .then((response) => {
                    setState((state) => ({
                        ...state,
                        userimagelink: response,
                        userimage: null,
                    }))
                    navigate(`/app/account/${username}/${tabIndex}`, {
                        state: {
                            user: initialAccountEditState.user,
                            userImage: image,
                            eventImage: initialAccountEditState.eventImage,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <Box
            sx={{
                height: '100%',
                mt: '55px',
                pb: `${navMobileBarHeight}px`,
            }}
        >
            {username && tabIndex && initialAccountEditState && (
                <EditAccountTopBarMobileView
                    tempUserState={tempUserState}
                    initialUserState={initialAccountEditState.user}
                    initialImage={initialAccountEditState.userImage}
                    tempImage={image}
                    username={username}
                    tabIndex={tabIndex}
                    initialAccountEditState={initialAccountEditState}
                />
            )}
            <Box
                sx={{
                    p: '16px 0px',
                }}
            >
                <Box
                    display="flex"
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <ImageBox
                        sx={{
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {image ? (
                            <Avatar
                                src={image}
                                alt="Circular Image"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        ) : (
                            <PersonRoundedIcon
                                sx={{
                                    fontSize: 30,
                                    width: '75%',
                                    height: '75%',
                                    color: 'text.tertiary',
                                }}
                            />
                        )}
                        <ClearIconButton
                            onClick={removeImage}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: -10,
                            }}
                        >
                            <CloseRoundedIcon />
                        </ClearIconButton>
                    </ImageBox>
                </Box>
                <Typography
                    sx={{
                        mt: '16px',
                        fontSize: '14px',
                        color: 'text.secondary',
                        textAlign: 'center',
                    }}
                >
                    Edit Profile Picture
                </Typography>
                <PrimaryDivider sx={{ m: '16px 0px' }} />
                <Box
                    sx={{
                        display: 'flex',
                        mt: '16px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.secondary',
                            mb: 'auto',
                        }}
                    >
                        Name
                    </Typography>
                    <Box flex={1} />
                    <Box
                        sx={{
                            width: '200px',
                            maxWidth: '200px',
                            height: '100%',
                            alignItems: 'top',
                        }}
                        // onClick={() => setView('description')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color:
                                    tempUserState.name === ''
                                        ? 'text.disabled'
                                        : 'text.primary',
                            }}
                        >
                            {tempUserState.name !== ''
                                ? tempUserState.name
                                : 'Name'}
                        </Typography>
                        <PrimaryDivider
                            sx={{
                                mt: '4px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '16px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.secondary',
                            mb: 'auto',
                        }}
                    >
                        Username
                    </Typography>
                    <Box flex={1} />
                    <Box
                        sx={{
                            width: '200px',
                            maxWidth: '200px',
                            height: '100%',
                            alignItems: 'top',
                        }}
                        // onClick={() => setView('description')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color:
                                    tempUserState.username === ''
                                        ? 'text.disabled'
                                        : 'text.primary',
                            }}
                        >
                            {tempUserState.username !== ''
                                ? `@${tempUserState.username}`
                                : 'Name'}
                        </Typography>
                        <PrimaryDivider
                            sx={{
                                mt: '4px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '16px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.secondary',
                            mb: 'auto',
                        }}
                    >
                        Bio
                    </Typography>
                    <Box flex={1} />
                    <Box
                        sx={{
                            width: '200px',
                            maxWidth: '200px',
                            height: '100%',
                            alignItems: 'top',
                        }}
                        // onClick={() => setView('description')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: !tempUserState.bio
                                    ? 'text.disabled'
                                    : 'text.primary',
                            }}
                        >
                            {tempUserState.bio ? tempUserState.bio : 'Bio'}
                        </Typography>
                        <PrimaryDivider
                            sx={{
                                mt: '4px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '16px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.secondary',
                            mb: 'auto',
                        }}
                    >
                        Link
                    </Typography>
                    <Box flex={1} />
                    <Box
                        sx={{
                            width: '200px',
                            maxWidth: '200px',
                            height: '100%',
                            alignItems: 'top',
                        }}
                        // onClick={() => setView('description')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.disabled',
                            }}
                        >
                            Link
                        </Typography>
                        <PrimaryDivider
                            sx={{
                                mt: '4px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '16px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.secondary',
                            mb: 'auto',
                        }}
                    >
                        Instagram
                    </Typography>
                    <Box flex={1} />
                    <Box
                        sx={{
                            width: '200px',
                            maxWidth: '200px',
                            height: '100%',
                            alignItems: 'top',
                        }}
                        // onClick={() => setView('description')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color:
                                    tempUserState.instagram === ''
                                        ? 'text.disabled'
                                        : 'text.primary',
                            }}
                        >
                            {tempUserState.instagram !== ''
                                ? `@${tempUserState.instagram}`
                                : 'Instagram'}
                        </Typography>
                        <PrimaryDivider
                            sx={{
                                mt: '4px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '16px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.secondary',
                            mb: 'auto',
                        }}
                    >
                        Twitter
                    </Typography>
                    <Box flex={1} />
                    <Box
                        sx={{
                            width: '200px',
                            maxWidth: '200px',
                            height: '100%',
                            alignItems: 'top',
                        }}
                        // onClick={() => setView('description')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color:
                                    tempUserState.twitter === ''
                                        ? 'text.disabled'
                                        : 'text.primary',
                            }}
                        >
                            {tempUserState.twitter !== ''
                                ? `@${tempUserState.twitter}`
                                : 'Twitter'}
                        </Typography>
                        <PrimaryDivider
                            sx={{
                                mt: '4px',
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

interface EditAccountTopBarMobileViewProps {
    tempUserState: User
    initialUserState: User
    initialImage: string | null
    tempImage: string | null
    username: string
    tabIndex: string
    initialAccountEditState: NavigateAccountEditState
}

const EditAccountTopBarMobileView = ({
    tempUserState,
    initialUserState,
    initialImage,
    tempImage,
    username,
    tabIndex,
    initialAccountEditState,
}: EditAccountTopBarMobileViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const handleEdit = () => {}
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() =>
                        navigate(`/app/account/${username}/${tabIndex}`, {
                            state: {
                                user: initialAccountEditState.user,
                                userImage: initialAccountEditState.userImage,
                                eventImage: initialAccountEditState.eventImage,
                            },
                        })
                    }
                >
                    Cancel
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            (initialImage !== tempImage && tempImage) ||
                            initialUserState !== tempUserState
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleEdit}
                >
                    Edit
                </Typography>
            </Toolbar>
        </TopBar>
    )
}

export default EditAccountMobileView
