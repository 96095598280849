import { Box, Drawer, IconButton, styled, Typography } from '@mui/material'
import { Post } from '../../../models/Post'
import { PrimaryDivider } from '../../Components/StyledComponents'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ReactDOM from 'react-dom'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { useAppState } from '../../../AppStateProvider'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import { deletePost } from '../../../api/RestPost'
import { useNavigate } from 'react-router-dom'

const ItemContainer = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '10px',
}))

const OptionsDrawer = styled(Drawer)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 100000,
    '& .MuiDrawer-paper': {
        width: '100%',
        height: '400px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        padding: '0px',
        background: theme.palette.background.default,
        zIndex: theme.zIndex.drawer + 100000,
    },
}))

interface PostOptionsMobileViewProps {
    post: Post
    showOptions: boolean
    setShowOptions: React.Dispatch<React.SetStateAction<boolean>>
}

const PostOptionsMobileView = ({
    post,
    showOptions,
    setShowOptions,
}: PostOptionsMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const handleClose = () => {
        setShowOptions(false)
    }
    const parentElement = document.getElementById('root')

    const handleDelete = () => {
        deletePost(post.id, state.usertoken)
            .then((response) => {
                navigate(-1)
            })
            .catch((error) => {
                console.error(error)
            })
    }
    return ReactDOM.createPortal(
        <OptionsDrawer
            anchor="bottom"
            open={showOptions}
            onClose={handleClose}
            onClick={function (event) {
                event.stopPropagation()
            }}
        >
            <Box
                sx={{
                    minHeight: '400px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '55px',
                        p: '0px 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        More options
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={handleClose}>
                        <ClearRoundedIcon
                            sx={{
                                color: 'text.primary',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        />
                    </IconButton>
                </Box>
                <PrimaryDivider />
                <Box
                    sx={{
                        m: '16px 16px',
                    }}
                >
                    <ItemContainer>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: '12px 16px',
                            }}
                        >
                            <FavoriteRoundedIcon
                                sx={{
                                    fontSize: '20px',
                                    color: 'text.primary',
                                    mr: '8px',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: 'text.primary',
                                    fontWeight: 'bold',
                                }}
                            >
                                Favorite
                            </Typography>
                        </Box>
                        <PrimaryDivider />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: '12px 16px',
                            }}
                        >
                            <IosShareRoundedIcon
                                sx={{
                                    fontSize: '20px',
                                    color: 'text.primary',
                                    mr: '8px',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: 'text.primary',
                                    fontWeight: 'bold',
                                }}
                            >
                                Share
                            </Typography>
                        </Box>
                    </ItemContainer>
                    {post.author.username === state.username ? (
                        <ItemContainer
                            sx={{
                                mt: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                            >
                                <EditRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.primary',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Edit
                                </Typography>
                            </Box>
                            <PrimaryDivider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                                onClick={handleDelete}
                            >
                                <DeleteRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.error',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.error',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Delete
                                </Typography>
                            </Box>
                        </ItemContainer>
                    ) : (
                        <ItemContainer
                            sx={{
                                mt: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                            >
                                <PersonRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.primary',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Follow
                                </Typography>
                            </Box>
                            <PrimaryDivider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                            >
                                <BlockRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.error',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.error',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Block
                                </Typography>
                            </Box>
                            <PrimaryDivider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '12px 16px',
                                }}
                            >
                                <FlagRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: 'text.error',
                                        mr: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: 'text.error',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Report
                                </Typography>
                            </Box>
                        </ItemContainer>
                    )}
                </Box>
            </Box>
        </OptionsDrawer>,
        parentElement as Element
    )
}

export default PostOptionsMobileView
