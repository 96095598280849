import React, { useRef, useState } from 'react'
import { Post } from '../../models/Post'
import { User } from '../../models/User'
import { Box, Typography } from '@mui/material'
import AccountAvatarSubView from '../AccountView/AccountAvatarSubView'
import AccountPopoverView from '../AccountView/AccountPopoverView'
import {
    PrimaryTypography,
    SecondaryTypography,
    StyledIconButton,
} from '../Components/StyledComponents'
import { timeDifferenceFromCurrentTime } from '../../utils/TimeDifference'
import { DataStatus } from '../../models/Dataloading'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import {
    navigateToAccount,
    navigateToEvent,
} from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useQuery } from 'react-query'
interface PostHeaderViewProps {
    post: Post
    user: User
    showMoreButton?: boolean
}

const PostHeaderView = ({
    post,
    user,
    showMoreButton = true,
}: PostHeaderViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [userState, setUserState] = useState(user)
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const { data: userImage, isLoading: isUserImageLoading, error: userImageError } = useQuery(
        userState.image,
        ()=>apiManager.grabUserPhoto(userState.image),
    )
    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 300)
    }
    return (
        <Box
            display="flex"
            alignItems="center"
            paddingBottom="4px"
            position={'relative'}
        >
            <Box>
                <AccountAvatarSubView
                    user={user}
                    userimage={userImage}
                    isUserImageLoading={isUserImageLoading}
                    handleAccountPopperClose={handleAccountPopperClose}
                    handleAccountPopperOpen={handleAccountPopperOpen}
                    following={user.following}
                    height={35}
                    width={35}
                />
            </Box>

            <Box
                sx={{
                    marginLeft: '4px',
                }}
            >
                <Box display="flex" justifyContent="center">
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.primary',
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                        onMouseOver={(e) =>
                            !state.isMobile && handleAccountPopperOpen(e)
                        }
                        onMouseLeave={(e) =>
                            !state.isMobile && handleAccountPopperClose()
                        }
                        onClick={function (event) {
                            event.stopPropagation()
                            navigateToAccount(
                                apiManager,
                                user.username,
                                user,
                                userImage,
                                null,
                                navigate,
                                state,
                                setState,
                                'attending'
                            )
                        }}
                    >
                        {user.name}
                    </Typography>
                    {post.is_host ? (
                        <SecurityRoundedIcon
                            sx={{ color: 'text.host', fontSize: 18, ml: '4px' }}
                        />
                    ) : post.attending ? (
                        <PersonRoundedIcon
                            sx={{
                                color: 'text.attending',
                                fontSize: 18,
                                ml: '4px',
                            }}
                        />
                    ) : null}
                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: '4px',
                            color: 'text.secondary',
                        }}
                    >
                        @{user.username}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: '4px',
                            color: 'text.secondary',
                        }}
                    >
                        {'\u{2022} ' +
                            timeDifferenceFromCurrentTime(post.created_at)}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>

                <Typography
                    sx={{
                        fontSize: 14,
                        cursor: 'pointer',
                        color: 'text.primary',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={function (event) {
                        event.stopPropagation()
                        navigateToEvent(
                            apiManager,
                            post.event,
                            null,
                            navigate,
                            state,
                            setState,
                            'details'
                        )
                    }}
                >
                    {post.event.title}
                </Typography>
            </Box>
            {showMoreButton && (
                <StyledIconButton
                    sx={{
                        float: 'right',
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                    }}
                >
                    <MoreHorizRoundedIcon sx={{ fontSize: 16 }} />
                </StyledIconButton>
            )}
            {!state.isMobile && (
            <AccountPopoverView
                user={userState}
                setUser={setUserState}
                openpopover={openAccountPopover}
                setOpenPopover={setOpenAccountPopover}
                anchorEl={accountAnchorEl}
                setAnchorEl={setAccountAnchorEl}
                timerRef={timerRef}
            />)}
        </Box>
    )
}

export default PostHeaderView
