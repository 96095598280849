import { Box, Button, Chip, Typography, styled } from '@mui/material'
import {
    FollowButton,
    StyledCircularProgress,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'
import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import PostSubEventMobileView from '../../PostView/PostMobileView/PostSubEventMobieView'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useApiManager } from '../../../api/ApiMangerProvider'

interface EventDetailsPostsMobileViewProps {
    event: Event
    eventImage: string | null
}

const PostBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    minWidth: 'calc(100% - 16px)',
    height: '100%',
}))
const EventDetailsPostsMobileView = ({
    event,
    eventImage,
}: EventDetailsPostsMobileViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        apiManager
            .getPosts(null, null, event.slug, 5, 0)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                    ml: '16px',
                }}
            >
                POSTS
            </Typography>
            <Box
                sx={{
                    minHeight: '100px',
                }}
            >
                {postloaded === DataStatus.loading ? (
                    <Box
                        sx={{
                            pt: '8px',
                            justifyContent: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <StyledCircularProgress />
                    </Box>
                ) : postloaded === DataStatus.failure ? (
                    <ErrorTabView />
                ) : (
                    postloaded === DataStatus.success && (
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    overflowX: 'auto',
                                    flexDirection: 'row',
                                    scrollSnapType: 'x mandatory',
                                    gap: '16px',
                                    mt: '16px',
                                    p: '0px 16px',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                }}
                            >
                                {postState.length > 0 ? (
                                    <>
                                        {postState.map((post) => (
                                            <PostBox
                                                sx={{
                                                    scrollSnapAlign: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        mb: 'auto',
                                                    }}
                                                >
                                                    <PostSubEventMobileView
                                                        post={post}
                                                    />
                                                </Box>
                                            </PostBox>
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    mt: '16px',
                                    p: '0px 16px',
                                }}
                            >
                                <Button
                                    fullWidth
                                    onClick={() =>
                                        navigateToEvent(
                                            apiManager,
                                            event,
                                            eventImage,
                                            navigate,
                                            state,
                                            setState,
                                            'posts'
                                        )
                                    }
                                    sx={{
                                        backgroundColor: 'background.secondary',
                                        border: (theme) =>
                                            '1px solid ' +
                                            theme.palette.border.secondary,
                                        borderRadius: '50px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor:
                                                'background.secondary',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                        }}
                                    >
                                        Show all {postState.length} posts
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    )
                )}
            </Box>
        </Box>
    )
}

export default EventDetailsPostsMobileView
