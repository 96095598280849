import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { getPosts } from '../../../api/RestPost'
import { Box, Grid } from '@mui/material'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { error } from 'console'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import EventPostsTopBarMobileView from './EventPostsTopBarMobileView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import EmptyMediaPostsView from '../../Components/StaticPages/EmptyView/EmptyMediaPostsView'
import { EmptyPostsViewType } from '../../Components/StaticPages/EmptyView/EmptyPostsView'
import PostImageView from '../../PostView/PostImageView'
import { useLocation } from 'react-router-dom'
import { NavigateEventState } from '../../../models/NavigateState'
import { Event } from '../../../models/Event'

interface EventPostsMobileViewProps {
    event_slug: string
}

const EventPostsMobileView = ({ event_slug }: EventPostsMobileViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [value, setValue] = useState('all')
    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    return (
        <Box
            sx={{
                mt: '102px',
                position: 'relative',
                mb: `${navMobileBarHeight}px`,
            }}
        >
            <TabBox
                sx={{
                    position: 'fixed',
                    top: '56px',
                    width: '100%',
                    zIndex: 2,
                    backgroundColor: 'background.primary',
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                >
                    <StyledTab label="All" value="all" />
                    <StyledTab label="Media" value="media" />
                    <StyledTab label="Attendees" value="attendees" />
                    <StyledTab label="Hosts" value="hosts" />
                </StyledTabs>
            </TabBox>
            <EventPostsTopBarMobileView />
            {value === 'all' ? (
                <EventPostsAllMobileView event_slug={event_slug} />
            ) : value === 'media' ? (
                <EventPostsMediaMobileView event_slug={event_slug} />
            ) : value === 'hosts' ? (
                <EventPostsHostMobileView event_slug={event_slug} />
            ) : (
                <Box></Box>
            )}
        </Box>
    )
}

const EventPostsHostMobileView = ({
    event_slug,
}: EventPostsMobileViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState
    const [eventState, setEventState] = useState<Event | null>(
        locationEvent ? locationEvent.event : null
    )

    useEffect(() => {
        console.log(eventState, 'hist')
        if (eventState) {
            getPosts(
                eventState.primary_host.username,
                null,
                event_slug,
                20,
                0,
                state.usertoken
            )
                .then((response) => {
                    setPostState(response.posts)
                    setPostLoaded(DataStatus.success)
                })
                .catch((error) => {
                    setPostLoaded(DataStatus.failure)
                    console.log(error)
                })
        }
    }, [])
    return (
        <Box>
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '16px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postLoaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box>
                                        <PostMobileView post={post} />
                                        <PrimaryDivider />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}

const EventPostsAllMobileView = ({ event_slug }: EventPostsMobileViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        getPosts(null, null, event_slug, 20, 0, state.usertoken)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box>
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '8px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postLoaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box>
                                        <PostMobileView post={post} />
                                        <PrimaryDivider />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}
const EventPostsMediaMobileView = ({
    event_slug,
}: EventPostsMobileViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        getPosts(null, null, event_slug, 20, 0, state.usertoken)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : postLoaded === DataStatus.success && postState.length === 0 ? (
                <EmptyMediaPostsView
                    name={event_slug}
                    type={EmptyPostsViewType.event}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        padding: '8px 16x',
                    }}
                >
                    <Grid container spacing={0}>
                        {postState.map((post) => (
                            <Grid item xs={4} md={4} lg={4}>
                                <PostImageView post={post} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default EventPostsMobileView
