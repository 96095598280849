import { Box, IconButton, styled } from '@mui/material'
import FeedTopBarMobileView from './FeedTopBarMobileView'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavigateFeedState } from '../../../models/NavigateState'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import PostView from '../../PostView/PostView'
import EmptyPostsView from '../../Components/StaticPages/EmptyView/EmptyPostsView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { use } from 'marked'
import FeedSearchMobileView from './FeedSearchMobileView'
import { useApiManager } from '../../../api/ApiMangerProvider'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'

const PostIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    padding: '16px',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

const FeedMobileView = () => {
    const apiManger = useApiManager()
    const [offset, setOffset] = useState(0)
    const location = useLocation()
    const initialpoststateData = location.state as NavigateFeedState | null
    const { state, setState } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [postLoadMore, setPostLoadMore] = useState<DataStatus>(DataStatus.none)
    const [showSearch, setShowSearch] = useState(false)
    // Fetches posts from the server with every offset change
    // useEffect(() => {
    //     document.body.style.overflow = 'auto';
    // },[])
    useEffect(() => {
        if (postLoadMore === DataStatus.loading) {
            apiManger
            .getPosts(null, null, null, 10, offset)
            .then((response) => {
                console.log(offset)
                setPostState((prevState) => [
                    ...prevState,
                    ...response.posts,
                ])
                setOffset(offset + response.posts.length)
                setPostLoadMore(DataStatus.success)
            })
            .catch((error) => {
                setPostLoadMore(DataStatus.failure)
            })
        }
    },[postLoadMore])
    const reloadRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        console.log(reloadRef)
        if (!reloadRef.current) return;
        
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting && postState.length > 0) {
              setPostLoadMore(DataStatus.loading)
            }
          },
          { root: null, threshold: 1.0 }
        );
    
        observer.observe(reloadRef.current);
    
        return () => {
          if (reloadRef.current) {
            observer.unobserve(reloadRef.current);
          }
        };
      }, [reloadRef.current]);
    const observeEndOfList = (node: HTMLDivElement | null) => {
        if (!node) return; // Skip if the node is null
    
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setPostLoadMore(DataStatus.loading)
                console.log('intersecting')
            }
          },
          { root: null, threshold: 1.0 }
        );
    
        observer.observe(node);
    
        return () => {
          observer.disconnect();
        };
      };
      
    useEffect(() => {
        console.log('offset', offset)
        if (offset === 0 && initialpoststateData !== null) {
            setPostLoaded(DataStatus.success)
            setPostState(initialpoststateData.poststate)
            setOffset(postState.length)
        } else {
            try {
                apiManger
                    .getPosts(null, null, null, 10, offset)
                    .then((response) => {
                        setPostState((prevState) => [
                            ...prevState,
                            ...response.posts,
                        ])
                        setOffset(offset + response.posts.length)
                        setPostLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setPostLoaded(DataStatus.failure)
                        console.log(error)
                    })
            } catch (error) {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            }
        }
    }, [])
    return (
        <Box>
            {showSearch ? (
                <FeedSearchMobileView
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                />
            ) : (
                <>
                    <FeedTopBarMobileView setShowSearch={setShowSearch} />
                    <Box
                        sx={{
                            mt: '60px',
                            mb: `${navMobileBarHeight}px`,
                            position: 'relative',
                        }}
                    >
                        {/* <Box
                    sx={{
                        position: 'fixed',
                        top: '0',
                        right: '0',
                        padding: '16px',
                        height: 'calc(100vh -  100px)',
                        zIndex: '100',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        display: 'flex',
                        pointerEvents: 'none',
                    }}
                >
                    <PostIconButton>
                        <AddRoundedIcon />
                    </PostIconButton>
                </Box> */}
                        {postloaded === DataStatus.loading ? (
                            <Box
                                sx={{
                                    pt: '8px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <StyledCircularProgress />
                            </Box>
                        ) : postloaded === DataStatus.failure ? (
                            <ErrorTabView />
                        ) : (
                            postloaded === DataStatus.success && (
                                <>
                                    {postState.length > 0 ? (
                                        <Box>
                                            {postState.map((post) => (
                                                <Box>
                                                    <PostMobileView
                                                        post={post}
                                                    />
                                                    <PrimaryDivider />
                                                </Box>
                                            ))}
                                                 
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )
                        )}
                                                                   <Box
                                                        ref={reloadRef}
                                                    sx={{
                                                        height: '80px',
                                                    }}
                                                >
                                                    {postLoadMore ===
                                                        DataStatus.loading && (
                                                        <Box
                                                            sx={{
                                                                height: '100%',
                                                                alignItems: 'center',
                                                                justifyContent:
                                                                    'center',
                                                                display: 'flex',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <StyledCircularProgress />
                                                        </Box>
                                                    )}
                                                </Box>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default FeedMobileView
