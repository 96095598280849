import {
    Avatar,
    Box,
    BoxProps,
    Button,
    IconButton,
    InputBase,
    TextField,
    Typography,
    styled,
} from '@mui/material'
import { register, RegisterRequestData } from '../../../api/Auth'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { CreateAccountFormValues } from '../CreateAccountItems/CreateAccountDetailsView'
import {
    OutlineContainer,
    OutlineInputBase,
    SelectButton,
    StyledButtonCircularProgress,
} from '../../Components/StyledComponents'
import { useCallback, useState } from 'react'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { useDropzone } from 'react-dropzone'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { uploadPhoto } from '../../../api/RestPhoto'
import { useAppState } from '../../../AppStateProvider'
import { useNavigate } from 'react-router-dom'
import { navigateToFeed } from '../../../utils/NavigationFunctions'
import { useApiManager } from '../../../api/ApiMangerProvider'

interface CreateAccountPhotoMobileViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
    registerstate: CreateAccountFormValues
    setRegisterState: React.Dispatch<
        React.SetStateAction<CreateAccountFormValues>
    >
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
}

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.quaternary,
    '&:hover': {
        backgroundColor: theme.palette.background.quaternary,
    },
}))

const ImageBox = styled(Box)(({ theme }) => ({
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.tertiary,
    cursor: 'pointer',
}))
const CreateAccountPhotoMobileView = ({
    setShowLogin,
    setShowRegister,
    registerstate,
    setRegisterState,
    setRegisterPage,
}: CreateAccountPhotoMobileViewProps) => {
    const apiManager = useApiManager()
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [image, setImage] = useState<string | null>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setImage(reader.result as string)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })
    const removeImage = () => {
        setImage(null)
    }

    const handleSubmit = () => {
        if (!isSubmitting && image) {
            setIsSubmitting(true)
            register({
                user: {
                    email: registerstate.email,
                    password: registerstate.password,
                    username: registerstate.username,
                    name: registerstate.name,
                    birth_date: `${registerstate.year}-${registerstate.month.toString().padStart(2, '0')}-${registerstate.day}`,
                },
            })
                .then((response) => {
                    setTimeout(() => {
                        console.log(response, 'hey')
                        if (image) {
                            uploadPhoto(image, response.user.token)
                                .then((photoresponse) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        name: response.user.name,
                                        username: response.user.username,
                                        useremail: response.user.email,
                                        usertoken: response.user.token,
                                        userimagelink: response.user.image,
                                        userimage: photoresponse,
                                        userpassword: registerstate.password,
                                    }))
                                    navigateToFeed(
                                        apiManager,
                                        navigate,
                                        state,
                                        setState,
                                        '/app/feed/fyp'
                                    )
                                    setIsSubmitting(false)
                                })
                                .catch((error) => {
                                    console.log('hey')
                                    console.log(error)
                                })
                        }
                    }, 1000)
                })
                .catch((error) => {
                    console.log(error)
                    setIsSubmitting(false)
                })
        }
    }
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: 'text.primary',
                }}
            >
                Please enter your email address
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                }}
            >
                Dont lose access to your account by verifying your email
            </Typography>
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    mt: '24px',
                }}
            >
                <Box
                    display="flex"
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <ImageBox
                        sx={{
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            display: 'flex',
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {image ? (
                            <Avatar
                                src={image}
                                alt="Circular Image"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    display: 'flex',
                                }}
                            />
                        ) : (
                            <PersonRoundedIcon
                                sx={{
                                    fontSize: 30,
                                    width: '75%',
                                    height: '75%',
                                    color: 'text.disabled',
                                }}
                            />
                        )}
                        <ClearIconButton
                            onClick={removeImage}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}
                        >
                            <ClearRoundedIcon />
                        </ClearIconButton>
                    </ImageBox>
                </Box>
                <Typography
                    sx={{
                        fontSize: 14,
                        textAlign: 'center',
                        mt: '16px',
                        color: 'text.secondary',
                    }}
                >
                    Drag or click to upload a picture
                </Typography>
                <SelectButton
                    fullWidth
                    type="submit"
                    sx={{
                        mt: '32px',
                        height: '40px',
                        fontSize: 16,
                    }}
                    disabled={!image}
                    onClick={handleSubmit}
                >
                    {isSubmitting ? (
                        <StyledButtonCircularProgress size={'26px'} />
                    ) : (
                        'Create Account'
                    )}
                </SelectButton>
            </Box>
        </Box>
    )
}

export default CreateAccountPhotoMobileView
