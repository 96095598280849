import { AppBar, Box, Button, styled } from '@mui/material'
import { navMobileBarHeight } from '../../layouts/DefaultLayout/NavMobileBar'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    navigateToCreateEvent,
    navigateToCreatePost,
} from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'

const BottomBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: 'transparent',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 5,
    bottom: 0,
    top: 'auto',
    alignContent: 'top',
    boxShadow: 'none',
}))

const ScrollContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflowX: 'auto',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    gap: '8px',
    position: 'relative',

    minHeight: navMobileBarHeight,
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    alignItems: 'center',
}))

const SnapContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '16px',
    position: 'relative',
    padding: '8px 8px',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.9)',
    // border: '1px solid ' + theme.palette.border.secondary,
}))
const SnapItem = styled(Button)(({ theme }) => ({
    scrollSnapAlign: 'center',
    flex: '0 0 auto',
    padding: '8px 16px',
    borderRadius: 50,
    textTransform: 'none',
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '14px',
    height: 30,
    zIndex: 1,
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
}))

const Indicator = styled(Box)(({ theme }) => ({
    position: 'absolute',
    height: navMobileBarHeight - 16,
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '10px',
    bottom: 8,
    transition: 'left 0.3s, width 0.3s',
    zIndex: 0,
}))

const CreateBottomBarMobileView = React.memo(({}) => {
    const [activeTab, setActiveTab] = useState('post')
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 })
    const containerRef = useRef<HTMLElement>(null)
    const itemsRef = useRef<{ [key: string]: HTMLElement | null }>({})
    const [scrollPosition, setScrollPosition] = useState(0)
    const windowWidth = window.innerWidth
    const handleClick = (newValue: string) => {
        if (itemsRef.current[newValue]) {
            const targetDiv = itemsRef.current[newValue] as HTMLElement
            const xPosition = targetDiv.getBoundingClientRect().left
            console.log(xPosition, windowWidth / 2)
            console.log(
                containerRef.current?.getBoundingClientRect(),
                xPosition
            )
            containerRef.current?.scrollTo({
                left: xPosition,
                behavior: 'smooth',
            })
        }
        setActiveTab(newValue)
    }
    const closestItem = () => {
        var closestId: string | null = null
        let minDistance = Infinity
        Object.entries(itemsRef.current).forEach(([key, value]) => {
            if (value) {
                const xPosition =
                    value.getBoundingClientRect().left +
                    value.getBoundingClientRect().width / 2
                const distance = xPosition - windowWidth / 2
                if (Math.abs(distance) < minDistance) {
                    minDistance = Math.abs(distance)
                    closestId = key
                    setActiveTab(closestId)
                }
            }
        })
    }
    useEffect(() => {
        if (
            activeTab === 'post' &&
            !window.location.pathname.includes('createpost')
        ) {
            navigateToCreatePost(null, null, null, navigate, state, setState)
        } else if (
            activeTab === 'event' &&
            !window.location.pathname.includes('createevent')
        ) {
            navigateToCreateEvent(null, navigate, state, setState)
        }
    }, [activeTab])
    return (
        <BottomBar>
            <ScrollContainer ref={containerRef} onScroll={() => closestItem()}>
                <Box flex={'0 0 50%'} />
                <SnapContainer>
                    <SnapItem
                        key={'post'}
                        ref={(el: HTMLElement | null) =>
                            (itemsRef.current['post'] = el)
                        }
                        className="snap-item"
                        sx={{
                            color:
                                activeTab === 'post'
                                    ? 'background.primary'
                                    : 'text.primary',
                            backgroundColor:
                                activeTab === 'post'
                                    ? 'text.primary'
                                    : 'transparent',
                            '&:hover': {
                                color:
                                    activeTab === 'post'
                                        ? 'background.primary'
                                        : 'text.primary',
                                backgroundColor:
                                    activeTab === 'post'
                                        ? 'text.primary'
                                        : 'transparent',
                            },
                        }}
                        onClick={() => handleClick('post')}
                    >
                        Post
                    </SnapItem>
                    <SnapItem
                        key={'event'}
                        ref={(el: HTMLElement | null) =>
                            (itemsRef.current['event'] = el)
                        }
                        className="snap-item"
                        sx={{
                            color:
                                activeTab === 'event'
                                    ? 'background.primary'
                                    : 'text.primary',
                            backgroundColor:
                                activeTab === 'event'
                                    ? 'text.primary'
                                    : 'transparent',
                            '&:hover': {
                                color:
                                    activeTab === 'event'
                                        ? 'background.primary'
                                        : 'text.primary',
                                backgroundColor:
                                    activeTab === 'event'
                                        ? 'text.primary'
                                        : 'transparent',
                            },
                        }}
                        onClick={() => handleClick('event')}
                    >
                        Event
                    </SnapItem>
                </SnapContainer>
                <Box flex={'0 0 50%'} />
            </ScrollContainer>
        </BottomBar>
    )
})

export default CreateBottomBarMobileView
