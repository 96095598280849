import {
    AppBar,
    Box,
    ButtonBase,
    Chip,
    IconButton,
    InputBase,
    Toolbar,
    Typography,
    styled,
} from '@mui/material'
import { CreateEvent } from '../../../../models/CreateEvent'
import {
    DefaultButton,
    OutlineContainer,
    OutlineInputBase,
    StyledChip,
    StyledLinearProgress,
} from '../../../Components/StyledComponents'
import { useAppState } from '../../../../AppStateProvider'
import { useState } from 'react'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { FilterCategory } from '../../../../models/Filter'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
}))

interface StyledIconButtonProps extends React.ComponentProps<typeof Chip> {
    item: FilterCategory
    selecteditems: string[]
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    width: '100%',
}))

const CategroyChip = styled((props: StyledIconButtonProps) => (
    <Chip {...props} />
))(({ theme, item, selecteditems }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
    ...(selecteditems.includes(item) && {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.primary,
    }),
}))

const TagsContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

interface CreateEventEditTagsMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    setView: React.Dispatch<React.SetStateAction<string>>
}

interface CreateEventEditTagsTopBarMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    tags: string[] | null
    setView: React.Dispatch<React.SetStateAction<string>>
}

const CreateEventEditTagsTopBarMobileView = ({
    tempEventData,
    setTempEventData,
    tags,
    setView,
}: CreateEventEditTagsTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const handleConfirm = () => {
        if (tags && tempEventData.tag_list !== tags) {
            setTempEventData({ ...tempEventData, tag_list: tags })
            setView('edit')
        }
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'relative',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => setView('edit')}
                >
                    Back
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            tempEventData.tag_list !== tags
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleConfirm}
                >
                    Confirm
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        height: 55,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Tags
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Toolbar>
        </TopBar>
    )
}

const CreateEventEditTagsMobileView = ({
    tempEventData,
    setTempEventData,
    setView,
}: CreateEventEditTagsMobileViewProps) => {
    const [tagsState, setTagsState] = useState<string[] | null>(
        tempEventData.tag_list
    )

    const [tagInput, setTagInput] = useState<string>('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagInput(event.target.value)
    }
    const handleAddTag = () => {
        if (tagsState) {
            setTagsState([...tagsState, tagInput])
            setTagInput('')
        } else {
            setTagsState([tagInput])
            setTagInput('')
        }
    }

    const handleRemoveTag = (tag: string) => {
        if (tagsState) {
            setTagsState(tagsState.filter((item) => item !== tag))
        }
    }
    return (
        <Box
            sx={{
                height: '100%',
                p: '8px 16px',
            }}
        >
            <CreateEventEditTagsTopBarMobileView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
                tags={tagsState}
                setView={setView}
            />

            <Box>
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: 'text.tertiary',
                    }}
                >
                    Add or remove tags
                </Typography>
                <TagsContainer
                    sx={{
                        minHeight: '200px',
                        width: '100%',
                        mt: '8px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                            padding: '8px 8px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {tagsState?.map((tag, index) => (
                            <StyledChip key={index}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: 'text.secondary',
                                    }}
                                >
                                    #{tag}
                                </Typography>
                                <IconButton
                                    sx={{
                                        backgroundColor:
                                            'background.quaternary',
                                        padding: '4px',
                                        mr: '-4px',
                                        ml: '4px',
                                    }}
                                    onClick={() => handleRemoveTag(tag)}
                                >
                                    <ClearRoundedIcon
                                        sx={{
                                            fontSize: 16,
                                            color: 'text.primary',
                                        }}
                                    />
                                </IconButton>
                            </StyledChip>
                        ))}
                    </Box>
                </TagsContainer>
                <StyledChip
                    sx={{
                        mt: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <StyledInputBase
                            placeholder="Add a tag"
                            value={tagInput}
                            onChange={handleChange}
                            sx={{
                                ml: '8px',
                                fontSize: 14,
                            }}
                        />

                        <DefaultButton
                            disabled={
                                tagInput === '' || tagsState?.includes(tagInput)
                            }
                            sx={{
                                ml: 'auto',
                                whiteSpace: 'nowrap',
                            }}
                            onClick={handleAddTag}
                        >
                            Add Tag
                        </DefaultButton>
                    </Box>
                </StyledChip>
            </Box>
        </Box>
    )
}

export default CreateEventEditTagsMobileView
