import {
    AppBar,
    Box,
    ButtonBase,
    Chip,
    IconButton,
    Toolbar,
    Typography,
    styled,
} from '@mui/material'
import { CreateEvent } from '../../../../models/CreateEvent'
import {
    OutlineContainer,
    OutlineInputBase,
    StyledLinearProgress,
} from '../../../Components/StyledComponents'
import { useAppState } from '../../../../AppStateProvider'
import { useState } from 'react'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { FilterCategory } from '../../../../models/Filter'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
}))

interface StyledIconButtonProps extends React.ComponentProps<typeof Chip> {
    item: FilterCategory
    selecteditems: string[]
}

const CategroyChip = styled((props: StyledIconButtonProps) => (
    <Chip {...props} />
))(({ theme, item, selecteditems }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
    ...(selecteditems.includes(item) && {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.primary,
    }),
}))

const TagsContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

interface CreateEventEditCategoriesMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    setView: React.Dispatch<React.SetStateAction<string>>
}

interface CreateEventEditCategoriesTopBarMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    categories: FilterCategory[]
    setView: React.Dispatch<React.SetStateAction<string>>
}

const CreateEventEditCategoriesTopBarMobileView = ({
    tempEventData,
    setTempEventData,
    categories,
    setView,
}: CreateEventEditCategoriesTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const handleConfirm = () => {
        if (
            categories.length > 0 &&
            categories.length <= 40 &&
            tempEventData.category_list !== categories
        ) {
            setTempEventData({ ...tempEventData, category_list: categories })
            setView('edit')
        }
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'relative',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => setView('edit')}
                >
                    Back
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            tempEventData.category_list !== categories
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleConfirm}
                >
                    Confirm
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        height: 55,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Categories
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Toolbar>
        </TopBar>
    )
}

const CreateEventEditCategoriesMobileView = ({
    tempEventData,
    setTempEventData,
    setView,
}: CreateEventEditCategoriesMobileViewProps) => {
    const [categories, setCategories] = useState(tempEventData.category_list)
    const [focusState, setFocusState] = useState(false)
    const handleChange = (newValue: FilterCategory) => {
        console.log('categories:', categories)
        setCategories((prevItems) => {
            if (prevItems.includes(newValue)) {
                return prevItems.filter((item) => item !== newValue)
            } else {
                return [...prevItems, newValue]
            }
        })
    }

    return (
        <Box
            sx={{
                height: '100%',
                p: '8px 16px',
            }}
        >
            <CreateEventEditCategoriesTopBarMobileView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
                categories={categories}
                setView={setView}
            />

            <TagsContainer
                sx={{
                    mt: '8px',
                    p: '8px',
                }}
            >
                <Box
                    sx={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '8px',
                    }}
                >
                    {Object.values(FilterCategory).map((item, index) => (
                        <ButtonBase
                            sx={{ borderRadius: 50 }}
                            onClick={() => handleChange(item)}
                        >
                            <CategroyChip
                                key={index}
                                label={item.toString()}
                                item={item}
                                selecteditems={categories}
                            />
                        </ButtonBase>
                    ))}
                </Box>
            </TagsContainer>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                    mt: '8px',
                }}
            >
                Add categories to help people discover your event. Use
                categories that are relevant to your event.
            </Typography>
        </Box>
    )
}

export default CreateEventEditCategoriesMobileView
