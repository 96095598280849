import axios from 'axios'
import { mainUrl } from '../constants'
import { base64ToBlob, convertBlobToBase64 } from '../utils/Base64ImageEncoding'

export const uploadPhoto = async (
    photoData: string,
    usertoken: string
): Promise<string> => {
    console.log(usertoken, 'token')
    const url = `${mainUrl}user/image`
    const base64Data = photoData.split(',')[1]
    const mimeType =
        photoData.split(',')[0].match(/:(.*?);/)?.[1] || 'image/jpeg' // Extract MIME type
    const binary = atob(base64Data) // Decode base64
    const array = []

    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }

    const blob = new Blob([new Uint8Array(array)], { type: mimeType }) // Convert to Blob

    const formData = new FormData()
    formData.append('image', blob, 'image.jpeg')
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${usertoken}`,
                ContentType: 'multipart/form-data',
            },
            body: formData,
        })
        const blob = await response.blob()
        const image = await convertBlobToBase64(blob)
        return image
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
