import React, { useEffect, useState, useRef } from 'react'
import { Event } from '../../models/Event'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import {
    Box,
    ButtonBase,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material'
import { grabEventPhoto } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import { Link, useNavigate } from 'react-router-dom'
import { iso2eventtime } from '../../utils/DateStringConverter'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { grabUserPhoto } from '../../api/RestUser'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'

import {
    AttendingBox,
    HostBox,
    PrimaryDivider,
    PrimaryTypography,
    SecondaryTypography,
    SelectTypography,
    StyledChip,
    TertiaryTypography,
} from '../Components/StyledComponents'
import AccountAvatarSubView from '../AccountView/AccountAvatarSubView'
import AccountPopoverView from '../AccountView/AccountPopoverView'
import { navigateToEvent } from '../../utils/NavigationFunctions'
import AccountDetailsSubView from '../AccountView/AccountSubViews/AccountDetailsSubView'
import Markdown from 'markdown-to-jsx'
import { useApiManager } from '../../api/ApiMangerProvider'

interface EventSubViewProps {
    event: Event
}

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const EventTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: 16,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: '8px 10px',
    '&:last-child': {
        paddingBottom: 8,
    },
}))

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const EventCard = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.secondary,
    padding: 0,
    borderRadius: 10,
    width: '100%',
    cursor: 'pointer',
}))

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const StyledCalendarMonthRoundedIcon = styled(CalendarMonthRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const StyledShoppingCartRoundedIcon = styled(ShoppingCartRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const StyledLocationOnRoundedIcon = styled(LocationOnRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const DetailsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 50,
}))

const PostEventSubView = ({ event }: EventSubViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimage, setEventImage] = React.useState<string | null>(null)
    const [userimage, setUserImage] = React.useState<string | null>(null)
    const [userState, setUserState] = useState(event.primary_host)
    const [following, setFollowing] = useState<boolean>(
        event.primary_host.following
    )
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const handleAccountPoperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPoperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }

    // const navigateToEvent = () => {
    //     navigate(`/app/event/${event.slug}`, {
    //         state: { event: event, eventImage: eventimage },
    //     })
    // }

    useEffect(() => {
        try {
            apiManager
                .grabEventPhoto(event.image)
                .then((response) => {
                    setEventImageStatus(DataStatus.failure)
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    useEffect(() => {
        try {
            apiManager
                .grabUserPhoto(event.primary_host.image)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [event])
    return (
        <EventCard>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px 10px 0px 0px',
                    aspectRatio: '20/9',
                }}
            >
                {eventimagestatus === DataStatus.success && eventimage ? (
                    <img
                        src={eventimage}
                        alt="Event"
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px 10px 0px 0px',
                        }}
                    />
                ) : eventimagestatus === DataStatus.loading ? (
                    <LoadingBox />
                ) : (
                    <LoadingBox />
                )}
            </div>
            <StyledCardContent>
                <Box>
                    <Box display="flex" alignItems="center">
                        {event.is_host ? (
                            <HostBox
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: '4px',
                                    mr: '8px',
                                }}
                            >
                                <SecurityRoundedIcon
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 12,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                        ml: '4px',
                                    }}
                                >
                                    Host
                                </Typography>
                            </HostBox>
                        ) : (
                            event.attending && (
                                <AttendingBox
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: '4px',
                                    }}
                                >
                                    <PersonRoundedIcon
                                        sx={{
                                            color: 'text.primary',
                                            fontSize: 14,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                            ml: '4px',
                                        }}
                                    >
                                        Attending
                                    </Typography>
                                </AttendingBox>
                            )
                        )}
                    </Box>
                    <PrimaryTypography fontSize={16} fontWeight={'bold'}>
                        {event.title}
                    </PrimaryTypography>
                    <SelectTypography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                    >
                        {iso2eventtime(event.event_time)}
                    </SelectTypography>

                    <Typography
                        sx={{
                            fontSize: 14,
                            margin: '8px 0px',
                            color: 'text.primary',
                        }}
                    >
                        <Markdown
                            fontSize={14}
                            options={{
                                overrides: {
                                    // Override headings to render as body text
                                    h1: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    h2: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    h3: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    h4: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    h5: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    h6: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    // Render paragraphs as normal body text
                                    p: {
                                        component: Typography,
                                        props: {
                                            style: { fontSize: 14 },
                                            gutterBottom: true,
                                        },
                                    },
                                    // Handle lists normally
                                    ul: {
                                        component: Typography,
                                        props: {
                                            component: 'ul',
                                            style: { fontSize: 14 },
                                        },
                                    },
                                    ol: {
                                        component: Typography,
                                        props: {
                                            component: 'ol',
                                            style: { fontSize: 14 },
                                        },
                                    },
                                },
                            }}
                        >
                            {event.description}
                        </Markdown>
                    </Typography>
                    <Box
                        sx={{
                            marginTop: '4px',
                            alighItems: 'left',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <StyledChip component={ButtonBase}>
                            <StyledFavoriteBorderRoundedIcon
                                style={{
                                    marginRight: 4,
                                    fontSize: 16,
                                }}
                            />
                            <PrimaryTypography fontSize={12}>
                                {event.favorites_count} likes
                            </PrimaryTypography>
                        </StyledChip>
                        <StyledChip
                            ml={'4px'}
                            component={ButtonBase}
                            onClick={() =>
                                navigateToEvent(
                                    apiManager,
                                    event,
                                    eventimage,
                                    navigate,
                                    state,
                                    setState,
                                    'posts'
                                )
                            }
                        >
                            <StyledRepeatRoundedIcon
                                style={{
                                    marginRight: 4,
                                    fontSize: 16,
                                }}
                            />
                            <PrimaryTypography
                                variant="body1"
                                color="textPrimary"
                                fontSize={12}
                            >
                                {event.post_count} posts
                            </PrimaryTypography>
                        </StyledChip>
                        <StyledChip
                            ml={'4px'}
                            component={ButtonBase}
                            onClick={() =>
                                navigateToEvent(
                                    apiManager,
                                    event,
                                    eventimage,
                                    navigate,
                                    state,
                                    setState,
                                    'attendees'
                                )
                            }
                        >
                            <StyledPersonOutlineRoundedIcon
                                style={{
                                    marginRight: 4,
                                    fontSize: 16,
                                }}
                            />

                            <PrimaryTypography fontSize={12}>
                                {event.attendee_count} attendees
                            </PrimaryTypography>
                        </StyledChip>
                    </Box>
                    <PrimaryDivider style={{ margin: '12px 0px' }} />
                    <TertiaryTypography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            paddingBottom: '5px',
                        }}
                    >
                        PRIMARY HOST
                    </TertiaryTypography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ paddingBottom: '5px' }}
                    >
                        <AccountDetailsSubView
                            user={event.primary_host}
                            followButton={true}
                            bio={false}
                            size={40}
                        />
                    </Box>
                    {/* <PrimaryDivider style={{ margin: '12px 0px' }} /> */}
                </Box>
            </StyledCardContent>
        </EventCard>
    )
}

export default PostEventSubView
