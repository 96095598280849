import { Box, Collapse, IconButton, styled, Typography } from '@mui/material'
import { CreateEvent } from '../../../../models/CreateEvent'
import CreateEventPhotoView from '../../../CreateEventView/CreateEventEditView/CreateEventPhoto'
import {
    PrimaryDivider,
    StyledChip,
} from '../../../Components/StyledComponents'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { useState } from 'react'
import { iso2dayRange, iso2time } from '../../../../utils/DateStringConverter'

interface ArrowIconButtonProps extends React.ComponentProps<typeof IconButton> {
    open: boolean
}

const ArrowIconButton = styled((props: ArrowIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, open }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,

    '&:hover': {
        backgroundColor: 'transparent',
    },
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

interface CreateEventEditMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    setView: React.Dispatch<React.SetStateAction<string>>
}

const CreateEventEditMobileView = ({
    tempEventData,
    setTempEventData,
    setView,
}: CreateEventEditMobileViewProps) => {
    const [activeStep, setActiveStep] = useState([0, 1])
    const handleArrowClick = (step: number) => {
        if (activeStep.includes(step)) {
            setActiveStep((prev) => prev.filter((s) => s !== step))
        } else {
            setActiveStep((prev) => [...prev, step])
        }
    }
    return (
        <Box>
            <Box
                sx={{
                    p: '8px 16px',
                }}
            >
                <CreateEventPhotoView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                />
            </Box>
            <PrimaryDivider
                sx={{
                    m: '8px 0px',
                    mt: '16px',
                }}
            />
            <Box
                sx={{
                    p: '0px 16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Event Details
                    </Typography>
                    <Box flex={1} />
                    <ArrowIconButton
                        sx={{
                            float: 'right',
                            width: 30,
                            height: 30,
                        }}
                        open={activeStep.includes(0)}
                        onClick={() => handleArrowClick(0)}
                    >
                        <ChevronRightRoundedIcon
                            sx={{
                                color: 'text.tertiary',
                                fontSize: 24,
                            }}
                        />
                    </ArrowIconButton>
                </Box>
                <Collapse in={activeStep.includes(0)}>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Title
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                            onClick={() => setView('title')}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color:
                                        tempEventData.title === ''
                                            ? 'text.disabled'
                                            : 'text.primary',
                                }}
                            >
                                {tempEventData.title !== ''
                                    ? tempEventData.title
                                    : 'Title'}
                            </Typography>
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Description
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                            onClick={() => setView('description')}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color:
                                        tempEventData.description === ''
                                            ? 'text.disabled'
                                            : 'text.primary',
                                }}
                            >
                                {tempEventData.description !== ''
                                    ? tempEventData.description
                                    : 'Description'}
                            </Typography>
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                        onClick={() => setView('date')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Date
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                        >
                            {tempEventData.event_time !== '' &&
                            tempEventData.end_time ? (
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.primary',
                                        }}
                                    >
                                        {iso2dayRange(
                                            tempEventData.event_time,
                                            tempEventData.end_time
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: 'text.primary',
                                        }}
                                    >
                                        {`${iso2time(tempEventData.event_time)} - ${iso2time(tempEventData.end_time)}`}
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Date
                                </Typography>
                            )}
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                        onClick={() => setView('location')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Location
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                        >
                            {tempEventData.location ? (
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'text.primary',
                                    }}
                                >
                                    {tempEventData.address?.street},{' '}
                                    {tempEventData.address?.city},{' '}
                                    {tempEventData.address?.state},{' '}
                                    {tempEventData.address?.zipcode}
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Location
                                </Typography>
                            )}
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                        onClick={() => setView('categories')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Categories
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                        >
                            {tempEventData.category_list.length !== 0 ? (
                                <Box
                                    sx={{
                                        flexWrap: 'wrap',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '8px',
                                        pb: '8px',
                                    }}
                                >
                                    {tempEventData.category_list.map(
                                        (category, index) => (
                                            <StyledChip key={index}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    {category}
                                                </Typography>
                                            </StyledChip>
                                        )
                                    )}
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Categories
                                </Typography>
                            )}
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                            mb: '16px',
                        }}
                        onClick={() => setView('tags')}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Tags
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                        >
                            {tempEventData.tag_list.length !== 0 ? (
                                <Box
                                    sx={{
                                        flexWrap: 'wrap',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '8px',
                                        pb: '8px',
                                    }}
                                >
                                    {tempEventData.tag_list.map(
                                        (tag, index) => (
                                            <StyledChip key={index}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    #{tag}
                                                </Typography>
                                            </StyledChip>
                                        )
                                    )}
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Tags
                                </Typography>
                            )}
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
            <PrimaryDivider
                sx={{
                    m: '8px 0px',
                    mt: '8px',
                }}
            />
            <Box
                sx={{
                    p: '0px 16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Event Settings
                    </Typography>
                    <Box flex={1} />
                    <ArrowIconButton
                        sx={{
                            float: 'right',
                            width: 30,
                            height: 30,
                        }}
                        open={activeStep.includes(1)}
                        onClick={() => handleArrowClick(1)}
                    >
                        <ChevronRightRoundedIcon
                            sx={{
                                color: 'text.tertiary',
                                fontSize: 24,
                            }}
                        />
                    </ArrowIconButton>
                </Box>
                <Collapse in={activeStep.includes(1)}>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Max Attendees
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                            onClick={() => setView('maxattendees')}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'text.primary',
                                }}
                            >
                                {tempEventData.attendee_limit !== 0
                                    ? tempEventData.attendee_limit
                                    : 'No Limit'}
                            </Typography>
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                                mb: 'auto',
                            }}
                        >
                            Privacy
                        </Typography>
                        <Box flex={1} />
                        <Box
                            sx={{
                                width: '200px',
                                maxWidth: '200px',
                                height: '100%',
                                alignItems: 'top',
                            }}
                            onClick={() => setView('privacy')}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'text.primary',
                                }}
                            >
                                {tempEventData.private ? 'Private' : 'Public'}
                            </Typography>
                            <PrimaryDivider
                                sx={{
                                    mt: '4px',
                                }}
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default CreateEventEditMobileView
