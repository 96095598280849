import { AppBar, Box, styled, Toolbar, Typography } from '@mui/material'
import { FilterData } from '../../../models/Filter'
import DatePickerView from '../FilterItems/DatePickerView'
import { useState } from 'react'
import {
    PrimaryDivider,
    StyledLinearProgress,
} from '../../Components/StyledComponents'
import RadiusPickerView from '../FilterItems/RadiusPickerView'
import AttendeesPickerView from '../FilterItems/AttendeesPickerView'
import FollowersPickerView from '../FilterItems/FollowersPickerView'
import CategoriesPickerView from '../FilterItems/CategoriesPickerView'
import EventTypePickerView from '../FilterItems/EventTypePickerView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { useAppState } from '../../../AppStateProvider'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    zIndex: theme.zIndex.drawer + 1,
}))

interface DiscoverFilterMobileViewProps {
    filterState: FilterData
    setFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    maxDistance: number
    maxAttendees: number
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
}

const DiscoverFilterMobileView = ({
    filterState,
    setFilterState,
    maxDistance,
    maxAttendees,
    setShowFilter,
}: DiscoverFilterMobileViewProps) => {
    const [tempFilterState, setTempFilterState] = useState(filterState)
    return (
        <Box>
            <DiscoverFilterTopBarMobileView
                setShowFilter={setShowFilter}
                tempFilterState={tempFilterState}
                filterState={filterState}
                setFilterState={setFilterState}
            />
            <Box
                style={{
                    padding: '16px 16px',
                    marginTop: '55px',
                    marginBottom: `${navMobileBarHeight}px`,
                }}
            >
                <DatePickerView
                    tempfilterstate={tempFilterState}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
                <PrimaryDivider
                    sx={{
                        m: '16px 0',
                    }}
                />
                <RadiusPickerView
                    filterstate={tempFilterState}
                    setFilterState={setTempFilterState}
                    maxDistance={maxDistance}
                    width="100%"
                />
                <PrimaryDivider
                    sx={{
                        m: '16px 0',
                    }}
                />
                <AttendeesPickerView
                    tempfilterstate={tempFilterState}
                    setTempFilterState={setTempFilterState}
                    maxAttendees={maxAttendees}
                    width="100%"
                />
                <PrimaryDivider
                    sx={{
                        m: '16px 0',
                    }}
                />
                <EventTypePickerView
                    tempfilterstate={tempFilterState}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
                <PrimaryDivider
                    sx={{
                        m: '16px 0',
                    }}
                />
                <FollowersPickerView
                    tempfilterstate={tempFilterState}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
                <PrimaryDivider
                    sx={{
                        m: '16px 0',
                    }}
                />
                <CategoriesPickerView
                    tempfilterstate={tempFilterState}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
            </Box>
        </Box>
    )
}

interface DiscoverFilterTopBarMobileViewProps {
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
    tempFilterState: FilterData
    filterState: FilterData
    setFilterState: React.Dispatch<React.SetStateAction<FilterData>>
}

const DiscoverFilterTopBarMobileView = ({
    setShowFilter,
    tempFilterState,
    filterState,
    setFilterState,
}: DiscoverFilterTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const handleSetFilter = () => {
        if (tempFilterState !== filterState) {
            setFilterState(tempFilterState)
            setShowFilter(false)
        }
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => setShowFilter(false)}
                >
                    Cancel
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            tempFilterState !== filterState
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleSetFilter}
                >
                    Confirm
                </Typography>
            </Toolbar>
        </TopBar>
    )
}

export default DiscoverFilterMobileView
