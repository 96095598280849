import {
    AppBar,
    Box,
    IconButton,
    SelectChangeEvent,
    Toolbar,
    Typography,
    styled,
} from '@mui/material'
import { CreateEvent } from '../../../../models/CreateEvent'
import {
    OutlineContainer,
    OutlineInputBase,
    StyledLinearProgress,
    StyledMenuItem,
    StyledSelect,
    StyledTextField,
} from '../../../Components/StyledComponents'
import { useAppState } from '../../../../AppStateProvider'
import { useEffect, useState } from 'react'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { IOSSwitch } from '../../../DiscoveryView/FilterItems/FollowersPickerView'
import {
    datetime2iso,
    iso2date,
    time2Date,
} from '../../../../utils/DateStringConverter'
import { addMinutes, format, isSameDay } from 'date-fns'
import { thirtyMinuteIncrement } from '../../../../constants'
import DateRangePopperCalendar from '../../../Components/DateRangePopperCalendar'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
}))

interface CreateEventEditDateMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    setView: React.Dispatch<React.SetStateAction<string>>
}

interface CreateEventEditDateTopBarMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    dateRangeSelector: boolean
    dateRange: [Date | null, Date | null]
    startTime: string
    endTime: string
    setView: React.Dispatch<React.SetStateAction<string>>
}

const CreateEventEditDateTopBarMobileView = ({
    tempEventData,
    setTempEventData,
    dateRangeSelector,
    dateRange,
    startTime,
    endTime,
    setView,
}: CreateEventEditDateTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const handleConfirm = () => {
        if (dateRangeSelector) {
            if (dateRange[0] && dateRange[1]) {
                setTempEventData({
                    ...tempEventData,
                    event_time: datetime2iso(dateRange[0], startTime),
                    end_time: datetime2iso(dateRange[1], endTime),
                })
                setView('edit')
            }
        }
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'relative',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => setView('edit')}
                >
                    Back
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            dateRangeSelector && dateRange[0] && dateRange[1]
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleConfirm}
                >
                    Confirm
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        height: 55,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Date
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Toolbar>
        </TopBar>
    )
}

const BoxContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    backgroundColor: theme.palette.background.secondary,
}))

const CreateEventEditDateMobileView = ({
    tempEventData,
    setTempEventData,
    setView,
}: CreateEventEditDateMobileViewProps) => {
    const [dateRangeSelector, setDateRangeSelector] = useState<boolean>(true)
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
        tempEventData.event_time
            ? [
                  iso2date(tempEventData.event_time),
                  iso2date(tempEventData.end_time),
              ]
            : [new Date(), null]
    )
    const [openDateRangePopper, setOpenDateRangePopper] =
        useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const handleDateRangePopperOpen = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        setAnchorEl(event.currentTarget)
        setOpenDateRangePopper(true)
    }
    const [startTime, setStartTime] = useState<string>(
        tempEventData.event_time
            ? format(iso2date(tempEventData.event_time), 'hh:mm a')
            : format(thirtyMinuteIncrement[23], 'hh:mm a')
    )
    const [endTime, setEndTime] = useState<string>(
        tempEventData.end_time
            ? format(iso2date(tempEventData.end_time), 'hh:mm a')
            : format(thirtyMinuteIncrement[24], 'hh:mm a')
    )
    const handleStartTimeChange = (event: SelectChangeEvent<unknown>) => {
        setStartTime(event.target.value as string)
        console.log(event.target.value)
        // if (!dateRangeSelector){}
    }
    useEffect(() => {
        if (dateRangeSelector) {
            if (dateRange[0] && dateRange[1]) {
                setTempEventData({
                    ...tempEventData,
                    event_time: datetime2iso(dateRange[0], startTime),
                    end_time: datetime2iso(dateRange[1], endTime),
                })
            }
        }
    }, [dateRange, startTime, endTime])
    useEffect(() => {
        if (dateRangeSelector && dateRange[0] && dateRange[1]) {
            if (isSameDay(dateRange[0], dateRange[1])) {
                setEndTime(
                    format(addMinutes(time2Date(endTime), 30), 'hh:mm a')
                )
            }
        }
    }, [dateRange])
    const handleCategoryChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDateRangeSelector(event.target.checked)
    }
    const handleEndTimeChange = (event: SelectChangeEvent<unknown>) => {
        setEndTime(event.target.value as string)
    }
    return (
        <Box
            sx={{
                height: '100%',
                p: '16px 16px',
            }}
        >
            <CreateEventEditDateTopBarMobileView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
                dateRangeSelector={dateRangeSelector}
                dateRange={dateRange}
                startTime={startTime}
                endTime={endTime}
                setView={setView}
            />
            <BoxContainer
                sx={{
                    p: '16px',
                }}
            >
                <Box
                    sx={{
                        mt: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.secondary',
                        }}
                    >
                        Day
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    {dateRangeSelector ? (
                        <>
                            <StyledTextField
                                label="Date Range"
                                sx={{
                                    cursor: 'pointer',
                                    width: '200px',
                                }}
                                size="small"
                                value={
                                    dateRange[0] && dateRange[1]
                                        ? `${format(dateRange[0], 'MM/dd/yyyy')} - ${format(dateRange[1], 'MM/dd/yyyy')}`
                                        : ''
                                }
                                onClick={handleDateRangePopperOpen}
                            />
                            <DateRangePopperCalendar
                                value={dateRange}
                                setValue={setDateRange}
                                openPopper={openDateRangePopper}
                                setOpenPopper={setOpenDateRangePopper}
                                anchorEl={anchorEl}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
                <Box
                    sx={{
                        mt: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.secondary',
                        }}
                    >
                        Start Time
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    <StyledSelect
                        sx={{
                            ml: '8px',
                            cursor: 'pointer',
                            height: '38px',
                        }}
                        size="small"
                        value={startTime}
                        name="startTime"
                        onChange={handleStartTimeChange}
                    >
                        {thirtyMinuteIncrement.map((time, index) => (
                            <StyledMenuItem
                                key={index}
                                value={format(time, 'hh:mm a')}
                            >
                                {format(time, 'hh:mm a')}
                            </StyledMenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.secondary',
                        }}
                    >
                        End Time
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    <StyledSelect
                        sx={{
                            ml: '8px',
                            cursor: 'pointer',
                            height: '38px',
                        }}
                        size="small"
                        value={endTime}
                        name="End Time"
                        placeholder="End Time"
                        onChange={handleEndTimeChange}
                    >
                        {thirtyMinuteIncrement.map((time, index) => (
                            <StyledMenuItem
                                key={index}
                                value={format(time, 'hh:mm a')}
                            >
                                {format(time, 'hh:mm a')}
                            </StyledMenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box
                    sx={{
                        mt: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.secondary',
                        }}
                    >
                        Date Range
                    </Typography>
                    <IOSSwitch
                        sx={{
                            marginLeft: 'auto',
                        }}
                        checked={dateRangeSelector}
                        onChange={handleCategoryChange}
                    />
                </Box>
            </BoxContainer>

            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                    mt: '16px',
                }}
            >
                Enhance your event's visability and appeal by adding a
                captivating date that reflects its essence and engages
                potnential attendees.
            </Typography>
        </Box>
    )
}

export default CreateEventEditDateMobileView
