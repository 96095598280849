import {
    AppBar,
    Box,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import {
    BackgroundIconButton,
    StyledIconButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useAppState } from '../../../AppStateProvider'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Post } from '../../../models/Post'
import { useEffect, useState } from 'react'
import { grabEventPhoto } from '../../../api/RestEvent'
import { DataStatus } from '../../../models/Dataloading'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { Event } from '../../../models/Event'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { User } from '../../../models/User'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: 'transparent',
    position: 'fixed',
    width: `calc(100%)`,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    // boxShadow: 'none',
    // marginRight: 6,
}))

interface LogoIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const LogoIconButton = styled((props: LogoIconButtonProps) => (
    <IconButton {...props} />
))(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface TopBarMobileViewProps {
    user: User
    opacity: number
}

const AccountTopBarMobileView = ({ user, opacity }: TopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    return (
        <TopBar
            sx={{
                overflow: 'hidden',
            }}
        >
            <Toolbar
                sx={{
                    minHeight: '55px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <BackgroundIconButton
                    sx={{
                        height: '36px',
                        width: '36px',
                    }}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <ArrowBackIosNewRoundedIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </BackgroundIconButton>
                <Box
                    ml="12px"
                    sx={{
                        opacity: opacity,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: 'text.primary',
                            fontWeight: 'bold',
                        }}
                    >
                        {user.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            bt: 0,
                            color: 'text.primary',
                        }}
                    >
                        {user.follower_size} Followers
                    </Typography>
                </Box>

                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {state.username == user.username ? (
                        <>
                            <BackgroundIconButton
                                sx={{
                                    height: '36px',
                                    width: '36px',
                                    mr: '8px',
                                }}
                            >
                                <AddRoundedIcon
                                    sx={{
                                        fontSize: '20px',
                                    }}
                                />
                            </BackgroundIconButton>
                            <BackgroundIconButton
                                sx={{
                                    height: '36px',
                                    width: '36px',
                                }}
                            >
                                <SettingsRoundedIcon
                                    sx={{
                                        fontSize: '16px',
                                    }}
                                />
                            </BackgroundIconButton>
                        </>
                    ) : (
                        <BackgroundIconButton
                            sx={{
                                height: '36px',
                                width: '36px',
                            }}
                        >
                            <MoreHorizRoundedIcon
                                sx={{
                                    fontSize: '20px',
                                }}
                            />
                        </BackgroundIconButton>
                    )}
                </Box>
            </Toolbar>
        </TopBar>
    )
}

export default AccountTopBarMobileView
