import { Box } from '@mui/material'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { useEffect, useState } from 'react'
import CreateEventTopBarMobileView from './CreateEventTopBarMobileView'
import { DataStatus } from '../../../models/Dataloading'
import CreateEventEditMobileView from './CreateEventEditView'
import { CreateEvent } from '../../../models/CreateEvent'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigateCreateEventState } from '../../../models/NavigateState'
import CreateEventEditTitleMobileView from './CreateEventEditView/CreateEventEditTitleMobileView'
import CreateEventEditDescriptionMobileView from './CreateEventEditView/CreateEventEditDescriptionMobileView'
import CreateEventEditCategoriesMobileView from './CreateEventEditView/CreateEventEditCategoriesMobileView'
import CreateEventEditTagsMobileView from './CreateEventEditView/CreateEventEditTagsMobileView'
import CreateEventEditDateMobileView from './CreateEventEditView/CreateEventEditDateMobileView'
import CreateEventEditLocationMobileView from './CreateEventEditView/CreateEventEditLocationMobileView'
import CreateEventPreviewMobileView from './CreateEventPreviewMobileView'
import { getUser } from '../../../api/RestUser'
import { useAppState } from '../../../AppStateProvider'
import { stat } from 'fs'

const CreateEventMobileView = () => {
    const { state, setState } = useAppState()
    const [view, setView] = useState('edit')
    const [submitLoading, setSubmitLoading] = useState<DataStatus>(
        DataStatus.none
    )
    const navigate = useNavigate()
    const location = useLocation()
    const initialTempEventData = location.state as NavigateCreateEventState
    const [tempEventData, setTempEventData] = useState<CreateEvent>(
        initialTempEventData.tempEventData
            ? initialTempEventData.tempEventData
            : {
                  title: '',
                  image: '',
                  description: '',
                  tag_list: [],
                  category_list: [],
                  private: false,
                  primary_host: null,
                  host: [],
                  location: null,
                  locationsearch: null,
                  address: null,
                  event_time: '',
                  end_time: '',
                  attendee_limit: 0,
              }
    )
    useEffect(() => {
        getUser(state.username, state.usertoken)
            .then((response) => {
                setTempEventData((prev) => {
                    return {
                        ...prev,
                        primary_host: response.profile,
                    }
                })
            })
            .catch((error) => {
                console.log(error, 'errror')
            })
    }, [])
    return (
        <Box
            sx={{
                height: '100%',
                mt: view !== 'preview' ? '55px' : 0,
                pb: view !== 'preview' ? `${navMobileBarHeight}px` : 0,
                zIndex: (theme) => theme.zIndex.drawer + 5,
            }}
        >
            {view === 'edit' ? (
                <>
                    <CreateEventTopBarMobileView
                        tempEventData={tempEventData}
                        view={view}
                        setView={setView}
                        setSubmitLoading={setSubmitLoading}
                        submitLoading={submitLoading}
                    />
                    <CreateEventEditMobileView
                        tempEventData={tempEventData}
                        setTempEventData={setTempEventData}
                        setView={setView}
                    />
                </>
            ) : view === 'preview' ? (
                <CreateEventPreviewMobileView
                    tempEvent={tempEventData}
                    setTempEvent={setTempEventData}
                    setView={setView}
                />
            ) : view === 'title' ? (
                <CreateEventEditTitleMobileView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    setView={setView}
                />
            ) : view === 'date' ? (
                <CreateEventEditDateMobileView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    setView={setView}
                />
            ) : view === 'description' ? (
                <CreateEventEditDescriptionMobileView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    setView={setView}
                />
            ) : view === 'location' ? (
                <CreateEventEditLocationMobileView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    setView={setView}
                />
            ) : view === 'categories' ? (
                <CreateEventEditCategoriesMobileView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    setView={setView}
                />
            ) : view === 'tags' ? (
                <CreateEventEditTagsMobileView
                    tempEventData={tempEventData}
                    setTempEventData={setTempEventData}
                    setView={setView}
                />
            ) : (
                <></>
            )}
        </Box>
    )
}

export default CreateEventMobileView
