import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    Tab,
    Tabs,
    Divider,
    List,
    InputBase,
    Button,
    ButtonBase,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { NavigatePostState } from '../../../models/NavigateState'
import { string } from 'yup'
import { DataStatus } from '../../../models/Dataloading'
import { Post } from '../../../models/Post'
import { set } from 'date-fns'
import PostItemCommentView from '../PostItemCommentView'
import {
    PrimaryDivider,
    SecondaryTypography,
    StyledChip,
    StyledCircularProgress,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Comment } from '../../../models/Comment'
import CommentView from '../CommentView'
import { styled } from '@mui/material/styles'
import PostEventSubView from '../../SideViews/PostEventSideView'
import { Create } from '@mui/icons-material'
import CreateCommentView from '../CreateCommentView'
import SideLinksView from '../../SideViews/SideLinksView'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import SortPopper from '../../Components/SortPopper'
import EmptyCommentsView from '../../Components/StaticPages/EmptyView/EmptyCommentsView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostItemCommentMobileView from './PostItemCommentMobileView'
import CommentMobileView from './CommentMobileView'
import PostTopBarMobileView from './PostTopBarMobileView'
import PostCommentBarMobileView from './PostCommentBarMobileView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import CreateCommentExpandMobileView from './CreateCommentExpandMobileView'
import PostOptionsMobileView from './PostOptionsMobileView'

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const PostMainMobileView = React.memo(() => {
    const { uuid } = useParams<{ uuid: string }>()
    const location = useLocation()
    const locationState = location.state as NavigatePostState | null
    const [post, setPost] = useState<Post | null>(null)
    const [comments, setComments] = useState<Comment[] | null>(null)
    const [postImage, setPostImage] = useState<string | null>(null)
    const [postAuthorImage, setPostAuthorImage] = useState<string | null>(null)
    const [postAuthorImageLoaded, setPostAuthorImageLoaded] =
        useState<DataStatus>(DataStatus.loading)
    const [postImageLoaded, setPostImageLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Hot')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [inputComment, setInputComment] = useState<string>('')
    const [showExpandComment, setShowExpandComment] = useState(false)
    const [focused, setFocused] = useState(false)
    const [commenting, setCommenting] = useState(false)
    const [selectedComment, setSelectedComment] = useState<Comment | null>(null)
    const [showOptions, setShowOptions] = useState(false)
    useEffect(() => {
        if (locationState !== null) {
            setPostImage(locationState.postImage)
            setPostAuthorImage(locationState.postAuthorImage)
            setPost(locationState.post)
            setComments(locationState.comments)
            setPostLoaded(DataStatus.success)
            setPostImageLoaded(DataStatus.success)
            setPostAuthorImageLoaded(DataStatus.success)
        } else {
            setPostLoaded(DataStatus.failure)
            setPostImageLoaded(DataStatus.failure)
            setPostAuthorImageLoaded(DataStatus.failure)
        }
    }, [location.key])

    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    return (
        <div
            id="post-main-view"
            style={{
                height: '100%',
                width: '100%',
                position: 'relative',
                justifyContent: 'center',
                display: 'flex',
                float: 'left',
                zIndex: 1,
            }}
        >
            {post && (
                <PostOptionsMobileView
                    post={post}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                />
            )}
            {showExpandComment && post ? (
                <CreateCommentExpandMobileView
                    post={post}
                    postImage={postImage}
                    setPostImage={setPostImage}
                    postImageStatus={postImageLoaded}
                    setPostImageStatus={setPostImageLoaded}
                    postAuthorImage={postAuthorImage}
                    setPostAuthorImage={setPostAuthorImage}
                    postAuthorImageStatus={postAuthorImageLoaded}
                    setPostAuthorImageStatus={setPostAuthorImageLoaded}
                    selectedComment={selectedComment}
                    setSelectedComment={setSelectedComment}
                    comments={comments}
                    setComments={setComments}
                    showExpandComment={showExpandComment}
                    setShowExpandComment={setShowExpandComment}
                    inputComment={inputComment}
                    setInputComment={setInputComment}
                    setCommenting={setCommenting}
                    setFocused={setFocused}
                />
            ) : (
                <>
                    {post && (
                        <PostTopBarMobileView
                            post={post}
                            setShowOptions={setShowOptions}
                        />
                    )}
                    {post && (
                        <PostCommentBarMobileView
                            post={post}
                            comments={comments}
                            setComments={setComments}
                            inputComment={inputComment}
                            setInputComment={setInputComment}
                            focused={focused}
                            setFocused={setFocused}
                            commenting={commenting}
                            setCommenting={setCommenting}
                            setShowExpandComments={setShowExpandComment}
                        />
                    )}
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            marginTop: '56px',
                            marginBottom: `${navMobileBarHeight + 60}px`,
                            position: 'relative',
                        }}
                    >
                        {postloaded === DataStatus.success && post !== null ? (
                            <Box>
                                <PostItemCommentMobileView
                                    post={post}
                                    postImage={postImage}
                                    postAuthorImage={postAuthorImage}
                                    postloaded={postloaded}
                                    postAuthorImageLoaded={
                                        postAuthorImageLoaded
                                    }
                                    postImageLoaded={postImageLoaded}
                                    setShowOptions={setShowOptions}
                                />

                                <PrimaryDivider
                                    sx={{
                                        marginTop: '0px',
                                        marginBottom: '4px',
                                    }}
                                />
                                {comments !== null ? (
                                    comments.length === 0 ? (
                                        <EmptyCommentsView />
                                    ) : (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    mt: '8px',
                                                    mb: '8px',
                                                    alignItems: 'center',
                                                    ml: '16px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 13,
                                                        fontWeight: 'bold',
                                                        color: 'text.tertiary',
                                                    }}
                                                >
                                                    Sort by:
                                                </Typography>
                                                <SortPopper
                                                    sortOptions={[
                                                        'Hot',
                                                        'Newest',
                                                        'Top',
                                                    ]}
                                                    sortbyState={sortbyState}
                                                    setSortByState={
                                                        setSortByState
                                                    }
                                                    openpopover={
                                                        sortbyShowState
                                                    }
                                                    setOpenPopover={
                                                        setSortbyShowState
                                                    }
                                                    anchorEl={sortAnchorEl}
                                                    setAnchorEl={
                                                        setSortAnchorEl
                                                    }
                                                />
                                                <StyledChip
                                                    sx={{
                                                        ml: '4px',
                                                        cursor: 'pointer',
                                                        background: (theme) =>
                                                            theme.palette
                                                                .background
                                                                .tertiary,
                                                    }}
                                                    component={ButtonBase}
                                                    onClick={
                                                        handleSortPopperOpen
                                                    }
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 13,
                                                            fontWeight: 'bold',
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        {sortbyState}
                                                    </Typography>
                                                    <StyledArrowForwardIosRoundedIcon
                                                        sx={{
                                                            fontSize: 13,
                                                            ml: '4px',
                                                        }}
                                                        open={sortbyShowState}
                                                    />
                                                </StyledChip>
                                            </Box>
                                            {comments.map((comment) => (
                                                <Box
                                                    sx={{ padding: '0px 0px' }}
                                                >
                                                    <CommentMobileView
                                                        post={post}
                                                        comment={comment}
                                                        setSelectedComment={
                                                            setSelectedComment
                                                        }
                                                        setShowExpandComment={
                                                            setShowExpandComment
                                                        }
                                                    />
                                                    <PrimaryDivider />
                                                </Box>
                                            ))}
                                        </>
                                    )
                                ) : (
                                    <ErrorTabView />
                                )}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <StyledCircularProgress />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </div>
    )
})

export default PostMainMobileView
