import {
    AppBar,
    Box,
    Button,
    IconButton,
    IconButtonProps,
    InputBase,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import {
    BackgroundIconButton,
    StyledIconButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useAppState } from '../../../AppStateProvider'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Post } from '../../../models/Post'
import { useEffect, useState } from 'react'
import { grabEventPhoto } from '../../../api/RestEvent'
import { DataStatus } from '../../../models/Dataloading'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import { createComment } from '../../../api/RestComment'
import { Comment } from '../../../models/Comment'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
    },
}))

const CommentButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    textTransform: 'none',
    borderRadius: 50,
    padding: '2px 16px',
    fontSize: 12,
}))

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.tertiary,
    textTransform: 'none',
    borderRadius: 50,
    padding: '2px 16px',
    fontSize: 12,
}))

const BottomBar = styled(Box)(({ theme }) => ({
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `100%`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    borderRadius: '10px 10px 0 0',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.8)',
}))

const Search = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: theme.palette.background.tertiary,
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
    alignItems: 'center',
    display: 'flex',
    minHeight: 35,
    marginRight: 16,
}))
interface LogoIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const LogoIconButton = styled((props: LogoIconButtonProps) => (
    <IconButton {...props} />
))(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

interface TopBarMobileViewProps {
    post: Post
    comments: Comment[] | null
    setComments: React.Dispatch<React.SetStateAction<Comment[] | null>>
    inputComment: string
    setInputComment: React.Dispatch<React.SetStateAction<string>>
    focused: boolean
    setFocused: React.Dispatch<React.SetStateAction<boolean>>
    commenting: boolean
    setCommenting: React.Dispatch<React.SetStateAction<boolean>>
    setShowExpandComments: React.Dispatch<React.SetStateAction<boolean>>
}

const PostCommentBarMobileView = ({
    post,
    comments,
    setComments,
    inputComment,
    setInputComment,
    focused,
    setFocused,
    commenting,
    setCommenting,
    setShowExpandComments,
}: TopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const [eventImage, setEventImage] = useState<string | null>(null)
    const [eventImageStatus, setEventImageStatus] = useState(DataStatus.loading)
    const navigate = useNavigate()
    const handleFocus = () => {
        setFocused(true)
        setCommenting(true)
    }
    const handleBlur = () => {
        setFocused(false)
        if (inputComment === '') {
            setCommenting(false)
        }
    }

    const handleCanncel = () => {
        setInputComment('')
        setFocused(false)
        setCommenting(false)
    }

    const handleComment = () => {
        try {
            createComment(inputComment, post.id, state.usertoken)
                .then((response) => {
                    if (comments === null) {
                        setComments([response.comment])
                        setInputComment('')
                        setFocused(false)
                        setCommenting(false)
                    } else {
                        setComments([...comments, response.comment])
                        setInputComment('')
                        setFocused(false)
                        setCommenting(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        try {
            grabEventPhoto(post.event.image, state.usertoken)
                .then((response) => {
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    return (
        <BottomBar
            sx={{
                overflow: 'hidden',
                bottom: `${navMobileBarHeight}px`,
            }}
        >
            <Box
                sx={{
                    p: '8px 16px',
                }}
            >
                {commenting && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: '4px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,

                                color: 'text.tertiary',
                            }}
                        >
                            Replying to
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                ml: '4px',
                                color: 'text.selected',
                            }}
                        >
                            @{post.author.username}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            float: 'left',
                            alignItems: 'top',
                        }}
                    >
                        {state.userimage ? (
                            <UserPhotoCircle
                                width={35}
                                height={35}
                                includeBorder={true}
                                sx={{
                                    overflow: 'hidden',
                                }}
                                id="user-photo-circle"
                            >
                                <img
                                    src={state.userimage}
                                    alt="User"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </UserPhotoCircle>
                        ) : (
                            <UserPhotoCircle
                                id="user-photo-circle"
                                width={35}
                                height={35}
                                includeBorder={true}
                            >
                                <PersonRoundedIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.text.disabled,
                                    }}
                                />
                            </UserPhotoCircle>
                        )}
                    </Box>
                    <Box
                        sx={{
                            ml: '8px',

                            mb: '0px',
                            height: '100%',
                            width: '100%',
                            float: 'right',
                            alignItems: 'top',
                        }}
                    >
                        <Search>
                            <StyledInputBase
                                placeholder={`Reply to ${post.author.name}`}
                                inputProps={{ 'aria-label': 'search' }}
                                multiline
                                fullWidth
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={inputComment}
                                onChange={(e) =>
                                    setInputComment(e.target.value)
                                }
                            />
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'top',
                                    justifyContent: 'center',
                                }}
                            >
                                <StyledIconButton
                                    onClick={() => setShowExpandComments(true)}
                                >
                                    <OpenInFullRoundedIcon
                                        sx={{
                                            fontSize: '16px',
                                            color: 'text.primary',
                                        }}
                                    />
                                </StyledIconButton>
                            </Box>
                        </Search>
                        {commenting && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginTop: '8px',
                                }}
                            >
                                <CancelButton onClick={handleCanncel}>
                                    Cancel
                                </CancelButton>
                                <CommentButton
                                    onClick={handleComment}
                                    sx={{
                                        marginLeft: '8px',
                                    }}
                                    disabled={inputComment === ''}
                                >
                                    Comment
                                </CommentButton>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </BottomBar>
    )
}

export default PostCommentBarMobileView
