import React, { useEffect, useState, useRef, useMemo } from 'react'
import { getEvents } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import { Event } from '../../models/Event'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    Drawer,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import EventSubView from '../EventView/EventSubView'
import MapView from './MapView'
import FilterTopView from './FilterTopView'
import { LocationSearch } from '../../models/Location'
import { EventType, FilterData, FilterDateChoice } from '../../models/Filter'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { off } from 'process'
import { NavigateDiscoverState } from '../../models/NavigateState'
import { hexToRGBA } from '../../utils/haxToRGBA'
import DiscoverMobileView from './DiscoverMobileView'

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

const StyledChevronRightRoundedIcon = styled(ChevronRightRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const TopBar = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: 3,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

interface MapIconButtonProps extends React.ComponentProps<typeof IconButton> {
    expandmap: boolean
}
const MapIconButton = styled((props: MapIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, expandmap }) => ({
    position: 'absolute',
    zIndex: 3,
    borderRadius: 50,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.8),
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.background.primary, 0.8),
    },
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    transform: expandmap ? 'rotate(-180deg)' : 'rotate(0deg)',
}))

interface ExpandDivProps extends React.HTMLAttributes<HTMLDivElement> {
    expandmap: boolean
    divideropen: boolean
}

const MapDiv = styled((props: ExpandDivProps) => <div {...props} />)(
    ({ theme, expandmap, divideropen }) => ({
        height: '100%',
        width: '100%',
        float: 'left',
        zIndex: 2,
        position: 'relative',
        ...(!expandmap
            ? {
                  marginRight: divideropen
                      ? 'calc(50% + 110px)'
                      : 'calc(50% + 30px)',
                  transition: theme.transitions.create('margin', {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                  }),
              }
            : {
                  marginRight: 0,
                  transition: theme.transitions.create('margin', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                  }),
              }),
    })
)

const EventDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: '50%',
        boxSizing: 'border-box',
        background: theme.palette.background.primary,

        marginTop: 107,

        borderLeft: '1px solid ' + theme.palette.border.primary,
    },
}))

const DiscoveryView = () => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? <DiscoverMobileView /> : <DiscoveryDesktopView />}
        </>
    )
}

const DiscoveryDesktopView = React.memo(() => {
    const [offset, setOffset] = useState(0)
    const location = useLocation()
    const stateData = location.state as NavigateDiscoverState
    const { state, setState } = useAppState()
    const [expandmap, setExpandMap] = useState(false)
    const [locationsearchstate, setLocationSearchState] =
        useState<LocationSearch | null>({
            description: 'Los Angeles',
            maintext: 'Los Angeles',
            secondarytext: 'USA',
            place_id: 'ChIJE9on3F3HwoAR9AhGJW_fL-I',
            location: { lat: 34.0549076, lng: -118.242643 },
        })
    const [filterstate, setFilterState] = useState<FilterData>({
        date: { choice: FilterDateChoice.Any, dates: [] },
        attendeeCount: [0, 500],
        range: 500,
        premium: EventType.None,
        following: false,
        category: [],
    })

    const [eventloaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventstate, setEventState] = useState<Event[]>(
        stateData ? stateData.eventstate : []
    )
    const scrollRef = useRef<HTMLDivElement>(null)

    const handleSuccess = (position: any) => {
        const { latitude, longitude } = position.coords
        setLocationSearchState({
            description: 'Current Location',
            maintext: 'Current Location',
            secondarytext: 'Current Location',
            place_id: 'default',
            location: { lat: latitude, lng: longitude },
        })
        setState((prevState) => ({
            ...prevState,
            location: { latitude, longitude },
        }))
    }

    const handleError = (error: any) => {
        console.log(error.message)
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
        } else {
            console.log('Geolocation is not supported by this browser.')
        }
    }
    useEffect(() => {
        getLocation()

        if (eventstate.length === 0) {
            setEventLoaded(DataStatus.loading)
            try {
                getEvents(null, null, null, 5, 20, offset, state.usertoken)
                    .then((response) => {
                        console.log(response)
                        setEventState((prevState) => [
                            ...prevState,
                            ...response.events,
                        ])
                        setEventLoaded(DataStatus.success)
                        setOffset((prev) => prev + 20)
                    })
                    .catch((error) => {
                        console.error(error)
                        setEventLoaded(DataStatus.failure)
                    })
            } catch (error) {
                console.error(error)
                setEventLoaded(DataStatus.failure)
            }
        } else {
            setEventLoaded(DataStatus.success)
        }
    }, [])

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                marginTop: '56px',
                display: 'flex',
            }}
        >
            <TopBar
                style={{
                    height: 50,
                    width: '100%',
                    display: 'flex',
                    position: 'fixed',
                }}
            >
                <FilterTopView
                    locationsearchstate={locationsearchstate}
                    setLocationSearchState={setLocationSearchState}
                    filterstate={filterstate}
                    setFilterState={setFilterState}
                />
            </TopBar>
            <div
                id="discovery-map-div"
                style={{
                    height: 'calc(100vh - 106px)',
                    width: '100%',
                    display: 'flex',
                    marginTop: 50,
                }}
            >
                <MapDiv expandmap={expandmap} divideropen={state.open}>
                    <MapIconButton
                        expandmap={expandmap}
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                        }}
                        onClick={() => setExpandMap(!expandmap)}
                    >
                        <StyledChevronRightRoundedIcon />
                    </MapIconButton>
                    <MapView eventstate={eventstate} />
                </MapDiv>
                <EventDrawer
                    variant="persistent"
                    anchor="right"
                    open={!expandmap}
                >
                    <div
                        ref={scrollRef}
                        style={{
                            height: 'calc(100vh - 120px)',
                            justifyContent: 'center',
                            display: 'flex',
                            borderRadius: 2,
                            padding: 10,
                            overflowY: 'auto',
                        }}
                    >
                        {eventloaded === DataStatus.loading ? (
                            <StyledCircularProgress />
                        ) : (
                            <div style={{ width: '100%', padding: '0px 10px' }}>
                                {locationsearchstate && (
                                    <>
                                        <PrimaryTypography
                                            align="left"
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                            }}
                                        >{`Events nearby ${locationsearchstate.maintext}`}</PrimaryTypography>
                                        <SecondaryTypography
                                            align="left"
                                            sx={{ fontSize: 12 }}
                                        >{`${eventstate.length} results`}</SecondaryTypography>
                                    </>
                                )}
                                <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                        paddingTop: '5px',
                                        paddingBottom: '10px',
                                        width: '100%',
                                    }}
                                >
                                    {eventstate.map((event) => (
                                        <Grid item xs={12} md={6} lg={6} xl={3}>
                                            <EventSubView event={event} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}
                    </div>
                </EventDrawer>
            </div>
        </div>
    )
})

export default DiscoveryView
