import { Box, Chip, Typography, hexToRgb, styled } from '@mui/material'
import {
    StyledCircularProgress,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'
import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import PostSubEventMobileView from '../../PostView/PostMobileView/PostSubEventMobieView'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import AccountAvatarSubView from '../../AccountView/AccountAvatarSubView'
import { User } from '../../../models/User'
import { grabUserPhoto } from '../../../api/RestUser'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { useQuery } from 'react-query'
import { useApiManager } from '../../../api/ApiMangerProvider'

interface EventDetailsAttendeesMobileViewProps {
    event: Event
    eventImage: string | null
}

const OverlayAttendees = styled(Box)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.5),
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
}))

const PostBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    minWidth: 'calc(100% - 16px)',
}))
const EventDetailsAttendeesMobileView = ({
    event,
    eventImage,
}: EventDetailsAttendeesMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                ATTENDEES
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'top',
                        position: 'relative',
                        height: '60px',
                        mt: '8px',
                        maxWidth: '50%',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: 'text.primary',
                            mb: 'auto',
                        }}
                    >
                        Attending ({event.attendees.length})
                    </Typography>
                    {event.attendees.map((attendee, index) =>
                        index < 6 ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: `${index * 20}px`,
                                }}
                            >
                                <AttendeeSubMobileView attendee={attendee} />
                            </Box>
                        ) : (
                            index === 6 && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '25px',
                                        left: `${index * 20}px`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        <AttendeeSubMobileView
                                            attendee={attendee}
                                        />
                                        <OverlayAttendees
                                            sx={{
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: '50%',
                                                top: '0px',
                                                left: '0px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    color: 'text.primary',
                                                }}
                                            >
                                                +{event.attendees.length - 7}
                                            </Typography>
                                        </OverlayAttendees>
                                    </Box>
                                </Box>
                            )
                        )
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'top',
                        position: 'relative',
                        height: '60px',
                        mt: '8px',
                        maxWidth: '50%',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: 'text.primary',
                            mb: 'auto',
                        }}
                    >
                        Following ({event.attendees.length})
                    </Typography>
                    {event.attendees.map((attendee, index) =>
                        index < 6 ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: `${index * 20}px`,
                                }}
                            >
                                <AttendeeSubMobileView attendee={attendee} />
                            </Box>
                        ) : (
                            index === 6 && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '25px',
                                        left: `${index * 20}px`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        <AttendeeSubMobileView
                                            attendee={attendee}
                                        />
                                        <OverlayAttendees
                                            sx={{
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: '50%',
                                                top: '0px',
                                                left: '0px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    color: 'text.primary',
                                                }}
                                            >
                                                +{event.attendees.length - 7}
                                            </Typography>
                                        </OverlayAttendees>
                                    </Box>
                                </Box>
                            )
                        )
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '8px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'top',
                        position: 'relative',
                        height: '60px',
                        mt: '8px',
                        maxWidth: '50%',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: 'text.primary',
                            mb: 'auto',
                        }}
                    >
                        Hosts ({event.attendees.length})
                    </Typography>
                    {event.attendees.map((attendee, index) =>
                        index < 6 ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: `${index * 20}px`,
                                }}
                            >
                                <AttendeeSubMobileView attendee={attendee} />
                            </Box>
                        ) : (
                            index === 6 && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '25px',
                                        left: `${index * 20}px`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        <AttendeeSubMobileView
                                            attendee={attendee}
                                        />
                                        <OverlayAttendees
                                            sx={{
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: '50%',
                                                top: '0px',
                                                left: '0px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    color: 'text.primary',
                                                }}
                                            >
                                                +{event.attendees.length - 7}
                                            </Typography>
                                        </OverlayAttendees>
                                    </Box>
                                </Box>
                            )
                        )
                    )}
                </Box>
            </Box>
        </Box>
    )
}

interface AttendeeSubMobileViewProps {
    attendee: User
}
const AttendeeSubMobileView = ({ attendee }: AttendeeSubMobileViewProps) => {
    const apiManager = useApiManager()
    const [userImageStatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const { state, setState } = useAppState()
    const { data: userImage, isLoading: isUserImageLoading, error: userImageError } = useQuery(
        attendee.image,
        ()=>apiManager.grabUserPhoto(attendee.image),
    )

    return (
        <AccountAvatarSubView
            user={attendee}
            userimage={userImage}
            isUserImageLoading={isUserImageLoading}
            handleAccountPopperClose={() => {}}
            handleAccountPopperOpen={() => {}}
            following={false}
            height={35}
            width={35}
            showFollowButton={false}
        />
    )
}

export default EventDetailsAttendeesMobileView
