import React, { useEffect, useState, useRef } from 'react'
import { Post } from '../../models/Post'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import { grabUserPhoto } from '../../api/RestUser'
import { grabEventPhoto } from '../../api/RestEvent'
import { favoritePost, grabPostPhoto, unfavoritePost } from '../../api/RestPost'
import EventSubPostView from '../EventView/EventSubPostView'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'

import {
    Box,
    CardContent,
    CardMedia,
    Typography,
    Divider,
    IconButton,
    Collapse,
    Chip,
    Button,
    ButtonBase,
    Grow,
    Fade,
} from '@mui/material'
import {
    PrimaryTypography,
    SecondaryTypography,
} from '../Components/StyledComponents'
import { timeDifferenceFromCurrentTime } from '../../utils/TimeDifference'
import AccountPopoverView from '../AccountView/AccountPopoverView'
import AccountAvatarSubView from '../AccountView/AccountAvatarSubView'
import { Expand } from '@mui/icons-material'
import ExpandImagePostView from './ExpandImagePostView'
import { Comment } from '../../models/Comment'
import HashtagTypography from '../Components/HighlightTypography'
import PostHeaderView from './PostHeaderView'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: 10,
    '&:last-child': {
        paddingBottom: 0,
    },
}))

const PostLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
    },
}))

const PostCard = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.primary,
    padding: 10,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
}))

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledChatBubbleOutlineRoundedIcon = styled(ChatBubbleOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledIosShareRoundedIcon = styled(IosShareRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.border.primary,
}))

const StyledChip = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderRadius: 50,
    padding: '5px 10px',
    display: 'flex',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.background.tertiary,
    alignItems: 'center',
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

interface PostItemCommentViewProps {
    post: Post
    postImage: string | null
    postAuthorImage: string | null
    postloaded: DataStatus
    postAuthorImageLoaded: DataStatus
    postImageLoaded: DataStatus
    comments: Comment[] | null
}

const PostItemCommentView = ({
    post,
    postImage,
    postAuthorImage,
    postloaded,
    postAuthorImageLoaded,
    postImageLoaded,
    comments,
}: PostItemCommentViewProps) => {
    const { state, setState } = useAppState()
    const timerRef = useRef<number | null>(null)
    const [user, setUser] = useState(post.author)
    const [eventcollapse, setEventCollapse] = useState(false)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [postState, setPostState] = useState<Post>(post)
    const [expandImage, setExpandImage] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )

    const handleAccountPoperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPoperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 300)
    }

    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimage, setEventImage] = useState<HTMLImageElement | null>(null)
    const handleFavorite = () => {
        if (!postState.favorited) {
            setPostState({
                ...postState,
                favorited: true,
                favorites_count: postState.favorites_count + 1,
            })
            try {
                favoritePost(postState.id, state.usertoken)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            setPostState({
                ...postState,
                favorited: false,
                favorites_count: postState.favorites_count - 1,
            })
            try {
                unfavoritePost(postState.id, state.usertoken)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <PostCard position={'relative'}>
            {expandImage && (
                <ExpandImagePostView
                    post={post}
                    postImage={postImage!}
                    comments={comments}
                    expandImage={expandImage}
                    setExpandImage={setExpandImage}
                />
            )}
            {/* <Box
                display="flex"
                alignItems="center"
                paddingBottom={1}
                position={'relative'}
            >
                <AccountAvatarSubView
                    user={user}
                    userimage={postAuthorImage}
                    userimagestatus={postAuthorImageLoaded}
                    handleAccountPopperClose={handleAccountPoperClose}
                    handleAccountPopperOpen={handleAccountPoperOpen}
                    following={user.following}
                    height={35}
                    width={35}
                />
                <AccountPopoverView
                    user={user}
                    setUser={setUser}
                    openpopover={openAccountPopover}
                    setOpenPopover={setOpenAccountPopover}
                    anchorEl={accountAnchorEl}
                    setAnchorEl={setAccountAnchorEl}
                    timerRef={timerRef}
                />

                <Box marginLeft={'8px'}>
                    <Box display="flex" justifyContent="center">
                        <PrimaryTypography style={{ fontSize: 14 }}>
                            {user.name}
                        </PrimaryTypography>
                        <Box width={4} />
                        {post.attending ? (
                            <PersonRoundedIcon
                                style={{ color: 'yellow', fontSize: 18 }}
                            />
                        ) : null}
                        <Box flex={1} />
                        <Box width={4} />
                        <SecondaryTypography style={{ fontSize: 14 }}>
                            @{user.username}
                        </SecondaryTypography>
                        <Box width={4} />
                        <SecondaryTypography style={{ fontSize: 14 }}>
                            {'\u{2022} ' +
                                timeDifferenceFromCurrentTime(post.created_at)}
                        </SecondaryTypography>
                    </Box>
                    <PrimaryTypography style={{ fontSize: 14 }}>
                        {post.event.title}
                    </PrimaryTypography>
                </Box>
            </Box>
            <StyledIconButton
                sx={{
                    float: 'right',
                    position: 'absolute',
                    right: '4px',
                    top: '4px',
                }}
            >
                <MoreHorizRoundedIcon sx={{ fontSize: 16 }} />
            </StyledIconButton> */}
            <PostHeaderView
                post={post}
                user={user}
            />

            {/* <PrimaryTypography paddingBottom={1}
            sx={{
                fontSize: 14,
                paddingBottom: '8px',
            }}>
                {post.caption}
            </PrimaryTypography>
             */}
            <Box
                sx={{
                    fontSize: 14,
                    paddingBottom: '8px',
                }}
            >
                <HashtagTypography text={post.caption} fontSize={14} />
            </Box>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    aspectRatio: '20/9',
                    position: 'relative',
                }}
            >
                {postImageLoaded === DataStatus.success && postImage ? (
                    <img
                        src={postImage}
                        alt="Event"
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px',
                        }}
                        onClick={() => setExpandImage(true)}
                    />
                ) : eventimagestatus === DataStatus.loading ? (
                    <LoadingBox />
                ) : (
                    <LoadingBox />
                )}
            </div>
            <Box
                display="flex"
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
                width="100%"
                paddingTop="8px"
            >
                <Box display="flex">
                    <StyledChip component={ButtonBase} onClick={handleFavorite}>
                        {postState.favorited ? (
                            <>
                                <FavoriteRoundedIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.text.favorite,
                                        marginRight: '8px',
                                        fontSize: 16,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        // color: (theme) =>
                                        //     theme.palette.text.favorite,
                                        fontSize: 14,
                                        color: 'text.primary',
                                    }}
                                >
                                    {postState.favorites_count}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <StyledFavoriteBorderRoundedIcon
                                    style={{ marginRight: '8px', fontSize: 16 }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: 'text.primary',
                                    }}
                                >
                                    {postState.favorites_count}
                                </Typography>
                            </>
                        )}
                    </StyledChip>
                    <Box width={20} />
                    <StyledChip component={ButtonBase}>
                        <StyledChatBubbleOutlineRoundedIcon
                            style={{ marginRight: 8, fontSize: 16 }}
                        />
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: 'text.primary',
                            }}
                        >
                            {post.comment_count}
                        </Typography>
                    </StyledChip>
                    <Box width={20} />
                    <StyledChip component={ButtonBase}>
                        <IosShareRoundedIcon
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                marginRight: '8px',
                                fontSize: 16,
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: 'text.primary',
                            }}
                        >
                            Share
                        </Typography>
                    </StyledChip>
                </Box>
                {/* <StyledIconButton
                    onClick={() => setEventCollapse(!eventcollapse)}
                >
                    <StyledArrowForwardIosRoundedIcon
                        style={{ fontSize: 16 }}
                        open={eventcollapse}
                    />
                </StyledIconButton> */}
            </Box>
            {/* <Collapse in={eventcollapse} timeout="auto" unmountOnExit>
                <StyledDivider style={{ marginBottom: 10 }} />

                <EventSubPostView event={post.event} />
            </Collapse> */}
        </PostCard>
    )
}

export default PostItemCommentView
