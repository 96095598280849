import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import ActiveExpiredTicketView from '../ActiveExpiredTicketView'
import TicketsTopBarMobileView from './TicketsTopBarMobileView'
import { ThemeContext } from '@emotion/react'

const TicketsMobileView = () => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const location = useLocation()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const [value, setValue] = useState(tabIndex)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/tickets/${newValue}`)
    }
    useEffect(() => {
        setValue(tabIndex)
    }, [window.location.pathname])
    return (
        <Box>
            <Box
                sx={{
                    height: '100%',
                    marginTop: '90px',
                    width: '100%',
                    marginBottom: '80px',
                    zIndex: 0,
                    position: 'relative',
                }}
            >
                <TicketsTopBarMobileView />
                <TabBox
                    sx={{
                        position: 'fixed',
                        top: '45px',
                        width: '100%',
                        zIndex: 2,
                        backgroundColor: 'background.primary',
                    }}
                >
                    <StyledTabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                    >
                        <StyledTab label="Inbox" value="inbox" />
                        <StyledTab label="Active" value="active" />
                        <StyledTab label="Expired" value="expired" />
                        <StyledTab label="Sent" value="sent" />
                    </StyledTabs>
                </TabBox>
                <Box>
                    {value === 'inbox' ? (
                        <Box></Box>
                    ) : value === 'active' ? (
                        <ActiveExpiredTicketView tabIndex={'active'} />
                    ) : value === 'expired' ? (
                        <ActiveExpiredTicketView tabIndex={'expired'} />
                    ) : (
                        value === 'sent' && <Box></Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default TicketsMobileView
