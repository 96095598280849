import React, { useState, useEffect } from 'react'
import TopBarView from './TopBar'
import { AppBar, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import LoginView from '../../views/AuthView/LoginView'
import CreateAccountView from '../../views/AuthView/CreateAccountView'
import { useAppState } from '../../AppStateProvider'
import SplashTopBarMobile from './TopBarMobile'
import SplashNavBarMobile from './SplashNavBarMobile'

const DivRoot = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
}))

interface SplashMobileLayoutProps {
    showLogin: boolean
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
}
const SplashMobileLayout = ({
    showLogin,
    setShowLogin,
}: SplashMobileLayoutProps) => {
    const { state } = useAppState()
    const [navBarOpen, setNavBarOpen] = useState(false)
    return (
        <DivRoot>
            <SplashTopBarMobile
                navBarOpen={navBarOpen}
                setNavBarOpen={setNavBarOpen}
            />
            <SplashNavBarMobile
                navBarOpen={navBarOpen}
                setNavBarOpen={setNavBarOpen}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
            <Outlet/>
        </DivRoot>
    )
}
const SplashLayout = () => {
    const { state } = useAppState()
    const [showLogin, setShowLogin] = useState(false)
    const [showRegister, setShowRegister] = useState(false)
    return (
        <div>
            <DivRoot>
                {state.isMobile ? (
                    <SplashMobileLayout
                        showLogin={showLogin}
                        setShowLogin={setShowLogin}
                    />
                ) : (
                    <>
                        <TopBarView
                            showLogin={showLogin}
                            setShowLogin={setShowLogin}
                            setShowRegister={setShowRegister}
                        />
                        <Container style={{ width: '100%', marginTop: 50 }}>
                            <Outlet />
                        </Container>
                    </>
                )}
            </DivRoot>
            {showLogin && (
                <div style={{ position: 'absolute' }}>
                    <LoginView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                    />
                </div>
            )}
            {showRegister && (
                <div style={{ position: 'absolute' }}>
                    <CreateAccountView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                    />
                </div>
            )}
        </div>
    )
}

export default SplashLayout
