import React, { useState, useEffect } from 'react'
import { Box, Typography, ClickAwayListener } from '@mui/material'
import {
    APIProvider,
    Map,
    Marker,
    AdvancedMarker,
    InfoWindow,
} from '@vis.gl/react-google-maps'
import { useAppState } from '../../../AppStateProvider'
import { Event } from '../../../models/Event'
import { styled } from '@mui/system'

import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import { Info } from '@mui/icons-material'
import { set } from 'date-fns'
import {
    OverlayView,
    OverlayViewF,
    GoogleMap,
    LoadScript,
    InfoBox,
    InfoBoxF,
} from '@react-google-maps/api'
import { googleMapsApiKey } from '../../../constants'
import DiscoveryMapEventsMobileView from './DiscoveryMapEventsMobileView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'

const StyledPersonOutlineRoundedIcon = styled(PersonRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledFavoriteRoundedIcon = styled(FavoriteRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const mapStyle = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
] as google.maps.MapTypeStyle[]

const EventMarker = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    borderRadius: '20px',
    padding: '0px 10px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
    alignContent: 'center',
    cursor: 'pointer',
}))

interface MapMobileViewProps {
    eventstate: Event[] | null
    showMapEvents: boolean
    setShowMapEvents: React.Dispatch<React.SetStateAction<boolean>>
}

const center = {
    lat: 43.64,
    lng: -79.41,
}

interface CustomMarkerProps {
    position: google.maps.LatLngLiteral
    eventdata: Event
    setSelectedMarker: (event: Event) => void
}

const CustomMarker = ({
    position,
    eventdata,
    setSelectedMarker,
}: CustomMarkerProps) => {
    return (
        <OverlayViewF
            position={position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <EventMarker onClick={() => setSelectedMarker(eventdata)}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {eventdata.favorited && (
                        <StyledFavoriteRoundedIcon style={{ fontSize: 16 }} />
                    )}

                    <StyledPersonOutlineRoundedIcon style={{ fontSize: 16 }} />
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.primary',
                        }}
                    >
                        {eventdata.attendee_count}
                    </Typography>
                </Box>
            </EventMarker>
        </OverlayViewF>
    )
}

const MapMobileView = React.memo(
    ({ eventstate, showMapEvents, setShowMapEvents }: MapMobileViewProps) => {
        const [selectedMarker, setSelectedMarker] = useState<Event | null>(null)
        const { state, setState } = useAppState()
        const handleSelectMarker = (event: Event) => {
            console.log('selected marker', event)
            setShowMapEvents(true)
            setSelectedMarker(event)
        }
        return (
            <Box
                sx={{
                    width: '100%',
                    height: `calc(100vh - ${navMobileBarHeight}px)`,
                    position: 'relative',
                    pb: '60px',
                    overflow: 'hidden',
                }}
            >
                <LoadScript googleMapsApiKey={googleMapsApiKey}>
                    <GoogleMap
                        mapContainerStyle={{
                            height: '100%',
                            width: '100%',
                        }}
                        center={{
                            lat: state.location.latitude
                                ? state.location.latitude
                                : 43.64,
                            lng: state.location.longitude
                                ? state.location.longitude
                                : -79.41,
                        }}
                        zoom={14}
                        options={{
                            disableDefaultUI: true,
                            mapTypeControl: false,
                            mapId: '7a9e2ebecd32a903',
                            clickableIcons: false,
                        }}
                    >
                        {/* {selectedMarker && (
                    <ClickAwayListener
                        onClickAway={() => setSelectedMarker(null)}
                    >
                        <InfoBox
                            position={
                                new google.maps.LatLng(
                                    selectedMarker.location.x,
                                    selectedMarker.location.y
                                )
                            }
                            options={{
                                closeBoxURL: ``,
                                enableEventPropagation: true,
                                pixelOffset: new google.maps.Size(-150, 30),
                            }}
                        >
                            <MapEventSubView event={selectedMarker} />
                        </InfoBox>
                    </ClickAwayListener>
                )} */}
                        <Marker
                            position={{
                                lat: state.location.latitude
                                    ? state.location.latitude
                                    : 43.64,
                                lng: state.location.longitude
                                    ? state.location.longitude
                                    : -79.41,
                            }}
                        />
                        {eventstate &&
                            eventstate.map((eventdata) => (
                                // <Marker
                                //     key={event.slug}
                                //     position={{
                                //         lat: event.location.x,
                                //         lng: event.location.y,
                                //     }}
                                // />
                                <CustomMarker
                                    eventdata={eventdata}
                                    position={{
                                        lat: eventdata.location.x,
                                        lng: eventdata.location.y,
                                    }}
                                    setSelectedMarker={handleSelectMarker}
                                />
                            ))}
                    </GoogleMap>
                </LoadScript>
                {eventstate && (
                    <DiscoveryMapEventsMobileView
                        eventState={eventstate}
                        selectedMarker={selectedMarker}
                        showMapEvents={showMapEvents}
                    />
                )}
            </Box>
        )
    }
)

export default MapMobileView
