import {
    AppBar,
    Box,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import { useAppState } from '../../../AppStateProvider'
import { createPost } from '../../../api/RestPost'
import { Event } from '../../../models/Event'
import { useNavigate } from 'react-router-dom'
import { DataStatus } from '../../../models/Dataloading'
import React from 'react'
import { navigateToPost } from '../../../utils/NavigationFunctions'
import { useApiManager } from '../../../api/ApiMangerProvider'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface CreatePostTopBarMobileViewProps {
    caption: string
    event: Event | null
    postImage: string | null
    submitLoading: DataStatus
    setSubmitLoading: React.Dispatch<React.SetStateAction<DataStatus>>
}

const CreatePostTopBarMobileView = ({
    caption,
    event,
    postImage,
    submitLoading,
    setSubmitLoading,
}: CreatePostTopBarMobileViewProps) => {
    const apiManager = useApiManager()
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const handleCreatePost = () => {
        if (postImage && event) {
            setSubmitLoading(DataStatus.loading)
            apiManager
                .createPost(caption, event.slug, [], postImage!)
                .then((response) => {
                    setSubmitLoading(DataStatus.success)
                    navigateToPost(
                        apiManager,
                        response.post,
                        null,
                        null,
                        navigate,
                        state,
                        setState
                    )
                })
                .catch((error) => {
                    setSubmitLoading(DataStatus.failure)
                    console.log('Error:', error)
                })
        }
    }
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color:
                            postImage && event
                                ? 'text.selected'
                                : 'text.disabled',
                    }}
                    onClick={handleCreatePost}
                >
                    Post
                </Typography>
            </Toolbar>
        </TopBar>
    )
}

export default CreatePostTopBarMobileView
