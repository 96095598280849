import {
    AppBar,
    Box,
    BoxProps,
    Button,
    IconButton,
    InputBase,
    Toolbar,
    Typography,
    styled,
} from '@mui/material'
import { CreateEvent } from '../../../../models/CreateEvent'
import {
    OutlineContainer,
    OutlineInputBase,
    PrimaryDivider,
    SelectButton,
    StyledLinearProgress,
} from '../../../Components/StyledComponents'
import { useAppState } from '../../../../AppStateProvider'
import { useState } from 'react'
import { navMobileBarHeight } from '../../../../layouts/DefaultLayout/NavMobileBar'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { LocationSearch } from '../../../../models/Location'
import {
    convertFormattedAddressToDictionary,
    getInfoFromCoords,
} from '../../../../utils/GoogleMapsFunc'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { getGoogleMapsAutocomplete } from '../../../../api/GoogleMapsAutocomplete'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'

const AddressDetailsContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    backgroundColor: theme.palette.background.secondary,
}))

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
}))
const Search = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    padding: '0px 10px',

    alignItems: 'center',
    display: 'flex',
    height: 35,
    fontFamily: 'Roboto',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
    },
}))

interface CreateEventEditLocationMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    setView: React.Dispatch<React.SetStateAction<string>>
}

interface CreateEventEditLocationTopBarMobileViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    locationSearchState: LocationSearch | null
    setView: React.Dispatch<React.SetStateAction<string>>
}

interface AddressContainerProps extends BoxProps {
    open: boolean
}

const StyledButtonBase = styled(Box)(({ theme }) => ({
    backgroundColor: 'transparent',
    borderRadius: 0,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

const AddressContainer = styled((props: AddressContainerProps) => (
    <Box {...props} />
))(({ theme, open }) => ({
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '20px 20px 0 0',
    ...(open
        ? {
              transition: theme.transitions.create('height', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
              }),
              height: 'calc(90% - 55px)',
          }
        : {
              transition: theme.transitions.create('height', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
              }),
              height: '380px',
          }),
    zIndex: 2,
    position: 'fixed',
    bottom: 0,
    left: 0,
}))
const CreateEventEditLocationTopBarMobileView = ({
    tempEventData,
    setTempEventData,
    locationSearchState,
    setView,
}: CreateEventEditLocationTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'relative',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() => setView('edit')}
                >
                    Back
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        width: '100%',
                        height: 55,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Location
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Toolbar>
        </TopBar>
    )
}

const CreateEventEditLocationMobileView = ({
    tempEventData,
    setTempEventData,
    setView,
}: CreateEventEditLocationMobileViewProps) => {
    const [Location, setLocation] = useState(tempEventData.location)
    const [isSearchingState, setIsSearchingState] = useState(true)
    const [locationSearchState, setLocationSearchState] =
        useState<LocationSearch | null>(null)
    const handleDragEnd = (e: any) => {
        getInfoFromCoords(e.latLng.lat(), e.latLng.lng())
            .then((data) => {
                console.log(data)
                setLocationSearchState(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <CreateEventEditLocationTopBarMobileView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
                locationSearchState={locationSearchState}
                setView={setView}
            />
            <LoadScript
                googleMapsApiKey={'AIzaSyCTm7sXwQNKtccUyry0RZty7qQFiFyCIZw'}
            >
                <GoogleMap
                    mapContainerStyle={{
                        height: 'calc(100vh - 55px)',
                        width: '100%',
                        position: 'absolute',
                        top: 0,

                        zIndex: 1,
                    }}
                    center={
                        locationSearchState
                            ? {
                                  lat: locationSearchState.location.lat,
                                  lng: locationSearchState.location.lng,
                              }
                            : {
                                  lat: 43.64,
                                  lng: -79.41,
                              }
                    }
                    zoom={14}
                    options={{
                        disableDefaultUI: true,
                        mapTypeControl: false,
                        mapId: '7a9e2ebecd32a903',
                        clickableIcons: false,
                    }}
                >
                    {locationSearchState && (
                        <Marker
                            position={{
                                lat: locationSearchState.location.lat,
                                lng: locationSearchState.location.lng,
                            }}
                            draggable={true}
                            onDragEnd={handleDragEnd}
                        />
                    )}
                </GoogleMap>
            </LoadScript>
            <CreateEventEditSearchLocationMobileView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
                locationSearchState={locationSearchState}
                setLocationSearchState={setLocationSearchState}
                isSearchingState={isSearchingState}
                setIsSearchingState={setIsSearchingState}
                setView={setView}
            />
        </Box>
    )
}

interface CreateEventEditSearchLocationMobileView {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    locationSearchState: LocationSearch | null
    setLocationSearchState: React.Dispatch<
        React.SetStateAction<LocationSearch | null>
    >
    isSearchingState: boolean
    setIsSearchingState: React.Dispatch<React.SetStateAction<boolean>>
    setView: React.Dispatch<React.SetStateAction<string>>
}
const CreateEventEditSearchLocationMobileView = ({
    tempEventData,
    setTempEventData,
    locationSearchState,
    setLocationSearchState,
    isSearchingState,
    setIsSearchingState,
    setView,
}: CreateEventEditSearchLocationMobileView) => {
    const [locationsearchresultstate, setLocationSearchResultState] = useState<
        LocationSearch[]
    >([])
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchState(event.target.value)
        getGoogleMapsAutocomplete(event.target.value).then((response) => {
            setLocationSearchResultState(response)
        })
    }
    const [searchState, setSearchState] = useState('')
    const handleConfirm = () => {
        if (locationSearchState) {
            const address = convertFormattedAddressToDictionary(
                locationSearchState.description
            )
            const location = {
                id: 0,
                x: locationSearchState.location.lat,
                y: locationSearchState.location.lng,
            }

            if (tempEventData.location !== location) {
                setTempEventData({
                    ...tempEventData,
                    location: location,
                    address: address,
                    locationsearch: locationSearchState,
                })
            }
            setView('edit')
        }
    }
    return (
        <AddressContainer open={isSearchingState}>
            {isSearchingState ? (
                <Box>
                    <Search
                        sx={{
                            m: '16px 16px',
                        }}
                    >
                        <PlaceRoundedIcon
                            sx={{
                                fontSize: 20,
                                paddingRight: '0px',
                                color: 'text.tertiary',
                            }}
                        />
                        <StyledInputBase
                            placeholder="Search Location"
                            onChange={handleInputChange}
                            value={searchState}
                        />
                    </Search>
                    <PrimaryDivider sx={{ mb: '8px' }} />
                    <Box
                        sx={{
                            height: 'calc(100% - 100px)',
                            maxHeight: 'calc(100% - 100px)',
                            overflowY: 'auto',
                        }}
                    >
                        {locationsearchresultstate.map(
                            (locationsearchresult) => (
                                <StyledButtonBase
                                    sx={{
                                        display: 'flex',
                                        p: '0px 16px',
                                        mt: '8px',
                                    }}
                                    onClick={(event) => {
                                        setLocationSearchState({
                                            description:
                                                locationsearchresult.description,
                                            maintext:
                                                locationsearchresult.maintext,
                                            secondarytext:
                                                locationsearchresult.secondarytext,
                                            place_id:
                                                locationsearchresult.place_id,
                                            location:
                                                locationsearchresult.location,
                                        })
                                        setIsSearchingState(false)
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            sx={{
                                                color: 'text.tertiary',
                                                padding: '8px',
                                                backgroundColor:
                                                    'background.tertiary',
                                                borderRadius: '50%',
                                                float: 'left',
                                                mr: '16px',
                                                mb: 'auto',
                                            }}
                                        >
                                            <PlaceRoundedIcon
                                                sx={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'text.primary',
                                                fontSize: 14,
                                                width: '100%',
                                            }}
                                        >
                                            {locationsearchresult.maintext}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'text.secondary',
                                                fontSize: 12,
                                                alignItems: 'left',
                                                width: '100%',
                                            }}
                                        >
                                            {locationsearchresult.secondarytext}
                                        </Typography>
                                        <PrimaryDivider
                                            sx={{
                                                m: '8px 0px',
                                            }}
                                        />
                                    </Box>
                                </StyledButtonBase>
                            )
                        )}
                    </Box>
                </Box>
            ) : (
                <Box>
                    {locationSearchState && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: '16px 16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {locationSearchState.maintext}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <IconButton
                                    onClick={() => setIsSearchingState(true)}
                                    sx={{
                                        color: 'text.secondary',
                                        padding: '8px',
                                        backgroundColor: 'background.tertiary',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <ClearRoundedIcon
                                        sx={{
                                            fontSize: 20,
                                        }}
                                    />
                                </IconButton>
                            </Box>
                            <PrimaryDivider />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Details
                                </Typography>
                                <AddressDetailsContainer
                                    sx={{
                                        p: '8px',
                                        mt: '8px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Address
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'text.primary',
                                            fontSize: 14,
                                            mt: '0px',
                                        }}
                                    >
                                        {locationSearchState.description}
                                    </Typography>
                                    <PrimaryDivider
                                        sx={{
                                            m: '8px 0px',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Coordinates
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'text.primary',
                                            fontSize: 14,
                                            mt: '0px',
                                        }}
                                    >
                                        {locationSearchState.location.lat} N,{' '}
                                        {locationSearchState.location.lng} W
                                    </Typography>
                                </AddressDetailsContainer>
                                <SelectButton
                                    sx={{
                                        mt: '16px',
                                        fontSize: 16,
                                    }}
                                    fullWidth
                                    onClick={handleConfirm}
                                >
                                    Select Location
                                </SelectButton>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </AddressContainer>
    )
}

export default CreateEventEditLocationMobileView
