import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Drawer, DrawerProps, IconButton, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    PrimaryTypography,
    SecondaryTypography,
    PrimaryDivider,
    EmptyImageBox,
    StyledTextField,
    ClearIconButton,
} from '../../Components/StyledComponents'
import { CreateEvent } from '../../../models/CreateEvent'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'
import { hexToRGBA } from '../../../utils/haxToRGBA'

const StyledCapsule = styled(Box)(({ theme }) => ({
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    borderRadius: 10,
    padding: '4px 12px',
    margin: '4px',
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
}))

interface CreateAccountDetailsViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
}

const StyledCameraAltRoundedIcon = styled(CameraAltRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.secondary,
    })
)

const CreateEventPhotoView = ({
    tempEventData,
    setTempEventData,
}: CreateAccountDetailsViewProps) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setTempEventData((prevData) => ({
                ...prevData,
                image: reader.result as string,
            }))
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })
    const removeImage = () => {
        setTempEventData((prevData) => ({
            ...prevData,
            image: '',
        }))
    }
    return (
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
            <EmptyImageBox
                {...getRootProps()}
                style={{
                    width: '100%',
                    height: '200px',
                    marginTop: '10px',
                    cursor: 'pointer',
                    borderRadius: 10,
                }}
            >
                <input {...getInputProps()} />
                {tempEventData.image !== '' ? (
                    <img
                        src={tempEventData.image}
                        alt="event"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: 10,
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <StyledCameraAltRoundedIcon sx={{ fontSize: 30 }} />
                    </Box>
                )}
            </EmptyImageBox>
            {tempEventData.image !== '' ? (
                <ClearIconButton
                    onClick={removeImage}
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        cursor: 'pointer',
                        padding: '5px',
                    }}
                >
                    <ClearRoundedIcon />
                </ClearIconButton>
            ) : (
                <StyledCapsule
                    sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        cursor: 'pointer',
                    }}
                >
                    Add Photo
                </StyledCapsule>
            )}
        </Grid>
    )
}

export default CreateEventPhotoView
