import React, { useEffect, useState } from 'react'
import { getPosts } from '../../api/RestPost'
import { useAppState } from '../../AppStateProvider'
import { Post } from '../../models/Post'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import PostView from '../PostView/PostView'
import {
    CircularProgress,
    Box,
    Grid,
    Divider,
    List,
    ListItem,
    Button,
    ButtonBase,
    Typography,
} from '@mui/material'
import { NavigateFeedState } from '../../models/NavigateState'
import {
    PrimaryDivider,
    PrimaryTypography,
    ScrollBarDiv,
    SecondaryDivider,
    SecondaryTypography,
    StyledChip,
    TertiaryTypography,
} from '../Components/StyledComponents'
import FeedSideView from './FeedSideView'
import SortPopper from '../Components/SortPopper'
import { StyledArrowForwardIosRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import FeedMobileView from './FeedMobileView.tsx'

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.border.primary,
}))

const FeedView = () => {
    const { state, setState } = useAppState()
    return <>{state.isMobile ? <FeedMobileView /> : <FeedDesktopView />}</>
}

const FeedDesktopView = React.memo(() => {
    const [offset, setOffset] = useState(0)
    const location = useLocation()
    const initialpoststateData = location.state as NavigateFeedState | null
    const { state, setState } = useAppState()
    const [poststate, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)

    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)

    // Sets the anchor element for the sort popper and displays the popper
    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }

    // Fetches posts from the server
    useEffect(() => {
        if (offset === 0 && initialpoststateData !== null) {
            setPostLoaded(DataStatus.success)
            setPostState(initialpoststateData.poststate)
        } else {
            try {
                getPosts(null, null, null, 15, offset, state.usertoken)
                    .then((response) => {
                        setPostState((prevState) => [
                            ...prevState,
                            ...response.posts,
                        ])
                        setPostLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setPostLoaded(DataStatus.failure)
                        console.log(error)
                    })
            } catch (error) {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            }
        }
    }, [location.pathname])
    return (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            <ScrollBarDiv
                style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    justifyContent: 'center',
                    display: 'flex',
                    float: 'left',
                }}
            >
                <Box
                    sx={{
                        maxWidth: 1300,
                        marginRight: '0',
                        height: '100%',
                        marginTop: '70px',
                        width: '100%',
                        marginBottom: '50px',
                        zIndex: 0,
                        position: 'relative',
                        padding: '0px 20px',
                    }}
                >
                    <Box
                        sx={{
                            width: 'calc(100% - 420px)',
                            height: '100%',
                            float: 'left',
                        }}
                    >
                        {postloaded === DataStatus.loading ? (
                            <Box
                                sx={{
                                    marginTop: '16px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <StyledCircularProgress />
                            </Box>
                        ) : (
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 22,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    For You
                                </Typography>
                                <SecondaryDivider sx={{ margin: '8px 0px' }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        margin: '8px 0px',
                                        alignItems: 'center',
                                        ml: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'text.tertiary',
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Sort by:
                                    </Typography>
                                    <SortPopper
                                        sortOptions={['Best', 'New', 'Top']}
                                        sortbyState={sortbyState}
                                        setSortByState={setSortByState}
                                        openpopover={sortbyShowState}
                                        setOpenPopover={setSortbyShowState}
                                        anchorEl={sortAnchorEl}
                                        setAnchorEl={setSortAnchorEl}
                                    />
                                    <StyledChip
                                        sx={{
                                            ml: '4px',
                                            cursor: 'pointer',
                                            background: (theme) =>
                                                theme.palette.background
                                                    .tertiary,
                                        }}
                                        component={ButtonBase}
                                        onClick={handleSortPopperOpen}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontWeight: 'bold',
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {sortbyState}
                                        </Typography>
                                        <StyledArrowForwardIosRoundedIcon
                                            sx={{ fontSize: 13, ml: '4px' }}
                                            open={sortbyShowState}
                                        />
                                    </StyledChip>
                                </Box>
                                {poststate.map((post) => (
                                    <Box>
                                        <PostView post={post} />
                                        <StyledDivider />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '400px',
                            height: '100%',
                            justifyContent: 'center',
                            paddingLeft: '20px',
                            float: 'right',
                        }}
                    >
                        <FeedSideView />
                    </Box>
                </Box>
            </ScrollBarDiv>
        </div>
    )
})

export default FeedView
