import { useAppState } from "../../AppStateProvider"
import AboutMobileView from "./SplashMobileView/About"


const AboutView = () => {
    const {state} = useAppState()
    return(
        <>
        {state.isMobile && <AboutMobileView/>}
        </>
    )
}

export default AboutView