import { Box, Button, styled, Typography } from '@mui/material'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import {
    StyledCircularProgress,
    StyledIconButton,
} from '../../Components/StyledComponents'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded'
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'

const BottomBar = styled(Box)(({ theme }) => ({
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `100%`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    borderTop: '1px solid ' + theme.palette.border.secondary,
    // boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.8)',
}))

const PostConfigButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '10px',
    padding: '4px 16px',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.9)',
}))

interface CreatePostBottomBarMobileViewProps {
    setPostImage: React.Dispatch<React.SetStateAction<string | null>>
    caption: string
}

const CreatePostBottomBarMobileView = ({
    setPostImage,
    caption,
}: CreatePostBottomBarMobileViewProps) => {
    const triggerFileSelect = () => {
        document.getElementById('fileInput')?.click()
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                setPostImage(reader.result as string)
            }

            reader.readAsDataURL(file)
        }
    }
    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: `${navMobileBarHeight + 40 + 10}px`,
                    display: 'flex',
                    alignItems: 'center',
                    p: '8px 8px',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <PostConfigButton>Public: Viewable to anyone</PostConfigButton>
            </Box>
            <BottomBar
                sx={{
                    overflow: 'hidden',
                    bottom: 0,
                    p: '0px',
                }}
            >
                <Box
                    sx={{
                        p: '8px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        mb: `${navMobileBarHeight - 10}px`,
                        height: `40px`,
                    }}
                >
                    <StyledIconButton
                        sx={{
                            float: 'left',
                            height: '35px',
                        }}
                        onClick={triggerFileSelect}
                    >
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <InsertPhotoRoundedIcon
                            sx={{ fontSize: 20, color: 'text.primary' }}
                        />
                    </StyledIconButton>
                    <StyledIconButton
                        sx={{
                            ml: '8px',
                            float: 'left',
                            height: '35px',
                        }}
                    >
                        <VideocamRoundedIcon
                            sx={{ fontSize: 24, color: 'text.primary' }}
                        />
                    </StyledIconButton>
                    <StyledIconButton
                        sx={{
                            ml: '8px',
                            float: 'left',
                            height: '35px',
                        }}
                    >
                        <InsertLinkRoundedIcon
                            sx={{ fontSize: 24, color: 'text.primary' }}
                        />
                    </StyledIconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                        sx={{
                            color:
                                caption.length > 300 ? 'red' : 'text.primary',
                            fontSize: 14,
                            fontWeight: 'bold',
                            alignSelf: 'center',
                            marginRight: '8px',
                        }}
                    >
                        {caption.length}/300
                    </Typography>
                    <Box
                        sx={{
                            width: '35px',
                            height: '35px',
                            position: 'relative',
                        }}
                    >
                        <StyledCircularProgress
                            variant="determinate"
                            value={100}
                            size={25}
                            thickness={5}
                            sx={{
                                color: 'background.quaternary',
                                '& .MuiCircularProgress-determinate': {
                                    stroke: 'text.disabled',
                                    color: 'text.disabled',
                                    backgroundColor: 'text.disabled',
                                },
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 0,
                                width: '30px',
                                height: '30px',
                                p: '5px',
                            }}
                        />
                        <StyledCircularProgress
                            variant="determinate"
                            value={Math.min((caption.length / 300) * 100, 100)}
                            size={25}
                            thickness={5}
                            sx={{
                                color:
                                    caption.length > 300
                                        ? 'red'
                                        : 'text.selected',
                                '& .MuiCircularProgress-determinate': {
                                    stroke: 'text.disabled',
                                    color: 'text.disabled',
                                    backgroundColor: 'text.disabled',
                                },
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                p: '5px',
                                zIndex: 1,
                            }}
                        />
                    </Box>
                </Box>
            </BottomBar>
        </>
    )
}

export default CreatePostBottomBarMobileView
