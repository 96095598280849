import React, { useState, useContext, useEffect } from 'react'
import { AppStateContext, defaultAppState, AppState } from './models/AppState'

interface AppStateProviderProps {
    children: React.ReactNode
}

export const AppStateProvider: React.FC<AppStateProviderProps> = ({
    children,
}) => {
    const [appState, setAppState] = useState<AppState>(() => {
        var localState = defaultAppState
        localState['usertoken'] = localStorage.getItem('usertoken') || ''
        localState['username'] = localStorage.getItem('username') || ''
        localState['useremail'] = localStorage.getItem('useremail') || ''
        localState['userpassword'] = localStorage.getItem('userpassword') || ''
        localState['name'] = localStorage.getItem('name') || ''
        localState['userimagelink'] =
            localStorage.getItem('userimagelink') || ''
        localState['userimage'] = localStorage.getItem('userimage') || null
        return localState
    })
    useEffect(() => {
        localStorage.setItem('appState', JSON.stringify(appState))
    }, [appState])
    return (
        <AppStateContext.Provider
            value={{ state: appState, setState: setAppState }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

export const useAppState = () => {
    const context = useContext(AppStateContext)

    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider')
    }
    return context
}
