import { Box, ButtonBase, styled, Toolbar } from '@mui/material'
import { EventType, FilterData, FilterDateChoice } from '../../../models/Filter'
import { LocationSearch } from '../../../models/Location'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import LocationSearchBox from '../../Components/LocationSearchBox'
import LocationSearchDiscoverBoxMobileView from './LocationSearchDiscoverBoxMobileView'
const FilterCapsule = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 20px',
    fontSize: 14,
    height: 18,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
}))

const SelectedFilterCapsule = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 20px',
    fontSize: 14,
    height: 18,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
}))

const HorizontalScrollDiv = styled(Box)(({ theme }) => ({
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}))

interface FilterTopBarMobileViewProps {
    locationSearchState: LocationSearch | null
    setLocationSearchState: React.Dispatch<
        React.SetStateAction<LocationSearch | null>
    >
    filterState: FilterData
    setFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    setShowSearchLocation: React.Dispatch<React.SetStateAction<boolean>>
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
    maxDistance: number
    maxAttendees: number
}

const FilterTopBarMobileView = ({
    locationSearchState,
    setLocationSearchState,
    filterState,
    setFilterState,
    setShowSearchLocation,
    setShowFilter,
    maxDistance,
    maxAttendees,
}: FilterTopBarMobileViewProps) => {
    return (
        <HorizontalScrollDiv
            sx={{
                width: '100%',
                height: '50px',
                alignContent: 'center',
            }}
        >
            {' '}
            <Toolbar
                style={{
                    minHeight: 30,
                    padding: '0px 10px',
                    width: '100%',
                }}
            >
                <LocationSearchDiscoverBoxMobileView
                    locationSearchState={locationSearchState}
                    setLocationSearchState={setLocationSearchState}
                    setShowSearchLocation={setShowSearchLocation}
                />
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    <FilterCapsule
                        sx={{ marginLeft: '8px', marginRight: '4px' }}
                    >
                        <TuneRoundedIcon
                            sx={{
                                fontSize: 20,
                                marginRight: '4px',
                                color: 'text.primary',
                            }}
                        />
                        Filters
                    </FilterCapsule>
                </ButtonBase>
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    {filterState.date.choice === FilterDateChoice.Any ? (
                        <FilterCapsule
                            sx={{ marginLeft: '4px', marginRight: '4px' }}
                        >
                            Date
                        </FilterCapsule>
                    ) : (
                        <SelectedFilterCapsule
                            sx={{ marginLeft: '4px', marginRight: '4px' }}
                        >
                            {filterState.date.choice !== FilterDateChoice.Custom
                                ? filterState.date.choice.toString()
                                : `${filterState.date.dates[0].toDateString()} to ${filterState.date.dates[1].toDateString()}`}
                        </SelectedFilterCapsule>
                    )}
                </ButtonBase>
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    {filterState.range === maxDistance ? (
                        <FilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            Distance
                        </FilterCapsule>
                    ) : (
                        <SelectedFilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            {`${filterState.range} miles away`}
                        </SelectedFilterCapsule>
                    )}
                </ButtonBase>
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    {filterState.attendeeCount[0] === 0 &&
                    filterState.attendeeCount[1] === maxAttendees ? (
                        <FilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            Attendee count
                        </FilterCapsule>
                    ) : (
                        <SelectedFilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            {filterState.attendeeCount[0] > 0 &&
                            filterState.attendeeCount[1] === maxAttendees
                                ? `At least ${filterState.attendeeCount[0]} attendees`
                                : filterState.attendeeCount[0] === 0 &&
                                    filterState.attendeeCount[1] < maxAttendees
                                  ? `Up to ${filterState.attendeeCount[1]} attendees`
                                  : `${filterState.attendeeCount[0]} to ${filterState.attendeeCount[1]} attendees`}
                        </SelectedFilterCapsule>
                    )}
                </ButtonBase>
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    {filterState.premium === EventType.None ? (
                        <FilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            Event type
                        </FilterCapsule>
                    ) : (
                        <SelectedFilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            {filterState.premium.toString()}
                        </SelectedFilterCapsule>
                    )}
                </ButtonBase>
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    {!filterState.following ? (
                        <FilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            Following attending
                        </FilterCapsule>
                    ) : (
                        <SelectedFilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            Following
                        </SelectedFilterCapsule>
                    )}
                </ButtonBase>
                <ButtonBase
                    sx={{ borderRadius: 50 }}
                    onClick={() => setShowFilter(true)}
                >
                    {filterState.category.length === 0 ? (
                        <FilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            Categories
                        </FilterCapsule>
                    ) : (
                        <SelectedFilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            {`${filterState.category[0].toString()} +${filterState.category.length}`}
                        </SelectedFilterCapsule>
                    )}
                </ButtonBase>
            </Toolbar>
        </HorizontalScrollDiv>
    )
}

export default FilterTopBarMobileView
