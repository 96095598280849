import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useState } from 'react'
import { Box, IconButton, InputBase, Typography } from '@mui/material'
import { styled } from '@mui/system'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { getEventsText } from '../../../api/RestEvent'
import { DataStatus } from '../../../models/Dataloading'
import { Event } from '../../../models/Event'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import EventSubMobileView from '../../EventView/EventMobileView/EventSubMobileView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { LocationSearch } from '../../../models/Location'
import { getGoogleMapsAutocomplete } from '../../../api/GoogleMapsAutocomplete'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
    },
}))

const SearchContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: theme.palette.background.tertiary,
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
    alignItems: 'center',
    display: 'flex',
    minHeight: 35,
    marginRight: 16,
}))

interface DiscoverSearchLocationMobileViewProps {
    showSearchLocation: boolean
    setShowSearchLocation: React.Dispatch<React.SetStateAction<boolean>>
    setLocationSearchState: React.Dispatch<
        React.SetStateAction<LocationSearch | null>
    >
}

const DiscoverSearchLocationMobileView = ({
    showSearchLocation,
    setShowSearchLocation,
    setLocationSearchState,
}: DiscoverSearchLocationMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [searchInput, setSearchInput] = useState('')
    const [searchStaus, setSearchStatus] = useState<DataStatus>(DataStatus.none)
    const [locationsearchresultstate, setLocationSearchResultState] = useState<
        LocationSearch[]
    >([])
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value)
        getGoogleMapsAutocomplete(event.target.value)
            .then((response) => {
                setLocationSearchResultState(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleClear = () => {
        setSearchInput('')
        setLocationSearchResultState([])
    }
    return (
        <Box
            sx={{
                pb: `${navMobileBarHeight}px`,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '0px 16px',
                    height: '55px',
                }}
            >
                <SearchContainer>
                    <SearchRoundedIcon
                        sx={{
                            color: 'text.disabled',
                            pl: '4px',
                            fontSize: '20px',
                        }}
                    />
                    <StyledInputBase
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleInputChange}
                    />
                    {searchInput.length > 0 && (
                        <CancelRoundedIcon
                            sx={{
                                color: 'text.disabled',
                                position: 'absolute',
                                right: '8px',
                                cursor: 'pointer',
                                fontSize: '20px',
                            }}
                            onClick={handleClear}
                        />
                    )}
                </SearchContainer>
                <Typography
                    sx={{
                        fontSize: '14px',
                        color: 'text.primary',
                        fontWeight: 'bold',
                    }}
                    onClick={() => setShowSearchLocation(false)}
                >
                    Cancel
                </Typography>
            </Box>
            <PrimaryDivider />
            <Box>
                {locationsearchresultstate.map((locationsearchresult) => (
                    <Box
                        sx={{
                            display: 'flex',
                            p: '0px 16px',
                            mt: '8px',
                        }}
                        onClick={(event) => {
                            setLocationSearchState({
                                description: locationsearchresult.description,
                                maintext: locationsearchresult.maintext,
                                secondarytext:
                                    locationsearchresult.secondarytext,
                                place_id: locationsearchresult.place_id,
                                location: locationsearchresult.location,
                            })
                            setShowSearchLocation(false)
                        }}
                    >
                        <Box>
                            <IconButton
                                sx={{
                                    color: 'text.tertiary',
                                    padding: '8px',
                                    backgroundColor: 'background.tertiary',
                                    borderRadius: '50%',
                                    float: 'left',
                                    mr: '16px',
                                    mb: 'auto',
                                }}
                            >
                                <PlaceRoundedIcon
                                    sx={{
                                        fontSize: 20,
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: 14,
                                    width: '100%',
                                }}
                            >
                                {locationsearchresult.maintext}
                            </Typography>
                            <Typography
                                sx={{
                                    color: 'text.secondary',
                                    fontSize: 12,
                                    alignItems: 'left',
                                    width: '100%',
                                }}
                            >
                                {locationsearchresult.secondarytext}
                            </Typography>
                            <PrimaryDivider
                                sx={{
                                    m: '8px 0px',
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default DiscoverSearchLocationMobileView
