import { Box, BoxProps, styled } from '@mui/material'
import { Event } from '../../../models/Event'
import { useEffect, useRef } from 'react'
import EventSubMobileView from '../../EventView/EventMobileView/EventSubMobileView'

interface EventContainerProps extends BoxProps {
    showMapEvents: boolean
}
const EventContainer = styled((props: EventContainerProps) => (
    <Box {...props} />
))(({ theme, showMapEvents }) => ({
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    scrollSnapType: 'x mandatory',
    position: 'fixed',
    gap: '16px',
    width: '100%',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    bottom: showMapEvents ? '72px' : '-300px',
    transition: theme.transitions.create('bottom', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
}))
interface DiscoveryMapEventsMobileViewProps {
    eventState: Event[]
    selectedMarker: Event | null
    showMapEvents: boolean
}
const DiscoveryMapEventsMobileView = ({
    eventState,
    selectedMarker,
    showMapEvents,
}: DiscoveryMapEventsMobileViewProps) => {
    const eventsRef = useRef<{ [key: string]: HTMLElement | null }>({})
    const scrollContainerRef = useRef<HTMLElement | null>(null)
    useEffect(() => {
        if (selectedMarker) {
            if (eventsRef.current[selectedMarker.slug]) {
                const targetDiv = eventsRef.current[
                    selectedMarker.slug
                ] as HTMLElement
                const xPosition =
                    targetDiv.getBoundingClientRect().left + window.scrollX - 8
            }
        }
    }, [selectedMarker, scrollContainerRef])
    return (
        <EventContainer
            showMapEvents={showMapEvents}
            ref={(el: HTMLElement) => (scrollContainerRef.current = el)}
        >
            {eventState.map((event, index) => (
                <Box
                    sx={{
                        border: '1px solid ' + 'border.primary',
                        m: '0px 8px',
                        minWidth: 'calc(100vw - 16px)',
                        scrollSnapAlign: 'center',
                        

                        p: '0px 0px',
                    }}
                    ref={(el: HTMLElement) =>
                        (eventsRef.current[event.slug] = el)
                    }
                >
                    <Box
                        sx={{
                            mb: 'auto',
                        }}
                    >
                        <Box
                        sx={{
                            backgroundColor: 'background.secondary',
                            borderRadius: '10px',
                        }}>
                    <EventSubMobileView event={event} />
                    </Box>
                    </Box>
                </Box>
            ))}
        </EventContainer>
    )
}

export default DiscoveryMapEventsMobileView
