import axios from 'axios'
import { mainUrl } from '../constants'
import { LoginRequestData, LoginResponse, LoginResponseData } from './Auth'
import {
    createEventRequest,
    EventReply,
    getEvent,
    getEvents,
    getEventsReply,
} from './RestEvent'
import {
    convertBase64toBlob,
    convertBlobToBase64,
} from '../utils/Base64ImageEncoding'
import { CreateEvent } from '../models/CreateEvent'
import { UserReply } from './RestUser'
import { GetPostReply, GetPostsReply, UpdatePostRequest } from './RestPost'
import { getTicketsReply } from './RestTicket'
import { CommentReply, GetCommentsReply } from './RestComment'

class ApiManger {
    private lastAuthTime: number
    private controllers: Map<string, AbortController>
    private token: string | null
    private email: string | null
    private password: string | null

    constructor(
        lastAuthTime: number | null,
        userTokan: string | null,
        email: string | null,
        password: string | null
    ) {
        this.lastAuthTime = lastAuthTime ? lastAuthTime : 0
        this.token = userTokan
        this.controllers = new Map()
        this.email = email
        this.password = password
    }

    private async reauthenticate() {
        if (
            (Math.floor(Date.now() - this.lastAuthTime) / (1000 * 60) > 15 ||
                !this.token) &&
            this.email &&
            this.password
        ) {
            await this.login(this.email, this.password)
                .then((response) => {
                    this.token = response.user.token
                    localStorage.setItem('usertoken', this.token)
                    localStorage.setItem('lastauthtime', Date.now().toString())
                    this.lastAuthTime = Date.now()
                    return
                })
                .catch((error) => {
                    console.log(error)
                    return
                })
        }
    }

    async login(email: string, password: string): Promise<LoginResponseData> {
        const contoller = new AbortController()
        this.controllers.set('login', contoller)
        const params: LoginRequestData = {
            user: {
                email: email,
                password: password,
            },
        }
        try {
            const response = await axios.post<LoginResponseData>(
                `${mainUrl}users/login`,
                params,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    signal: contoller.signal,
                }
            )
            this.email = email
            this.password = password
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            }
            throw new Error('An unexpected error occurred')
        } finally {
            this.controllers.delete('login')
        }
    }

    cancelAllRequests() {
        this.controllers.forEach((controller) => {
            controller.abort()
        })
        this.controllers.clear()
    }

    cancelRequest(key: string) {
        if (this.controllers.has(key)) {
            this.controllers.get(key)!.abort()
            this.controllers.delete(key)
        }
    }

    async getEvents(
        host: string | null,
        attending: string | null,
        tags: string | null,
        radius: number,
        limit: number,
        offset: number
    ): Promise<getEventsReply> {
        console.log('getEvents')
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getEvents', controller)
        try {
            const params: Record<string, any> = {
                radius,
                limit,
                offset,
            }
            if (host) {
                params.host = host
            }
            if (attending) {
                params.attending = attending
            }
            if (tags) {
                params.tags = tags
            }
            const response = await axios.get<getEventsReply>(
                `${mainUrl}events`,
                {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getEvents')
        }
    }

    async getEvent(slug: string): Promise<EventReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getEvent', controller)
        try {
            const response = await axios.get<EventReply>(
                `${mainUrl}events/${slug}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getEvent')
        }
    }

    async getEventsText(
        search_string: string,
        limit: number,
        offset: number
    ): Promise<getEventsReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getEventsText', controller)
        try {
            const params: Record<string, any> = {
                limit,
                offset,
            }
            const response = await axios.get<getEventsReply>(
                `${mainUrl}events/search/${search_string}`,
                {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getEventsText')
        }
    }

    async grabEventPhoto(imagelink: string): Promise<string> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('grabEventPhoto', controller)
        try {
            const response = await fetch(
                `${mainUrl}event/image?image=${imagelink}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            if (!response.ok) {
                throw new Error('Failed to fetch data')
            }

            const blob = await response.blob()
            const image = await convertBlobToBase64(blob)
            return image
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('grabEventPhoto')
        }
    }

    async favoriteEvent(slug: string): Promise<EventReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('favoriteEvent', controller)
        try {
            const response = await axios.post<EventReply>(
                `${mainUrl}event/${slug}/favorite`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('favoriteEvent')
        }
    }

    async unfavoriteEvent(slug: string): Promise<EventReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('unfavoriteEvent', controller)
        try {
            const response = await axios.delete<EventReply>(
                `${mainUrl}event/${slug}/favorite`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('unfavoriteEvent')
        }
    }

    async createEvent(tempEventData: CreateEvent): Promise<EventReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('createEvent', controller)
        try {
            const requestData: createEventRequest = {
                event: {
                    title: tempEventData.title,
                    description: tempEventData.description,
                    body: '',
                    tagList: tempEventData.tag_list,
                    address: tempEventData.address!,
                    location: tempEventData.location!,
                    private: tempEventData.private,
                    event_time: tempEventData.event_time,
                    end_time: tempEventData.end_time,
                    attendee_limit: tempEventData.attendee_limit,
                },
            }
            const blob = convertBase64toBlob(tempEventData.image)
            const formData = new FormData()
            formData.append('image', blob, 'image.jpeg')

            const response = await axios.post<EventReply>(
                `${mainUrl}events`,
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('createEvent')
        }
    }

    async getUser(username: string): Promise<UserReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getUser', controller)
        try {
            const response = await axios.get<UserReply>(
                `${mainUrl}profiles/${username}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getUser')
        }
    }

    async grabUserPhoto(imagelink: string): Promise<string> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('grabUserPhoto', controller)
        try {
            const response = await fetch(
                `${mainUrl}user/image?image=${imagelink}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            if (!response.ok) {
                throw new Error('Failed to fetch data')
            }

            const blob = await response.blob()
            const image = await convertBlobToBase64(blob)
            return image
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('grabUserPhoto')
        }
    }

    async getUsersSearch(
        search_string: string,
        limit: number,
        offset: number
    ): Promise<UserReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getUsersSearch', controller)
        try {
            const params: Record<string, any> = {
                limit,
                offset,
            }
            const response = await axios.get<UserReply>(
                `${mainUrl}profiles/search/${search_string}`,
                {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getUsersSearch')
        }
    }

    async followUser(username: string): Promise<UserReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('followUser', controller)
        try {
            const response = await axios.post<UserReply>(
                `${mainUrl}profiles/${username}/follow`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('followUser')
        }
    }

    async unfollowUser(username: string): Promise<UserReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('unfollowUser', controller)
        try {
            const response = await axios.delete<UserReply>(
                `${mainUrl}profiles/${username}/follow`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('unfollowUser')
        }
    }

    async getFollowers(
        username: string,
        limit: number,
        offset: number
    ): Promise<UserReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getFollowers', controller)
        try {
            const params: Record<string, any> = {
                limit,
                offset,
            }
            const response = await axios.get<UserReply>(
                `${mainUrl}profiles/${username}/followers`,
                {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getFollowers')
        }
    }

    async getFollowing(
        username: string,
        limit: number,
        offset: number
    ): Promise<UserReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getFollowing', controller)
        try {
            const params: Record<string, any> = {
                limit,
                offset,
            }
            const response = await axios.get<UserReply>(
                `${mainUrl}profiles/${username}/following`,
                {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getFollowing')
        }
    }

    async getPosts(
        author: string | null,
        favorited: string | null,
        event_slug: string | null,
        limit: number,
        offset: number
    ): Promise<GetPostsReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getPosts', controller)
        try {
            const params: Record<string, any> = {
                limit,
                offset,
            }
            if (author) {
                params.author = author
            }
            if (favorited) {
                params.favorited = favorited
            }
            if (event_slug) {
                params.event_slug = event_slug
            }

            const response = await axios.get<GetPostsReply>(`${mainUrl}posts`, {
                params: params,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
                signal: controller.signal,
            })
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getPosts')
        }
    }

    async getPostbyId(id: string): Promise<GetPostReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getPostbyId', controller)
        try {
            const response = await axios.get<GetPostReply>(
                `${mainUrl}posts/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getPostbyId')
        }
    }

    async grabPostPhoto(imagelink: string): Promise<string> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('grabPostPhoto', controller)
        try {
            const response = await fetch(
                `${mainUrl}post/image?image=${imagelink}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            if (!response.ok) {
                throw new Error('Failed to fetch data')
            }

            const blob = await response.blob()
            const image = await convertBlobToBase64(blob)
            return image
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('grabPostPhoto')
        }
    }

    async favoritePost(id: string): Promise<GetPostReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('favoritePost', controller)
        try {
            const response = await axios.post<GetPostReply>(
                `${mainUrl}posts/${id}/favorite`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('favoritePost')
        }
    }

    async unfavoritePost(id: string): Promise<GetPostReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('unfavoritePost', controller)
        try {
            const response = await axios.delete<GetPostReply>(
                `${mainUrl}posts/${id}/favorite`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('unfavoritePost')
        }
    }

    async createPost(
        caption: string,
        event_slug: string,
        tags: string[],
        postImage: string | null
    ): Promise<GetPostReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('createPost', controller)
        try {
            const formData = new FormData()
            const params = new URLSearchParams()
            if (postImage) {
                const blob = convertBase64toBlob(postImage)
                formData.append('image', blob, 'image.jpeg')
            }
            params.append('caption', caption)
            params.append('event_slug', event_slug)
            params.append('tags', JSON.stringify(tags))

            const response = await axios.post<GetPostReply>(
                `${mainUrl}posts`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: params,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('createPost')
        }
    }

    async deletePost(id: string): Promise<GetPostReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('deletePost', controller)
        try {
            const response = await axios.delete<GetPostReply>(
                `${mainUrl}posts/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('deletePost')
        }
    }

    async updatePost(
        id: string,
        caption: string,
        tags: string[]
    ): Promise<GetPostReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('updatePost', controller)
        try {
            const params: UpdatePostRequest = {
                update_data: {
                    caption,
                },
            }
            const response = await axios.put<GetPostReply>(
                `${mainUrl}posts/${id}`,
                params,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(
                    error.response?.data || 'An unexpected error occurred'
                )
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('updatePost')
        }
    }

    async getTickets(
        state: string | null,
        slug: string | null,
        limit: number,
        offset: number
    ): Promise<getTicketsReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getTickets', controller)
        try {
            const params: Record<string, any> = {
                limit,
                offset,
            }
            if (state) {
                params.state = state
            }
            if (slug) {
                params.slug = slug
            }
            const response = await axios.get<getTicketsReply>(
                `${mainUrl}tickets`,
                {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getTickets')
        }
    }

    async uploadPhoto(photoData: string): Promise<string> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('uploadPhoto', controller)

        try {
            const url = `${mainUrl}user/image`
            const base64Data = photoData.split(',')[1]
            const mimeType =
                photoData.split(',')[0].match(/:(.*?);/)?.[1] || 'image/jpeg'
            const binary = atob(base64Data)
            const array = []

            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }

            const blob = new Blob([new Uint8Array(array)], { type: mimeType })
            const formData = new FormData()
            formData.append('image', blob, 'image.jpeg')
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    ContentType: 'multipart/form-data',
                },
                body: formData,
            })
            const blobResponse = await response.blob()
            const image = await convertBlobToBase64(blobResponse)
            return image
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('uploadPhoto')
        }
    }

    async getComments(postslug: string): Promise<GetCommentsReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('getComments', controller)
        try {
            const response = await axios.get<GetCommentsReply>(
                `${mainUrl}posts/${postslug}/comments`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('getComments')
        }
    }

    async createComment(body: string, postslug: string): Promise<CommentReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('createComment', controller)
        try {
            const requestData = {
                comment: {
                    body: body,
                },
            }
            const response = await axios.post<CommentReply>(
                `${mainUrl}posts/${postslug}/comments`,
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('createComment')
        }
    }

    async favoriteComment(
        postid: string,
        commentid: string
    ): Promise<CommentReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('favoriteComment', controller)
        try {
            const response = await axios.post<CommentReply>(
                `${mainUrl}posts/${postid}/comments/${commentid}/favorite`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('favoriteComment')
        }
    }

    async unfavoriteComment(
        postid: string,
        commentid: string
    ): Promise<CommentReply> {
        await this.reauthenticate()
        const controller = new AbortController()
        this.controllers.set('unfavoriteComment', controller)
        try {
            const response = await axios.delete<CommentReply>(
                `${mainUrl}posts/${postid}/comments/${commentid}/favorite`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                    signal: controller.signal,
                }
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.message)
            } else {
                throw new Error('An unexpected error occurred')
            }
        } finally {
            this.controllers.delete('unfavoriteComment')
        }
    }
}

export default ApiManger
