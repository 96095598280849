import { url } from 'inspector'
import { iframelyApiKey } from '../constants'
import axios from 'axios'

interface IframelyMedia {
    href: string
    type: string
    rel: string[]
    media?: {
        width?: number
        height?: number
        [key: string]: any
    }
}
interface IframelyResponse {
    meta?: {
        canonical?: string
        title?: string
        description?: string
        media?: string
        site?: string
    }
    links?: {
        icon?: IframelyMedia[]
        thumbnail?: IframelyMedia[]
    }
    url: string
}

export const getIframely = async (url: string): Promise<IframelyResponse> => {
    const iframelyurl = `https://iframe.ly/api/iframely?url=${url}&api_key=${iframelyApiKey}`
    try {
        const iframelyResponse = await axios.get(iframelyurl)
        const iframelyData = iframelyResponse.data
        if (!iframelyData.meta) {
            throw new Error('No metadata found')
        }
        return iframelyData
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data.message || 'An error occurred')
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
