import { createTheme } from '@mui/material'

// Extend the Material UI theme to include custom colors
declare module '@mui/material/styles' {
    interface TypeBackground {
        primary: string
        secondary: string
        header: string
        tertiary: string
        quaternary: string
        selected: string
        hover: string
        attending: string
        host: string
    }
    interface TypeText {
        primary: string
        secondary: string
        tertiary: string
        selected: string
        favorite: string
        error: string
        attending: string
        host: string
    }
    interface TypeBorder {
        primary: string
        secondary: string
    }
    interface Palette {
        background: TypeBackground
        text: TypeText
        border: TypeBorder
    }
    interface PaletteOptions {
        background?: Partial<TypeBackground>
        text?: Partial<TypeText>
        border?: Partial<TypeBorder>
    }
}

// Define the dark theme
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            primary: '#111111',
            secondary: '#151515',
            header: '#1A1A1C',
            tertiary: '#252528',
            quaternary: '#444449',
            selected: '#A872FF',
            hover: '#1C1C1E',
            attending: '#D7B601',
            host: '#2AA134',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#C8CED1',
            tertiary: '#979B9C',
            selected: '#B88CFF',
            favorite: '#FF5D5D',
            error: '#FF3D3D',
            attending: '#FFD904',
            host: '#42DA4F',
        },
        border: {
            primary: '#232325',
            secondary: '#333335',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, sans-serif',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, sans-serif',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, sans-serif',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, sans-serif',
                },
            },
        },
    },
})

export { darkTheme }
