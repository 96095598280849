import { Box, Collapse, Drawer, Fade, Typography, styled } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import React from 'react'
import {
    FollowButton,
    PrimaryDivider,
    SelectButton,
} from '../../views/Components/StyledComponents'

const drawerWidth = 240
const StyledDrawer = styled(Box)(({ theme }) => ({
    paddingRight: 0,
    zIndex: theme.zIndex.appBar - 1,
    height: '100vh',
    width: '100vw',
    background: theme.palette.background.primary,
    paddingTop: 55,
    position: 'fixed',
}))

interface SplashNavBarMobileProps {
    navBarOpen: boolean
    setNavBarOpen: React.Dispatch<React.SetStateAction<boolean>>
    showLogin: boolean
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
}

const SplashNavBarMobile = ({
    navBarOpen,
    setNavBarOpen,
    showLogin,
    setShowLogin,
}: SplashNavBarMobileProps) => {
    const { state, setState } = useAppState()
    const handleShowLogin = () => {
        setNavBarOpen(false)
        setShowLogin(true)
    }
    return (
        <Collapse in={navBarOpen}>
            <StyledDrawer
            >
                <Fade in={navBarOpen}>
                    <Box
                        sx={{
                            position: 'relative',
                            height: 'calc(100% - 55px)',
                        }}
                    >
                        <Box
                            sx={{
                                p: '16px',
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    About
                                </Typography>
                            </Box>
                            <PrimaryDivider sx={{ m: '16px 0px' }} />
                            <Box
                                sx={{
                                    mt: '16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    Discover Events
                                </Typography>
                            </Box>
                            <PrimaryDivider sx={{ m: '16px 0px' }} />
                            <Box
                                sx={{
                                    mt: '16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    Beta Test
                                </Typography>
                            </Box>
                            <PrimaryDivider sx={{ m: '16px 0px' }} />
                            <Box
                                sx={{
                                    mt: '16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    Updates
                                </Typography>
                            </Box>
                            <PrimaryDivider sx={{ m: '16px 0px' }} />
                            <Box
                                sx={{
                                    mt: '16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    Premium
                                </Typography>
                            </Box>
                            <PrimaryDivider sx={{ m: '16px 0px' }} />
                            <Box
                                sx={{
                                    mt: '16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    FAQ
                                </Typography>
                            </Box>
                            <PrimaryDivider sx={{ m: '16px 0px' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'fixed',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                pb: '16px',
                                bottom: 0,
                            }}
                        >
                            {/* <FollowButton
                    sx={{mr:'4px'}}>
                    <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'background.primary',
                    }}
                    >
                        Log in
                    </Typography>
                    </FollowButton> */}

                            <SelectButton onClick={handleShowLogin}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    Sign In
                                </Typography>
                            </SelectButton>
                        </Box>
                    </Box>
                </Fade>
            </StyledDrawer>
        </Collapse>
    )
}

export default SplashNavBarMobile
