import { Box, styled, Typography } from '@mui/material'
import {
    PrimaryTypography,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { Event } from '../../../models/Event'
import { useRef } from 'react'
import { googleMapsApiKey } from '../../../constants'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

interface EventDetailsMapViewProps {
    event: Event
    height?: number
}

const EventDetailsMapView = ({
    event,
    height = 300,
}: EventDetailsMapViewProps) => {
    const mapRef = useRef<HTMLDivElement | null>(null)
    const zoom = 11
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                LOCATION
            </Typography>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '8px',
                    }}
                >
                    <LocationOnRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: 'text.tertiary',
                            background: (theme) =>
                                theme.palette.background.tertiary,
                            padding: '8px',
                            borderRadius: '10px',
                        }}
                    />
                    <PrimaryTypography sx={{ fontSize: 14, marginLeft: '8px' }}>
                        {`${event.address.street} ${event.address.city}, ${event.address.state} ${event.address.zipcode}`}
                    </PrimaryTypography>
                </Box>
                <LoadScript
                    googleMapsApiKey={'AIzaSyCTm7sXwQNKtccUyry0RZty7qQFiFyCIZw'}
                >
                    <GoogleMap
                        mapContainerStyle={{
                            height: height,
                            width: '100%',
                            borderRadius: '10px',
                            marginTop: '8px',
                        }}
                        center={{
                            lat: event.location.x,
                            lng: event.location.y,
                        }}
                        zoom={14}
                        options={{
                            disableDefaultUI: true,
                            mapTypeControl: false,
                            mapId: '7a9e2ebecd32a903',
                            clickableIcons: false,
                        }}
                    >
                        <Marker
                            position={{
                                lat: event.location.x,
                                lng: event.location.y,
                            }}
                        />
                    </GoogleMap>
                </LoadScript>
                {/* <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${event.location.x},${event.location.y}&zoom=${zoom}&size=${2000}x${height}&maptype=roadmap&markers=color:red%7C${event.location.x},${event.location.y}&key=${googleMapsApiKey}`}
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '10px',
                        marginTop: '8px',
                    }}
                /> */}
            </Box>
        </Box>
    )
}

export default EventDetailsMapView
