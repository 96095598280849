import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { getPosts } from '../../../api/RestPost'
import { Box } from '@mui/material'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { error } from 'console'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import EventPostsTopBarMobileView from './EventPostsTopBarMobileView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { Event } from '../../../models/Event'
import AccountDetailsSubView from '../../AccountView/AccountSubViews/AccountDetailsSubView'
import EventAttendeesTopBarMobileView from './EventAttendeesTopBarMobileView copy'

interface EventAttendeesMobileViewProps {
    event: Event
}

const EventAttendeesMobileView = ({ event }: EventAttendeesMobileViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [value, setValue] = useState('attendees')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    return (
        <Box
            sx={{
                mt: '112px',
                position: 'relative',
                mb: `${navMobileBarHeight}px`,
            }}
        >
            <TabBox
                sx={{
                    position: 'fixed',
                    top: '56px',
                    width: '100%',
                    zIndex: 2,
                    backgroundColor: 'background.primary',
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                >
                    <StyledTab label="Attendees" value="attendees" />
                    <StyledTab label="Followers" value="followers" />
                    <StyledTab label="Hosts" value="hosts" />
                </StyledTabs>
            </TabBox>
            <EventAttendeesTopBarMobileView />
            <Box
                sx={{
                    p: '0px 16px',
                }}
            >
                {event.attendees.map((attendee) => (
                    <Box
                        sx={{
                            p: '8px 0px',
                        }}
                    >
                        <AccountDetailsSubView
                            user={attendee}
                            followButton={true}
                            bio={true}
                            size={40}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default EventAttendeesMobileView
