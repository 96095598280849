import axios from 'axios'
import { mainUrl } from '../constants'
import { ErrorCode } from '../models/ErrorCode'
import { convertBlobToBase64 } from '../utils/Base64ImageEncoding'
import { User } from '../models/User'

export interface UserReply {
    profile: User
}

export interface UsersReply {
    profiles: User[]
}

export const getUser = async (
    username: string,
    usertoken: string
): Promise<UserReply> => {
    try {
        const response = await axios.get<UserReply>(
            `${mainUrl}profiles/${username}`,
            {
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const grabUserPhoto = async (
    imagelink: string,
    usertoken: string
): Promise<string> => {
    const url = `${mainUrl}user/image?image=${imagelink}`

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${usertoken}`,
        },
    }

    try {
        const response = await fetch(url, requestOptions)
        if (!response.ok) {
            throw new Error('Failed to fetch data')
        }

        const blob = await response.blob()
        const image = await convertBlobToBase64(blob)

        return image
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const getUsersText = async (
    search_string: string,
    limit: number,
    offset: number,
    usertoken: string
): Promise<UsersReply> => {
    try {
        const params: Record<string, number> = {
            limit: limit,
            offset: offset,
        }
        const response = await axios.get<UsersReply>(
            `${mainUrl}profiles/search/${search_string}`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const followUser = async (
    username: string,
    usertoken: string
): Promise<UserReply> => {
    const url = `${mainUrl}profiles/${username}/follow`

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${usertoken}`,
        },
    }

    try {
        const response = await axios.post<UserReply>(
            url,
            {},
            {
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const unfollowUser = async (
    username: string,
    usertoken: string
): Promise<UserReply> => {
    const url = `${mainUrl}profiles/${username}/follow`

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${usertoken}`,
        },
    }

    try {
        const response = await axios.delete<UserReply>(url, {
            headers: {
                Authorization: `Bearer ${usertoken}`,
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const getFollowers = async (
    username: string,
    usertoken: string
): Promise<User[]> => {
    const url = `${mainUrl}profiles/${username}/followers`

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${usertoken}`,
        },
    }

    try {
        const response = await axios.get<User[]>(url, {
            headers: {
                Authorization: `Bearer ${usertoken}`,
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const getFollowing = async (
    username: string,
    usertoken: string
): Promise<User[]> => {
    const url = `${mainUrl}profiles/${username}/following`

    try {
        const response = await axios.get<User[]>(url, {
            headers: {
                Authorization: `Bearer ${usertoken}`,
                'Content-Type': 'application/json',
            },
        })
        console.log(response)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
