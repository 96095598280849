import React from 'react'
import ApiManger from './ApiManager'

interface ApiManagerProviderProps {
    children: React.ReactNode
}

export const ApiManagerContext = React.createContext<ApiManger | null>(null)

export const ApiManagerProvider: React.FC<ApiManagerProviderProps> = ({
    children,
}) => {
    const apiManager = new ApiManger(
        Number(localStorage.getItem('lastauthtime')) || null,
        localStorage.getItem('usertoken') || null,
        localStorage.getItem('useremail') || null,
        localStorage.getItem('userpassword') || null
    )
    return (
        <ApiManagerContext.Provider value={apiManager}>
            {children}
        </ApiManagerContext.Provider>
    )
}

export const useApiManager = () => {
    const apiManager = React.useContext(ApiManagerContext)
    if (!apiManager) {
        throw new Error(
            'useApiManager must be used within a ApiManagerProvider'
        )
    }
    return apiManager
}
