import { useRef } from 'react'
import { CreateEventPreviewProps } from './CreateEventPreviewView'
import { Box, Typography } from '@mui/material'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { googleMapsApiKey } from '../../../constants'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { CreateEvent } from '../../../models/CreateEvent'

interface CreateEventPreviewLocationViewProps {
    tempEvent: CreateEvent
    height?: number
}
const CreateEventPreviewLocationView = ({
    tempEvent,
    height = 300,
}: CreateEventPreviewLocationViewProps) => {
    const mapRef = useRef<HTMLDivElement | null>(null)
    const zoom = 11
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                LOCATION
            </Typography>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '8px',
                    }}
                >
                    <LocationOnRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: 'text.tertiary',
                            background: (theme) =>
                                theme.palette.background.tertiary,
                            padding: '8px',
                            borderRadius: '10px',
                        }}
                    />

                    <Typography
                        sx={{
                            fontSize: 14,
                            marginLeft: '8px',
                            color: 'text.primary',
                        }}
                    >
                        {tempEvent.address
                            ? `${tempEvent.address.street} ${tempEvent.address.city}, ${tempEvent.address.state} ${tempEvent.address.zipcode}`
                            : 'Add Address'}
                    </Typography>
                </Box>
                {tempEvent.location && (
                    // <img
                    //     src={`https://maps.googleapis.com/maps/api/staticmap?center=${tempEvent.location.x},${tempEvent.location.y}&zoom=${zoom}&size=${2000}x${height}&maptype=roadmap&markers=color:red%7C${tempEvent.location.x},${tempEvent.location.y}&map_id=7a9e2ebecd32a903&key=${googleMapsApiKey}`}
                    //     style={{
                    //         width: '100%',
                    //         height: 'auto',
                    //         borderRadius: '10px',
                    //         marginTop: '8px',
                    //     }}
                    // />
                    <LoadScript
                        googleMapsApiKey={
                            'AIzaSyCTm7sXwQNKtccUyry0RZty7qQFiFyCIZw'
                        }
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                height: height,
                                width: '100%',
                                borderRadius: '10px',
                                marginTop: '8px',
                            }}
                            center={{
                                lat: tempEvent.location.x,
                                lng: tempEvent.location.y,
                            }}
                            zoom={14}
                            options={{
                                disableDefaultUI: true,
                                mapTypeControl: false,
                                mapId: '7a9e2ebecd32a903',
                                clickableIcons: false,
                            }}
                        >
                            <Marker
                                position={{
                                    lat: tempEvent.location.x,
                                    lng: tempEvent.location.y,
                                }}
                            />
                        </GoogleMap>
                    </LoadScript>
                )}
            </Box>
        </Box>
    )
}

export default CreateEventPreviewLocationView
