import { DataStatus } from '../../models/Dataloading'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { User } from '../../models/User'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { useEffect, useState, useRef } from 'react'
import { grabUserPhoto } from '../../api/RestUser'
import { useAppState } from '../../AppStateProvider'
import {
    PrimaryTypography,
    SecondaryTypography,
} from '../Components/StyledComponents'
import AccountAvatarSubView from './AccountAvatarSubView'
import { useApiManager } from '../../api/ApiMangerProvider'
import { useQuery } from 'react-query'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

const FollowingIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

interface AccountDetailsSubViewProps {
    user: User
}
const AccountDesciptionSubView = ({ user }: AccountDetailsSubViewProps) => {
    const apiManager = useApiManager()
    const { state } = useAppState()
    const [userAuthorImage, setUserAuthorImage] = useState<string | null>(null)
    const [following, setFollowing] = useState<boolean>(user.following)
    const [userImageLoaded, setUserImageLoaded] = useState<DataStatus>(
        DataStatus.loading
    )

    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )

    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }
    const { data: userImage, isLoading: isUserImageLoading, error: userImageError } = useQuery(
        user.image,
        ()=>apiManager.grabUserPhoto(user.image),
    )

    return (
        <Box display="flex" position={'relative'} height={'100%'}>
            <Box
                sx={{
                    height: '100%',
                    alignItems: 'top',
                    marginTop: '5px',
                }}
            >
                <AccountAvatarSubView
                    user={user}
                    userimage={userAuthorImage}
                    isUserImageLoading={isUserImageLoading}
                    handleAccountPopperClose={handleAccountPopperClose}
                    handleAccountPopperOpen={handleAccountPopperOpen}
                    following={following}
                    height={40}
                    width={40}
                />
            </Box>
            <Box
                sx={{
                    marginLeft: '5px',
                    height: '100%',
                    float: 'right',
                    alignItems: 'top',
                }}
            >
                <Box display="flex" justifyContent="center">
                    <PrimaryTypography style={{ fontSize: 14 }}>
                        {user.name}
                    </PrimaryTypography>
                    {/* 
                        {comment.attending && (
                            <>
                                <Box width={5} />
                                <PersonRoundedIcon
                                    style={{ color: 'yellow', fontSize: 18 }}
                                />
                            </>
                        )} */}
                </Box>
                <SecondaryTypography style={{ fontSize: 14 }}>
                    @{user.username}
                </SecondaryTypography>
                {user.bio && (
                    <PrimaryTypography style={{ fontSize: 14 }}>
                        {user.bio}
                    </PrimaryTypography>
                )}
            </Box>
        </Box>
    )
}

export default AccountDesciptionSubView
